import { useEffect, useState } from 'react'
import { Stack, Typography } from '@mui/material'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { TermsOfUseModal } from 'components/Modals'

import { CreateNewCampaignModal } from 'components/Modals'
import { CreateCampaignSuccess } from 'components/Modals'
import ListCampaign from './components/ListCampaign'
import campaignService from 'services/campaign-service'
import { FetchingLoader } from 'components/ErrorFallback'

function CampaignsPage() {
  const [showListCampaign, setShowListCampaign] = useState(false)
  const [isOpenTOSModal, setIsOpenTOSModal] = useState(false)
  const [listLoader, setListLoader] = useState('pending')
  const [isOpenCreateNewCampaignModal, setIsOpenCreateNewCampaignModal] =
    useState(false)
  const [
    isOpenCreateCampaignSuccessModal,
    setIsOpenCreateCampaignSuccessModal
  ] = useState(false)

  const launchCampaign = () => {
    setIsOpenTOSModal(true)
  }
  const [newCampaign, setNewCampaign] = useState('')

  useEffect(() => {
    setListLoader('pending')
    const fetchData = async () => {
      const data = await campaignService.getCampaigns()
      if (data?.campaigns?.length > 0) {
        setShowListCampaign(true)
      }
      setListLoader('resolved')
    }
    fetchData()
  }, [])

  if (listLoader === 'pending') {
    return <FetchingLoader />
  }

  return (
    <>
      <TermsOfUseModal
        isOpen={isOpenTOSModal}
        onClose={() => setIsOpenTOSModal(false)}
        onOkCallback={() => {
          setIsOpenTOSModal(false)
          setIsOpenCreateNewCampaignModal(true)
        }}
      />
      <CreateNewCampaignModal
        isOpen={isOpenCreateNewCampaignModal}
        setNewCampaign={setNewCampaign}
        // isOpen={true}
        onClose={() => {
          setIsOpenCreateNewCampaignModal(false)
          setIsOpenCreateCampaignSuccessModal(false)
        }}
        onOkHandler={() => {
          setIsOpenCreateNewCampaignModal(false)
          setIsOpenCreateCampaignSuccessModal(true)
        }}
      />
      <CreateCampaignSuccess
        newCampaign={newCampaign}
        isOpen={isOpenCreateCampaignSuccessModal}
        onClose={() => {
          setShowListCampaign(true)
          setIsOpenCreateCampaignSuccessModal(false)
        }}
      />
      {showListCampaign ? (
        <ListCampaign launchCampaign={launchCampaign} />
      ) : (
        <MDBox mt={10}>
          <Stack gap={4}>
            <Typography
              variant="h4"
              component="h1"
              sx={{ pl: { sm: 4 }, textAlign: 'center' }}
            >
              Embark on Your Journey with AI Calling
            </Typography>
            <MDBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 5
              }}
            >
              <MDTypography sx={{ textAlign: 'center' }}>
                Ready to dive in? Launch your AI agent and let the conversations
                begin! Just a click away, your first campaign can be up and
                running in no time. It's simple, quick, and designed to get you
                connected in about a minute.
              </MDTypography>
              <MDButton color="success" onClick={launchCampaign}>
                Start Your Campaign
              </MDButton>
            </MDBox>
          </Stack>
        </MDBox>
      )}
    </>
  )
}

export default CampaignsPage
