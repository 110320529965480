// CallDetails.js
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardContent, Grid, Tooltip, Typography } from '@mui/material'
import MDBox from 'components/MDBox'
import { useQuery } from 'react-query'
import callService from 'services/call-service'
import {
  formatUtcToReadableDate,
  capitalizeFirstLetter
} from 'utils/utilsFunctions'
import { FETCH_DATA_STATUS } from 'utils/constants'
import { FetchingError, FetchingLoader } from 'components/ErrorFallback'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import ArticleIcon from '@mui/icons-material/Article'
import GetAppIcon from '@mui/icons-material/GetApp'
import {
  useAudioPlayer,
  UseDownloadRecording
} from 'utils/customHooks/useAudioPlayer'
import { TranscriptModal } from 'components/Modals'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

const CallDetails = () => {
  const { id } = useParams()
  const { playing, handlePlayPause } = useAudioPlayer()
  const [openTranscriptModal, setOpenTranscriptModal] = useState(false)
  const [transcriptData, setTranscriptData] = useState([])

  const downloadRecording = async function (location) {
    UseDownloadRecording(location)
  }

  let { data, isLoading, status } = useQuery(['calls', id], () =>
    callService.getCall(id)
  )

  if (isLoading) {
    return <FetchingLoader />
  }

  if (status === FETCH_DATA_STATUS.FAILURE) {
    return <FetchingError />
  }

  if (data.success === true) {
    data = data.data
  }

  return (
    <MDBox sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TranscriptModal
        isOpen={openTranscriptModal}
        onClose={() => {
          setOpenTranscriptModal(false)
        }}
        transcriptData={transcriptData}
      />{' '}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          {createCard('Call Summary', [
            {
              title: 'Contact Name',
              value: data?.contactName ? data?.contactName : 'N/A'
            },
            { title: 'Direction', value: data?.direction },
            { title: 'To Phone Number', value: data?.phone_number },
            { title: 'From Phone Number', value: data?.fromPhoneNumber },
            { title: 'Result', value: data?.result },
            { title: 'Number of Tries', value: data?.num_of_tries }
          ])}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {createCard('Call Details', [
            {
              title: 'Call Date',
              value: formatUtcToReadableDate(data?.createdAt)
            },
            {
              title: 'Max Call Duration',
              value: `${data?.maxCallDuration} minutes`
            },
            { title: 'Call Duration', value: `${data?.call_duration} seconds` },
            { title: 'Campaign Name', value: data?.campaignName },
            { title: 'Agent Name', value: data?.agentName }
          ])}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {createCard('Advanced Details', [
            { title: 'Status', value: data?.status },
            { title: 'Demo Call', value: data?.demo2Call ? 'Yes' : 'No' },
            {
              title: 'Scheduled At',
              value: data?.scheduledAt
                ? formatUtcToReadableDate(data?.scheduledAt)
                : 'N/A'
            },
            { title: 'Call ID', value: data?._id },
            { title: 'API Call', value: data?.api_call ? 'Yes' : 'No' },
            {
              title: 'API Webhook',
              value: data?.webhookUrl ? data?.webhookUrl : 'N/A'
            },
            {
              title: 'Additional Status Notes',
              value: data?.statusDetails ? data?.statusDetails : 'N/A'
            }
          ])}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Tooltip
            title="The appointment booking integration lets our AI book appointments for you. Define your cal.com API keys in the settings and let our AI do the rest."
            placement="bottom"
          >
            {createCard('Appointment Details', [
              {
                title: 'Appointment Booked',
                value: data?.appointment_booked ? 'Yes' : 'No'
              }
            ])}
          </Tooltip>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4}>
          {createCard('Call Details - Part 3', [
            { title: 'Priority', value: data.priority },
            { title: 'OpenAI Input Tokens', value: data.openai_input_tokens },
            { title: 'OpenAI Output Tokens', value: data.openai_output_tokens },
            { title: 'TTS Generated Characters', value: data.tts_generated_characters },
            { title: 'TTS Voice Template Characters', value: data.tts_voicetemplate_characters },
          ])}
        </Grid> */}
        <Grid item xs={12} sm={6} md={6}>
          {createCard('Call Recording', [
            {
              title: 'Recording Enabled',
              value: data?.recordingEnabled ? 'Yes' : 'No'
            },
            {
              title: 'Recording',
              value: (
                <>
                  <MDButton
                    sx={{
                      cursor: 'pointer'
                    }}
                    disabled={!data?.recordingEnabled}
                    onClick={e => {
                      e.stopPropagation()
                      handlePlayPause(id, data?.recording_location)
                    }}
                  >
                    {playing[id] ? (
                      <>
                        <PauseIcon />
                        Pause
                      </>
                    ) : (
                      <>
                        <PlayArrowIcon />
                        Play
                      </>
                    )}{' '}
                  </MDButton>
                  <MDButton
                    variant="contained"
                    startIcon={<ArticleIcon />}
                    onClick={e => {
                      e.stopPropagation()
                      setOpenTranscriptModal(true)
                      setTranscriptData(data?.transcripts)
                    }}
                  >
                    Transcript
                  </MDButton>
                  <MDButton
                    variant="contained"
                    startIcon={<GetAppIcon />}
                    onClick={e => {
                      e.stopPropagation()
                      downloadRecording(data?.recording_location)
                    }}
                  >
                    Download
                  </MDButton>
                </>
              )
            }
            // { title: 'Recording ID', value: data.recording_location }
          ])}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {createCard('Call Transfer Options', [
            {
              title: 'Transfer Enabled',
              value: data?.transferEnabled ? 'Yes' : 'No'
            },
            { title: 'Transfer Rule', value: data?.transferGptDescription },
            { title: 'Transfer Phone Number', value: data?.transferPhoneNumber }
          ])}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {createCard('Voicemail and IVR', [
            {
              title: 'Is Voicemail Enabled',
              value: data?.isVoicemailEnabled ? 'Yes' : 'No'
            },
            {
              title: 'Enable Machine Check',
              value: data?.enableMachineCheck ? 'Yes' : 'No'
            },
            { title: 'Voicemail', value: data?.voicemail }
          ])}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {createCard('Text Message Details', [
            {
              title: 'Is Text Message Enabled',
              value: data?.isTxtMessageEnabled ? 'Yes' : 'No'
            },
            { title: 'Text Message', value: data?.txtMessage }
          ])}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {createCard('Email Message Details', [
            {
              title: 'Is Email Enabled',
              value: data?.isEmailEnabled ? 'Yes' : 'No'
            },
            { title: 'Email Subject', value: data?.emailSubject },
            {
              title: 'Email Message',
              value: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: atob(data?.emailBody || '')
                  }}
                />
              )
            }
          ])}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {createCard('Task Details', [
            { title: 'Greetings', value: data?.greetings },
            {
              title: 'Task',
              value: <div style={{ whiteSpace: 'pre-wrap' }}>{data?.task}</div>
            }
          ])}
        </Grid>
      </Grid>
    </MDBox>
  )
}
const createCard = (title, gridItems) => (
  <Card>
    <CardContent>
      <MDBox sx={{ display: 'flex', alignItems: 'center' }} pt={1}>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ textDecoration: 'underline', textUnderlineOffset: 5 }}
        >
          {title}
        </Typography>
      </MDBox>
      <Grid container spacing={3} mt={2}>
        {gridItems.map(item => (
          <GridItem
            key={item.title}
            xs={12}
            title={item.title}
            value={item.value}
          />
        ))}
      </Grid>
    </CardContent>
  </Card>
)

const GridItem = ({ title, value = 0, xs = 4 }) => (
  <Grid item xs={xs}>
    <Typography variant="h6" component="div">
      {title}:{' '}
      <Typography variant="body2" component="span">
        {value}
      </Typography>
    </Typography>
  </Grid>
)

export default CallDetails
