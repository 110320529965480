import { useAuth0 } from '@auth0/auth0-react'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { storeReferralCode } from 'services/authentication.service'

const sendReferralCode = async (user, code) => {
  try {
    await storeReferralCode({
      email: user.email,
      code
    })
  } catch (error) {
    console.error('Error:', error)
  }
}

export const useReferralCode = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { user } = useAuth0()
  useEffect(() => {
    const fetchData = async () => {
      const code = searchParams.get('val')
      await sendReferralCode(user, code)
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete('val')
      setSearchParams(newSearchParams)
      sessionStorage.removeItem('affiliateCode')
    }
    if (user && searchParams.has('val')) {
      fetchData()
    }
  }, [searchParams, setSearchParams, user])
}
