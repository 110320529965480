import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  Radio
} from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import CustomAudioPlayer from 'components/AudioPlayer'
import callService from 'services/call-service'

const ReviewModal = ({ isOpen, onClose, onSave, item }) => {
  const [reviewResult, setReviewResult] = React.useState('')
  const [reviewNotes, setReviewNotes] = React.useState('')
  const [presignedUrl, setPresignedUrl] = React.useState('')
  const reviewOptions = [
    { label: 'Not Reviewed', key: '' },
    { label: 'Reviewed', key: 'reviewed' },
    { label: 'Interested', key: 'interested' },
    { label: 'Issue', key: 'issue' },
    { label: 'Fix', key: 'fix' }
  ]
  const handleResultChange = e => {
    // updateCallResultClick(e, row, e.target.value)
  }
  const handleCopy = id => {
    const url = window.location.origin + `/calls/${id}`
    navigator.clipboard.writeText(url)
  }

  const resultOptions = [
    'pending',
    'voicemail',
    'ivr',
    'picked_up',
    'failed',
    'unanswered',
    'donotcall',
    'disconnected'
  ]
  useEffect(() => {
    const getAudioUrl = async () => {
      const result = await callService.getPresignedUrl(item.recordingLocation)
      setPresignedUrl(result.data)
    }
    getAudioUrl()
  }, [item])

  useEffect(() => {
    if (item) {
      setReviewResult(item.manualReview || '')
      setReviewNotes(item.notes || '')
    }
  }, [item])

  const handleReviewResultChange = event => {
    setReviewResult(event.target.value)
  }

  const handleReviewNotesChange = event => {
    setReviewNotes(event.target.value)
  }

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle textAlign="center" sx={{ color: 'inherit' }}>
        Manual Review Details
      </DialogTitle>
      <DialogContent>
        <MDBox p={2}>
          <MDTypography component="div" variant="h6" sx={{ marginTop: '2rem' }}>
          Call ID: 
          </MDTypography>
          <Link to={`/calls/${item.callId}`}>{item.callId}</Link>
            <IconButton onClick={() => handleCopy(item.callId)}>
              <FileCopyIcon />
            </IconButton>
          <RadioGroup
            row
            value={reviewResult}
            onChange={handleReviewResultChange}
          >
            {reviewOptions.map(option => (
              <FormControlLabel
                key={option.key}
                value={option.key}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
          <MDTypography component="div" variant="h6" sx={{ marginTop: '2rem' }}>
            Call Result
          </MDTypography>
          <Select value={item.result} onChange={handleResultChange}>
            {resultOptions.map(option => (
              <MenuItem key={option} value={option}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </MenuItem>
            ))}
          </Select>
          <MDTypography component="div" variant="h6" sx={{ marginTop: '2rem' }}>
            Notes
          </MDTypography>

          <TextField
            value={reviewNotes}
            onChange={handleReviewNotesChange}
            fullWidth
            multiline
            rows={4}
            sx={{ marginTop: '1rem' }}
            placeholder="Review notes"
          />

          <MDTypography component="div" variant="h6" sx={{ marginTop: '2rem' }}>
            Recording
          </MDTypography>
          <CustomAudioPlayer src={presignedUrl} />
          <MDTypography component="div" variant="h6" sx={{ marginTop: '2rem' }}>
            Summary
          </MDTypography>
          <MDTypography variant='body2'>
            {item.summary &&  JSON.parse(item.summary).summary}
          </MDTypography>
          <MDTypography component="div" variant="h6" sx={{ marginTop: '2rem' }}>
            Transcript
          </MDTypography>
          <MDTypography variant='body2'>
          {item?.transcripts?.map((item, index) => {
            return (
              
                <p key={index}><b>{item.role}</b>: {item.content}</p>
            )
          })}
          </MDTypography>
        </MDBox>
        <MDButton
          onClick={onClose}
          variant="contained"
          sx={{ marginTop: '1rem' }}
        >
          Cancel
        </MDButton>

        <MDButton
          onClick={() => onSave(item.callId, reviewNotes, reviewResult)}
          variant="contained"
          color="success"
          sx={{ marginTop: '1rem', marginLeft: '1rem' }}
        >
          Save
        </MDButton>
      </DialogContent>
    </Dialog>
  )
}

export default ReviewModal
