import React from 'react'
import { Box, FormControl, Stack, Typography } from '@mui/material'
import Card from 'components/Card'

const ScriptToFollow = ({ formik }) => {
  // Handler for card clicks

  const cardOptions = [
    {
      title: 'Blank Canvas Brilliance',
      description:
        "Start with a clean slate and let your creativity flow. This option is great if you have a specific vision or prefer to craft every word yourself. It’s your script, your way – the sky's the limit.",
      useAI: false
    },
    {
      title: 'AI-Assisted Magic',
      description:
        "Let AI work its magic! Just answer a few questions, and voilà – a custom script tailored to your needs. It's like having a creative genie at your service. Perfect for when you want a blend of efficiency and customization.",
      useAI: true
    }
  ]

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <FormControl sx={{ m: 3 }} variant="standard">
        <Stack sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Craft Your Agent's Script!</Typography>
          <Typography variant="body1">
            How would you like to give your agent their lines?
          </Typography>
        </Stack>
        <Box
          sx={{ width: '100%' }}
          mt={3}
          display="flex"
          gap={1}
          flexDirection="row"
        >
          {cardOptions.map((option, index) => (
            <Card
              key={index}
              title={option.title}
              onClick={() => {
                formik.setFieldValue('useAI', option.useAI)
              }}
              selected={formik.values.useAI === option.useAI}
              description={option.description}
            />
          ))}
        </Box>
      </FormControl>
    </Box>
  )
}

export default ScriptToFollow
