import React, { useCallback } from 'react'
import {
  Box,
  FormControl,
  FormHelperText,
  RadioGroup,
  Stack,
  Typography,
  FormControlLabel
} from '@mui/material'
import Card from 'components/Card'

const EditorChoice = ({ formik }) => {
  // Handler for card clicks
  const handleCardClick = useCallback(
    mode => {
      formik.setFieldValue('editorMode', mode)
    },
    [formik]
  )

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <FormControl
        sx={{ m: 3 }}
        error={Boolean(formik.errors.editorMode)}
        variant="standard"
      >
        <Stack sx={{ textAlign: 'center' }}>
          <Typography variant="h5" id="demo-error-radios">
            Pick Your Creative Style!
          </Typography>
          <Typography variant="body1">
            How do you want to shape your agent's skills?
          </Typography>
        </Stack>
        <Box sx={{ width: '100%' }} mt={3}>
          <RadioGroup
            aria-labelledby="type-label"
            name="editorMode"
            value={formik.values.editorMode}
            onChange={formik.handleChange}
            row
          >
            <Stack flexDirection="row" gap={1}>
              <FormControlLabel
                value="standard"
                control={
                  <Card
                    title="Standard - The Quickstart Path"
                    onClick={() => handleCardClick('standard')}
                    selected={formik.values.editorMode === 'standard'}
                    description="Choose Standard for a smooth and speedy setup. It's perfect if you want to get your agent up and running with ease. Ideal for most of our users, this mode requires minimal technical know-how. Not sure where to start? Standard mode is your friend!"
                  />
                }
              />
              <FormControlLabel
                value="advanced"
                control={
                  <Card
                    title="Advanced - The Tinkerer's Toolkit"
                    onClick={() => handleCardClick('advanced')}
                    selected={formik.values.editorMode === 'advanced'}
                    description="Go Advanced if you're ready to dive deep into customizations. This mode unlocks the full potential for those who love to tweak and tailor. It’s great for tech-savvy users who enjoy experimenting with prompts and models. Recommended for those with a knack for the nitty-gritty of AI."
                  />
                }
              />
            </Stack>
          </RadioGroup>
        </Box>
        {formik.errors.editorMode && (
          <FormHelperText>{formik.errors.editorMode}</FormHelperText>
        )}
      </FormControl>
    </Box>
  )
}

export default EditorChoice
