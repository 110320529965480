import { Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import ContactsList from './components/ContactsList'
import UploadContactsWizard from '../UploadContactsWizard'
import Loader from 'components/Loader'
import listService from 'services/list-service'

import NoItemsView from 'components/NoItemsView'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'

function GroupDetails() {
  const { id: groupId } = useParams()
  const [fetch, setFetch] = useState(false)

  useEffect(() => {
    setFetch(true)
  }, [])

  const { data, isLoading } = useQuery(
    ['group', groupId],
    () => listService.getList(groupId),
    {
      enabled: fetch
    }
  )

  const [showAddDialog, setShowAddDialog] = React.useState(false)

  let contacts = data?.contacts
  let list = data?.list

  if (isLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Loader />
      </Box>
    )
  }
  console.log('contacts', contacts)

  return (
    <>
     <MDBox>
        <MDTypography
          variant="h4"
          component="h1"
          sx={{ pl: { sm: 4 }, textAlign: 'center' }}>
      {list?.listName}
          </MDTypography>
        </MDBox>

      {contacts?.length > 0 ? (
    <>
      <ContactsList
        contacts={contacts}
        list={list}
        additionalFields={list?.additionalFields ?? []}
      />
    </>
  ) : (
    <>
      <NoItemsView
        cta="Upload Contacts"
        title="Your Group doesn't have any contacts yet!"
        content="Looks like your agents are all ears and ready to chat! Let's get your contacts uploaded."
        onClick={() => {
          setShowAddDialog(true)
        }}
      />
      <UploadContactsWizard
        open={showAddDialog}
        lists={[list]}
        onClose={() => setShowAddDialog(false)}
      />
    </>
  )}
    </>
  )
}

export default GroupDetails
