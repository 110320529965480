/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// @mui material components
import Grid from '@mui/material/Grid'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

// Material Dashboard 2 PRO React base styles
import breakpoints from 'assets/theme/base/breakpoints'

// import Footer from "examples/Footer";

function TabsLayout({ stickyNavbar, children, onChange, activeTab, tabs }) {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal')

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal')
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation)

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation)
  }, [tabsOrientation])

  return (
    <React.Fragment>
      <MDBox mt={stickyNavbar ? 3 : 2}>
        <Grid container>
          <Grid item xs={12} sm={8} lg={4}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={activeTab}
                onChange={onChange}
              >
                {tabs?.map((tab, index) => (
                  <Tab label={tab} key={index} />
                ))}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </MDBox>
      {/* <Footer /> */}
    </React.Fragment>
  )
}

// Setting default values for the props of TabsLayout
TabsLayout.defaultProps = {
  stickyNavbar: false
}

// Typechecking props for TabsLayout
TabsLayout.propTypes = {
  stickyNavbar: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default TabsLayout
