import { useState } from 'react';
import MDButton from 'components/MDButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MDBox from 'components/MDBox';

function VideoPlayer({ url, openText = 'play tutorial', closeText = 'Close' }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MDBox>
      <MDButton onClick={handleOpen}>
        {openText}
      </MDButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth={true}
        PaperProps={{
          style: {
            width: '60%',
            maxWidth: 'none',
          },
        }}
      >
        <DialogContent>
          <div
            style={{
              position: 'relative',
              paddingBottom: '61.016949152542374%',
              height: '0'
            }}
          >
            <iframe
              src={url}
              frameBorder="0"
              webkitallowfullscreen={true}
              mozallowfullscreen={true}
              allowFullScreen={true}
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%'
              }}
            ></iframe>
          </div>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>
            {closeText}
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default VideoPlayer;