import { Card, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { FormField } from 'pages/account/components'
import { useFormik } from 'formik'
import HttpService from 'services/http.service'
import { useEffect, useState } from 'react'
import { notifySuccess, notifyError } from 'components/Messages'

const Settings = () => {
  const [settings, setSettings] = useState({})
  const [loading, setLoading] = useState(true)

  const formik = useFormik({
    initialValues: {
      zapierWebhookUrl: '',
      calComApiKey: '',
      calComEventTypeId: '',
      burstSmsApiKey: '',
      burstSmsSecret: '',
      burstSmsPhoneNumber:''
    },
    onSubmit: async e => {
      const data = await HttpService.post('/api/me/settings', e)
      if (!data.success) {
        notifyError(data.message)
      } else {
        notifySuccess('Settings updated successfully')
      }
    }
  })

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await HttpService.get('/api/me/settings')
      setSettings(data)
      formik.setValues({
        zapierWebhookUrl: data.zapierWebhookUrl || '',
        calComApiKey: data.calComApiKey || '',
        calComEventTypeId: data.calComEventTypeId || '',
        burstSmsApiKey: data.burstSmsApiKey || '',
        burstSmsSecret: data.burstSmsSecret || '',
        burstSMSPhoneNumber: data.burstSMSPhoneNumber || ''
      })
      setLoading(false)
    }
    fetchSettings()
  }, [])

  return (
    <Card id="settings" sx={{ overflow: 'visible' }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Cal.com Integration</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3} onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <FormField label="Zapier Webhook URL" name="zapierWebhookUrl" value={formik.values.zapierWebhookUrl} onChange={formik.handleChange} />
          </Grid> */}
          <Grid item xs={12}>
            <FormField
              label="Cal.com API Key"
              name="calComApiKey"
              value={formik.values.calComApiKey}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="Cal.com Event Type ID"
              name="calComEventTypeId"
              value={formik.values.calComEventTypeId}
              onChange={formik.handleChange}
            />
          </Grid>
          {/* New BurstSMS integration fields */}
  <Grid item xs={12}>
    <MDTypography variant="h5">Burst SMS (Transmit SMS) Integration</MDTypography>
  </Grid>
  <Grid item xs={12}>
    <FormField
      label="API Key (user name)"
      name="burstSmsApiKey"
      value={formik.values.burstSmsApiKey || ''}
      onChange={formik.handleChange}
    />
  </Grid>
  <Grid item xs={12}>
    <FormField
      label="Secret (password)"
      name="burstSmsSecret"
      value={formik.values.burstSmsSecret || ''}
      onChange={formik.handleChange}
    />
  </Grid>
  {/* <Grid item xs={12}>
    <FormField
      label="Phone Number"
      name="burstSmsPhoneNumber"
      value={formik.values.burstSmsPhoneNumber || ''}
      onChange={formik.handleChange}
    />
  </Grid> */}
          <Grid item xs={12}>
            <MDButton variant="gradient" color="dark" type="submit">
              Save
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  )
}

export default Settings
