import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import StripeService from 'services/stripe-service'

export const useStripe = setPayment => {
  const [isLoading, setIsLoading] = useState(false)

  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    StripeService.getConfig().then(res => {
      const { publishableKey } = res
      setStripePromise(loadStripe(publishableKey))
    })
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true) // Start loading
        const res = await StripeService.getStripePaymentIntent()
        const { paymentMethods, clientSecret } = res

        if (paymentMethods.length > 0) {
          setPayment(true)
        }

        setClientSecret(clientSecret)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false) // Finish loading, whether successful or not
      }
    }
    if (stripePromise || clientSecret) return
    else {
      fetchData()
    } // Call the async function
  }, [stripePromise, clientSecret, setPayment])

  return {
    isLoading,
    stripePromise,
    clientSecret
  }
}
