import { Box, useMediaQuery } from '@mui/material'

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the page layout of Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the DefaultNavbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `name` key is used for the name of the route on the DefaultNavbar.
  3. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  4. The `icon` key is used for the icon of the route on the DefaultNavbar, you have to add a node.
  5. The `collapse` key is used for making a collapsible item on the DefaultNavbar that contains other routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  6. The `route` key is used to store the route location which is used for the react router.
  7. The `href` key is used to store the external links location.
*/

// @mui material components
import Header from 'components/Header'
import DashboardLayout from './DashboardLayout'
import Loader from 'components/Loader'
import MDTypography from 'components/MDTypography'

export function Layout({ children, isLoading = false }) {
  const below500 = useMediaQuery('(max-width:500px)')
  return (
    <DashboardLayout sx={{ minHeight: '100vh', minWidth: 'inherit' }}>
      <Header />
      {isLoading ? (
        <Box sx={{ height: '100px' }}>
          <Loader />
        </Box>
      ) : below500 ? (
        <Box
          sx={{
            height: '80vh',
            width: '90vw',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
          p={2}
        >
          <MDTypography variant="h4" align="left">
            Please Maximize your experience by accessing the desktop version for
            optimal functionality and a wider view.
          </MDTypography>
        </Box>
      ) : (
        children
      )}
    </DashboardLayout>
  )
}
