import React, { useMemo } from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import Chart from 'components/Charts/BarCharts/HorizontalBarChart'

const calculateTotals = (data, fieldName) => {
  const optionsMap = new Map()
  data.forEach(item => {
    const option = item[fieldName]
    optionsMap.set(option, (optionsMap.get(option) || 0) + 1)
  })

  return Array.from(optionsMap).map(([option, total]) => ({
    title: convertCamelCaseToLabel(option),
    value: total
  }))
}

function convertCamelCaseToLabel(camelCaseString) {
  if (!camelCaseString) {
    return ''
  }
  const words = camelCaseString.split(/(?=[A-Z])/)
  const label = words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

  return label
}

const StatsSection = ({
  title,
  items = [],
  chart = false,
  fieldName = null
}) => {
  const processedItems = useMemo(() => {
    if (fieldName) {
      return calculateTotals(items, fieldName)
    }
    return items
  }, [items, fieldName])

  const labelsByStatus = processedItems.map(item => item.title)
  const dataPoints = processedItems.map(item => item.value)
  const chartData = {
    labels: labelsByStatus,
    datasets: [
      {
        label: '# of calls',
        data: dataPoints
      }
    ]
  }

  return (
    <Card>
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          style={{ marginTop: '10px', marginBottom: '30px' }}
        >
          {title}
        </Typography>
        <Grid container spacing={2}>
          {processedItems.map(item => (
            <GridItem key={item.title} title={item.title} value={item.value} />
          ))}
        </Grid>
        {chart && <Chart chart={chartData} />}
      </CardContent>
    </Card>
  )
}

const GridItem = ({ title, value = 0 }) => (
  <Grid item xs={4}>
    <Typography variant="caption" component="div">
      {title}:{' '}
      <Typography variant="caption" component="span">
        {value}
      </Typography>
    </Typography>
  </Grid>
)

export default StatsSection
