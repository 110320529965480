import React from 'react'
import templates from 'data/templates.json'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemButton
} from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'

export default function ScriptTemplateSelector(props) {
  const { onSelected } = props

  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [selectedTemplate, setSelectedTemplate] = React.useState(templates[0])

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
    setSelectedTemplate(templates[index])
  }

  const handleTemplateSelected = () => {
    onSelected(selectedTemplate)
  }

  return (
    <div>
      <MDTypography variant="h4" component="div" sx={{ padding: '1rem' }}>
        Select a Template
      </MDTypography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <List component="nav">
            {templates.map((item, index) => (
              <React.Fragment key={item.name}>
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={event => handleListItemClick(event, index)}
                >
                  <ListItemText primary={item.name} />
                </ListItemButton>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))}
          </List>
        </Grid>
        <Grid item xs={9}>
          <MDBox sx={{ height: '800px', overflowY: 'scroll' }}>
            {selectedTemplate.body.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText primary={'~ ' + item} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={' * wait for prospect to respond *'} />
                </ListItem>
                <Divider variant="inset" component="" />
              </React.Fragment>
            ))}
          </MDBox>
        </Grid>
        <MDBox
          sx={{
            width: '100%',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            position: 'sticky', // Change from 'sticky' to 'fixed'
            bottom: 0,
            backgroundColor: 'white !important',
            padding: '1rem'
          }}
        >
          <MDButton
            variant="gradient"
            color="success"
            style={{ marginTop: '20px' }}
            onClick={handleTemplateSelected}
          >
            Next
          </MDButton>
        </MDBox>
      </Grid>
    </div>
  )
}
