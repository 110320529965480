import React, { useMemo, useCallback } from 'react'
import { IconButton, Stack } from '@mui/material'
import { Delete, Description } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import agentService from 'services/agent-service'
import MDButton from 'components/MDButton'
import NoItemsView from 'components/NoItemsView'
import CreateAgentDialog from './components/CreateAgentDialog'
import { notifyError } from 'components/Messages'
import { getTimeDifferenceString } from 'utils/utilsFunctions'
import { useMaterialUIController } from 'context'
import AlertDialog from 'components/AlertDialog'
import { DataTable } from 'components/Tables/material'
import { LoadingSpinner } from 'components/LoadingSpinner'
import MDBox from 'components/MDBox'

export default function AgentsList() {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller
const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [showAddDialog, setShowAddDialog] = React.useState(false)
  const [agent, setAgent] = React.useState(null)

  const { data, isLoading, isError } = useQuery(
    'agents',
    agentService.getAgents,
    {
      select: response => {
        if (response && response.agents) {
          return response.agents.map(agent => ({
            ...agent,
            updatedAt: getTimeDifferenceString(agent.updatedAt),
            campaignsCount: agent.campaigns.length
          }))
        }
        return []
      },
      onError: error => {
        notifyError('Error loading agents: ' + error.message)
      }
    }
  )
  const deleteAgentMutation = useMutation(agentService.deleteAgent, {
    onSuccess: () => queryClient.invalidateQueries(['agents']),
    onError: error => notifyError(error?.error)
  })

  const cloneAgentMutation = useMutation(agentService.cloneAgent, {
    onSuccess: () => queryClient.invalidateQueries(['agents'])
  })

  const handleDelete = useCallback((e, row) => {
    e.stopPropagation()
    setAgent(row)
    setShowDeleteDialog(true)
  }, [])

  const handleDuplicate = useCallback(
    (e, row) => {
      e.stopPropagation()
      const data = {
        id: row._id,
        agentName: `${row.agentName} - Copy`
      }

      cloneAgentMutation.mutate({ ...data })
    },
    [cloneAgentMutation]
  )
  const ActionCell = ({ params, darkMode }) => (
    <Stack sx={{ flexDirection: 'row', gap: 4 }}>
      <IconButton onClick={e => handleDuplicate(e, params.row.original)}>
        <Description color={darkMode ? 'white' : ''} />
      </IconButton>
      <IconButton onClick={e => handleDelete(e, params.row.original)}>
        <Delete color={darkMode ? 'white' : ''} />
      </IconButton>
    </Stack>
  )

  const columns = useMemo(
    () => [
      { accessorKey: 'agentName', header: 'Name' },
      { accessorKey: 'type', header: 'Type' },
      { accessorKey: 'campaignsCount', header: 'Campaigns' },
      { accessorKey: 'updatedAt', header: 'Last Edited' },
      {
        accessorKey: 'Action',
        header: 'action',
        Cell: params => <ActionCell params={params} darkMode={darkMode} />
      }
    ],
    [darkMode]
  )
  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return <div>Error loading agents</div>
  }

  if (data?.length === 0 && !showAddDialog) {
    return (
      <MDBox>
      
      <NoItemsView
        title="Hello there! Ready to make some connections that count?"
        cta="Create Agent"
        tutorialLink="https://www.loom.com/embed/f8f506907fee42959f735af0fe8ca1c2?sid=78fd008d-3d1b-4d2d-9fef-14f8a857c884"
        onClick={() => {
          setShowAddDialog(true)
        }}
        content="Let's get started! Create your first AI agent. Once you create your agent, you can place it on live calls in minutes."
        onOpen={() => setShowAddDialog(true)}
      />
       </MDBox>

    )
  }

  return (
    <React.Fragment>
      <DataTable
        table={{ columns, rows: data ?? [] }}
        canSearch
        onRowClick={row => {
          navigate(`/agents/${row.original._id}`, { replace: true })
        }}
        onNewItem={() => {
          setShowAddDialog(true)
        }}
        newItemLabel="New Agent"
      />

      <CreateAgentDialog
        open={showAddDialog}
        onClose={() => setShowAddDialog(false)}
      />
      <AlertDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        title="Just Checking In!"
        content={renderDeleteModalContent(agent)}
      >
        <MDButton onClick={() => setShowDeleteDialog(false)}>Cancel</MDButton>
        <MDButton
          color="error"
          onClick={() => {
            deleteAgentMutation.mutate(agent._id)
            setShowDeleteDialog(false)
          }}
        >
          Delete
        </MDButton>
      </AlertDialog>
    </React.Fragment>
  )
}

const renderDeleteModalContent = agent => (
  <div>
    <strong>Warning:</strong> Your agent will be permanently deleted
    <br />
    <br />
    <strong>Name:</strong> {agent?.agentName}
    <br />
    {/* <strong>Active Campaigns:</strong> 0 */}
  </div>
)
