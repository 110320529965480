import moment from 'moment'

export function getTimeDifferenceString(targetDate) {
  const timeAgoString = moment(targetDate).fromNow()
  return `about ${timeAgoString}`
}

export function replaceDoubleQuotes(str) {
  if (!str) {
    return str
  }
  return str.replace(/"/g, "'")
}

export const formatUtcToReadableDate = timestamp =>
  moment.utc(timestamp).format('MM-DD-YYYY')

export function capitalizeFirstLetter(str) {
  if (!str) {
    return str
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2')
}
