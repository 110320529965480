/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from 'prop-types'

// @mui material components
import Card from '@mui/material/Card'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Custom styles for ComplexProjectCard
function CustomeCard({ title, description, selected, onClick }) {
  return (
    <Card
      sx={{
        border: selected ? '2px solid #49a3f1' : '',
        borderRadius: '10px',
        cursor: 'pointer',
        minHeight: '12rem'
      }}
      onClick={onClick}
    >
      <MDBox p={2}>
        <MDBox display="flex" alignItems="start">
          <MDBox lineHeight={0} display="flex" alignItems="center" gap={1}>
            <MDTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="light"
              sx={{
                flex: 1
              }}
            >
              {title}
            </MDTypography>
            {selected && (
              <MDBox
                sx={{
                  display: 'flex',
                  justifyContent: 'center', // Center horizontally
                  alignItems: 'center', // Center vertically
                  width: '13px', // Adjust the width as needed
                  height: '13px', // Adjust the height as needed
                  backgroundColor: '#49a3f1', // Your desired background color
                  borderRadius: '50%', // Make it circular (adjust as needed)
                  color: 'white', // Adjust the text color to be visible on the background
                  fontSize: '12px' // Adjust the font size as needed
                }}
              >
                <span
                  role="img"
                  aria-label="Checkmark"
                  color="#49a3f1"
                  style={{ textAlign: 'center' }}
                >
                  &#x2713;
                </span>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
        <MDBox lineHeight={1}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {description}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  )
}

// Typechecking props for the ProfileInfoCard
CustomeCard.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light'
  ]),
  // image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node
    })
  ])
}

export default CustomeCard
