import React from 'react'
import { useMemo } from 'react'
import { useMutation, useQuery, QueryClient } from 'react-query'
import { IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import MDBox from 'components/MDBox'
import { DataTable } from 'components/Tables/material'

import campaignService from 'services/campaign-service'

import Loader from 'components/Loader'
import AlertDialog from 'components/AlertDialog'
import MDButton from 'components/MDButton'
import { useNavigate } from 'react-router-dom'

const queryClient = new QueryClient()

const ListCampaign = ({ launchCampaign }) => {
  const [deleteItem, setDeleteItem] = React.useState(null)
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [campaigns, setCampaigns] = React.useState([])

  let { data, isLoading } = useQuery(
    ['campaigns'],
    campaignService.getCampaigns
  )

  const navigate = useNavigate()

  React.useEffect(() => {
    if (data) {
      const campaigns = data.campaigns.map(campaign => ({
        id: campaign.campaignId,
        ...campaign
      }))
      setCampaigns(campaigns)
    }
  }, [data])

  const { mutate } = useMutation(campaignService.deleteCampaign, {
    onSuccess: () => {
      queryClient.invalidateQueries(['campaigns'])
      setCampaigns(campaigns.filter(campaign => campaign.id !== deleteItem.id))
    }
  })

  const tableData = useMemo(() => {
    return {
      rows: campaigns || [],
      columns: [
        { header: 'Campaign Name', accessorKey: 'name' },
        { header: 'Status', accessorKey: 'status' },
        { header: 'Group Name', accessorKey: 'listName' },
        { header: 'Agent', accessorKey: 'agentName' },
        { header: 'Appointments', accessorKey: 'totalAppointments' },
        { header: 'Calls', accessorKey: 'totalCalls' },
        { header: 'Dialed', accessorKey: 'totalCalledContacts' },
        { header: 'Contacts', accessorKey: 'totalContacts' },
        { header: 'Failed', accessorKey: 'totalFailed' },
        { header: 'In Progress', accessorKey: 'totalInProgressCalls' },
        { header: 'Pending', accessorKey: 'totalPending' },
        { header: 'Pickups', accessorKey: 'totalPickups' },
        { header: 'Scheduled', accessorKey: 'totalScheduled' },
        { header: 'Created', accessorKey: 'createdAt' },
        { header: 'Updated', accessorKey: 'updatedAt' },
        {
          header: 'Action',
          accessorKey: 'action',
          Cell: info => {
            return (
              <MDBox display="flex" alignItems="center">
                <Tooltip title="Delete Campaign">
                  <IconButton
                    onClick={e => {
                      e.stopPropagation()
                      const details = info.cell.row.original
                      setDeleteItem(details)
                      setShowDeleteDialog(true)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </MDBox>
            )
          }
        }
      ]
    }
  }, [campaigns])

  if (isLoading)
    return (
      <MDBox
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Loader />
      </MDBox>
    )

  return (
    <React.Fragment>
      <DataTable
        table={tableData}
        canSearch
        onNewItem={launchCampaign}
        newItemLabel="New Campaign"
        onRowClick={row => navigate(`/campaigns/${row.original.id}`)}
      />
      <AlertDialog
        open={showDeleteDialog}
        onClose={() => setDeleteItem(null)}
        title="Just Checking In!"
        content={renderDeleteModalContent(deleteItem)}
      >
        <MDButton
          onClick={() => {
            setShowDeleteDialog(false)
            setDeleteItem(null)
          }}
        >
          Cancel
        </MDButton>
        <MDButton
          color="error"
          onClick={e => {
            mutate(deleteItem.id)
            setDeleteItem(null)
            setShowDeleteDialog(false)
          }}
        >
          Delete
        </MDButton>
      </AlertDialog>
    </React.Fragment>
  )
}

export default ListCampaign

const renderDeleteModalContent = deleteItem => (
  <div>
    <strong>Warning:</strong> Your campaign will be permanently deleted and all
    pending calls will be immediately cancelled.
    <br />
    <br />
    <strong>Name:</strong> {deleteItem?.campaignName}
    <br />
    <strong>Type:</strong> {deleteItem?.type}
    <br />
    <strong>Agent:</strong> {deleteItem?.agent}
    <br />
    <strong>List:</strong> {deleteItem?.listName}
    <br />
  </div>
)
