import React from 'react'
import { Box, IconButton, Stack } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useMutation, useQueryClient } from 'react-query'
import MDButton from 'components/MDButton'
import { DataTable } from 'components/Tables/material'
import contactService from 'services/contact-service'
import UploadContactsWizard from '../../../UploadContactsWizard'
import AlertDialog from 'components/AlertDialog'
import { useMaterialUIController } from 'context'
import { notifySuccess } from 'components/Messages'
import MDBox from 'components/MDBox'
import MDModal from 'components/MDModal'
import DataObjectIcon from '@mui/icons-material/DataObject'
import MDTypography from 'components/MDTypography'
import { useParams } from 'react-router-dom'
import { notifyError } from 'components/Messages'

function ContactsList({ contacts, list }) {
  const [openVariablesModal, setOpenVariablesModal] = React.useState(false)
  const [variables, setVariables] = React.useState([])
  const [controller] = useMaterialUIController()
  const { darkMode } = controller
  const { id: groupId } = useParams()
    const dataWithFullName = contacts.map(contact => ({
    ...contact,
    firstName: contact.firstName ?? '',
    lastName: contact.lastName ?? ''
  }))

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'phone_number',
        header: 'Phone',
        flex: 1
      },
      {
        accessorKey: 'email',
        header: 'Email',
        flex: 1
      },
      { accessorKey: 'name', header: 'Prospect Name', flex: 1 },
      // {
      //   Cell: ({row}) => row.original.variables?.toString(),
      //   header: 'Variables',
      //   flex: 1
      // },
      {
        header: 'Additional Info',
        Cell: ({ row }) => {
          const data = row.original
          const vars = data?.variables

          if (!vars || vars.length === 0) return 'Not available'

          return (
            <MDBox
              sx={{
                cursor: 'pointer',
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                color: 'inherit'
              }}
              onClick={() => {
                setOpenVariablesModal(true)
                setVariables(vars)
              }}
            >
              <DataObjectIcon />
              View{' '}
            </MDBox>
          )
        }
      },
      {
        header: 'Action',
        accessorKey: 'id',
        Cell: ({ row }) => (
          <Stack sx={{ flexDirection: 'row', gap: 4 }}>
            <IconButton onClick={e => handleDelete(e, row)}>
              <Delete color={darkMode ? 'white' : ''} />
            </IconButton>
          </Stack>
        )
      }
    ],
    [darkMode]
  )

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [showAddDialog, setShowAddDialog] = React.useState(false)

  const queryClient = useQueryClient()
  const { mutate } = useMutation(contactService.deleteContact, {
    onSuccess: () => {
      notifySuccess('Contact deleted successfully')
      queryClient.invalidateQueries(['group', groupId])
      queryClient.invalidateQueries(['lists'])
    },
    onError: () => {
      notifyError('Something went wrong')
    }
  })

  const [contactToDelete, setContactToDelete] = React.useState(null)

  const handleDelete = (e, row) => {
    e.stopPropagation()
    setShowDeleteDialog(true) // Show the delete dialog
    setContactToDelete(row.original._id) // Set the ID of the contact to delete
  }
  console.log('contacts', contacts)

  return (
    <Stack gap={2}>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          height: '100%'
        }}
      >
        <DataTable
          table={{
            columns: columns, // Use the useMemo columns
            rows: dataWithFullName // Use the processed data
          }}
          canSearch={true}
          onNewItem={() => setShowAddDialog(true)}
          newItemLabel="Upload Contacts"
        />
        <UploadContactsWizard
          open={showAddDialog}
          lists={[list]}
          onClose={() => setShowAddDialog(false)}
        />
        <MDModal
          open={openVariablesModal}
          onClose={() => setOpenVariablesModal(false)}
          sx={{
            padding: '1.5rem',
            borderRadius: '8px',
            // width: { xs: '90%', md: '90%' },
            maxWidth: '90rem',
            maxHeight: '90%',
            overflow: 'auto',
            borderColor: 'white',
            outline: 'none',
            minHeight: '40vh'
          }}
        >
          <MDTypography variant="h4" sx={{ marginBottom: '1rem' }}>
            Additional Fields
          </MDTypography>

          <MDTypography variant="body2" sx={{ marginBottom: '1rem' }}>
            You can use these fields in your messages by wrapping curly bracets
            around the variables highlighted in the bold.
          </MDTypography>
          <MDTypography variant="body2" sx={{ marginBottom: '1rem' }}>
            {' '}
            For example, if you type <strong>
                {'{' + variables[0]?.variable + '}'}
            </strong>{' '}
            then you will get the value of the variable:{' '}
            <i>
              <strong> {variables[0]?.value}</strong>
            </i>{' '}
            in your message at run time.
          </MDTypography>

          <Stack>{renderVariables(variables)}</Stack>
        </MDModal>
        <AlertDialog
          open={showDeleteDialog}
          onClose={() => {
            setShowDeleteDialog(false)
            setContactToDelete(null) // Clear the ID of the contact to delete when the dialog is closed
          }}
          title="Just Checking In!"
          content={renderDeleteModalContent()}
        >
          <MDButton onClick={() => setShowDeleteDialog(false)}>Cancel</MDButton>
          <MDButton
            color="error"
            onClick={() => {
              mutate(contactToDelete)
              setShowDeleteDialog(false)
            }}
          >
            Delete
          </MDButton>
        </AlertDialog>
      </Box>
    </Stack>
  )
}
const renderVariables = variables => {
  if (variables.length)
    return variables.map(({ variable, value }) => (
      <p key={variable} style={{ marginBottom: '20px' }}>
        <strong>{variable}</strong>: {value}
      </p>
    ))
}

const renderDeleteModalContent = () => (
  <div>
    <strong>Warning:</strong> Your contact will be permanently deleted
  </div>
)

export default ContactsList
