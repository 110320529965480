import { PaymentElement } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js'

import MDButton from 'components/MDButton'
import CircularProgress from '@mui/material/CircularProgress'
import { toast } from 'react-toastify'
import { notifySuccess } from 'components/Messages'
import stripeService from 'services/stripe-service'

export default function CheckoutForm({ title, onClose, updateCredit }) {
  const stripe = useStripe()
  const elements = useElements()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const confirmation = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/account/settings#billing-info`
      },
      redirect: 'if_required'
    })

    if (confirmation.paymentIntent?.status === 'succeeded') {
      await new Promise(res => setTimeout(res, 1000))

      notifySuccess('Your purchase was successful')

      await stripeService.getPaymentIntentInfo()
      updateCredit(confirmation.paymentIntent.amount)
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    else if (
      confirmation.error.type === 'card_error' ||
      confirmation.error.type === 'validation_error'
    ) {
      toast.error(confirmation.error.message)
    } else {
      toast.error('An unexpected error occured.')
    }

    setIsLoading(false)
    onClose()
  }

  return (
    <form id="payment-form">
      <PaymentElement id="payment-element" />
      <MDButton
        sx={{ marginTop: '1rem' }}
        fullWidth
        color="primary"
        variant="contained"
        disabled={isLoading || !stripe || !elements}
        id="submit"
        onClick={() => handleSubmit()}
      >
        {isLoading ? <CircularProgress color="inherit" size={20} /> : title}
      </MDButton>
    </form>
  )
}
