import React, { useState } from 'react'
import ScriptSourceSelector from './components/ScriptSourceSelector'
import ScriptGeneration from './components/ScriptGeneration'
import ScriptTemplateSelector from './components/ScriptTemplateSelector'

function ScriptWizard(props) {
  const { onScriptReady } = props
  const [scriptSource, setScriptSource] = useState(null)
  const [activeStep, setActiveStep] = useState('genTypeSelector')
  const [selectedScriptTemplate, setSelectedScriptTemplate] = useState(null)

  function handleScriptSourceSelected(source) {
    setScriptSource(source)
    setActiveStep('ScriptTemplateSelector')
  }

  function setSelectedTemplate(scriptTemplate) {
    setSelectedScriptTemplate(scriptTemplate)
    setActiveStep('ScriptGeneration')
  }
  function handleScriptGenerated(script) {
    onScriptReady({
      scriptTemplate: script,
      greeting: selectedScriptTemplate.greeting
    })
  }

  function getStep(activeStep) {
    switch (activeStep) {
      case 'genTypeSelector':
        return <ScriptSourceSelector onSelected={handleScriptSourceSelected} />
      case 'ScriptGeneration':
        return (
          <ScriptGeneration
            scriptTemplate={selectedScriptTemplate}
            onScriptGenerated={handleScriptGenerated}
          />
        )

      case 'ScriptTemplateSelector':
        return <ScriptTemplateSelector onSelected={setSelectedTemplate} />
      default:
        return null
    }
  }

  return getStep(activeStep)
}

export default ScriptWizard
