import { useState, useRef } from 'react'
import callService from 'services/call-service'

const useAudioPlayer = () => {
  const [playing, setPlaying] = useState({})
  const playersRef = useRef({})

  const handlePlayPause = async (id, location) => {
    let currentPlayer = playersRef.current[id]
    console.log('currentPlayer', id)
    if (!currentPlayer) {
      try {
        const presignedUrl = (
          await callService.getPresignedUrl(location)
        ).data
        currentPlayer = new Audio(presignedUrl)
        playersRef.current[id] = currentPlayer

        currentPlayer.addEventListener('ended', () => {
          setPlaying(prev => ({
            ...prev,
            [id]: false
          }))
          delete playersRef.current[id]
        })

        currentPlayer.play()
        setPlaying(prev => ({
          ...prev,
          [id]: true
        }))
      } catch (error) {
        console.error('Error playing recording:', error)
      }
    } else if (currentPlayer.paused) {
      currentPlayer.play()
      setPlaying(prev => ({
        ...prev,
        [id]: true
      }))
    } else {
      currentPlayer.pause()
      setPlaying(prev => ({
        ...prev,
        [id]: false
      }))
    }
  }

  return { playing, handlePlayPause }
}
const UseDownloadRecording = async recording_location => {
  try {
    const presignedUrl = (await callService.getPresignedUrl(recording_location))
      .data
    const link = document.createElement('a')
    link.href = presignedUrl
    link.download = 'recording.mp3'
    link.click()
  } catch (error) {
    console.error('Error downloading recording:', error)
  }
}

export { useAudioPlayer, UseDownloadRecording }
