/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Settings page components
import { FormField } from 'pages/account/components'

// Hooks
import { useUser } from 'context'
import { useFormik } from 'formik'
import * as yup from 'yup'
import React, { Fragment, useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import MDButton from 'components/MDButton'
import { useMutation } from 'react-query'
import { notifySuccess } from 'components/Messages'
import { notifyError } from 'components/Messages'
import { patchSubDomain } from 'services/authentication.service'
import { useAppInfo } from 'context'
import { Box, Stack, Typography } from '@mui/material'
import { CheckCircle, FileUpload } from '@mui/icons-material'
import { useDropzone } from 'react-dropzone'
import { LoadingSpinner } from 'components/LoadingSpinner'

const DropzoneStyles = disabled => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '150px',
  maxHeight: '100%',
  borderStyle: 'dashed',
  borderColor: '#c5bebe',
  backgroundColor: '#f8f3f3',
  borderWidth: '3px',
  borderRadius: 1,
  outline: 'none',
  pointerEvents: disabled ? 'none' : 'auto',
  opacity: disabled ? 0.6 : 1
})

function FileDropZone({ formik, disabled }) {
  const [file, setFile] = useState(null)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => {
      formik.setFieldValue('logoImage', acceptedFiles[0])
      setFile(acceptedFiles[0])
    },
    noKeyboard: true,
    multiple: false,
    accept: {}
  })

  return (
    <Fragment>
      <MDTypography
        variant="body2"
        component="p"
        color="main"
        sx={{
          fontSize: '1rem',
          lineHeight: 1.5
        }}
      >
        Upload Logo
      </MDTypography>
      <Box {...getRootProps()} name="file" sx={{ ...DropzoneStyles(disabled) }}>
        <input {...getInputProps()} disabled={disabled} />
        {isDragActive ? (
          <Typography variant="body1">Drop the file here...</Typography>
        ) : (
          <React.Fragment>
            {file ? (
              <Stack spacing={1} alignItems="center">
                <CheckCircle sx={{ fontSize: 48 }} />
                {/* <Typography variant="body1">{file?.name}</Typography> */}

                <img
                  src={URL.createObjectURL(file)}
                  alt="logo"
                  height="70px"
                  width="100px"
                />
                <Typography variant="body2">
                  File Successfully Uploaded
                </Typography>
              </Stack>
            ) : (
              <Stack alignItems="center" spacing={1}>
                <FileUpload />

                <Typography
                  // onClick={open} // Commenting this line out prevents a double upload popup from appearing
                  variant="body1"
                  sx={{
                    textDecoration: 'underline',
                    fontWeight: 500,
                    cursor: 'pointer'
                  }}
                >
                  Upload a file
                </Typography>
                <Typography variant="body2">
                  or Drag and Drop it here
                </Typography>
              </Stack>
            )}
          </React.Fragment>
        )}
      </Box>
    </Fragment>
  )
}

const validationSchema = yup.object({
  domainName: yup
    .string()
    .trim('Please remove whitespaces')
    .strict(true)
    .min(2, 'The domainName field must be at least 2 characters')
    .required('domainName is required'),
  appName: yup
    .string()
    .trim('Please remove whitespaces')
    .strict(true)
    .min(2, 'The appName field must be at least 2 characters')
    .required('appName is required')
})

function AppInfo() {
  const [editMode, setEditMode] = useState(false)
  const { appInfo } = useAppInfo()
  const { authState } = useUser()
  const [loading, setLoading] = useState(true)

  const { mutate, isLoading } = useMutation(
    data => patchSubDomain(data, appInfo.auth0Id),

    {
      onSuccess: () => {
        setEditMode(false)
        notifySuccess('App settings updated successfully')
        window.location.assign(window.location)
      },
      onError: () => {
        notifyError('Something Went Wrong')
      }
    }
  )
  const formik = useFormik({
    initialValues: {
      logoImage: appInfo.logoUrl ?? '',
      domainName: appInfo.domain,
      appName: authState?.userInfo?.appName
    },
    validationSchema,
    onSubmit: async values => {
      if (!values.logoImage) {
        values.logoImage = appInfo.logoUrl
      }
      mutate(values)
    }
  })

  console.log(formik.values, 'formik.values')

  useEffect(() => {
    if (appInfo.domain && appInfo.appName) {
      formik.setFieldValue('domainName', appInfo.domain)
      formik.setFieldValue('appName', appInfo.appName)
      setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInfo.domain])

  const handleCancelChanges = () => {
    formik.resetForm()
    setEditMode(false)
  }
  return (
    <Card id="basic-info" sx={{ overflow: 'visible' }}>
      {loading ? (
        <LoadingSpinner height={'200px'} />
      ) : (
        <>
          <MDBox
            p={3}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <MDTypography variant="h5">App Info</MDTypography>
            {!editMode && (
              <EditIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => setEditMode(true)}
              />
            )}
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {appInfo?.logoUrl && !editMode ? (
                  <img
                    src={appInfo?.logoUrl}
                    alt="logo"
                    width="250px"
                    height="150px"
                  />
                ) : (
                  <FileDropZone formik={formik} disabled={!editMode} />
                )}
              </Grid>
              <Grid item xs={12}>
                <FormField
                  name="domainName"
                  label="Domain Name"
                  placeholder="Thompson.com"
                  value={formik.values.domainName}
                  onChange={formik.handleChange}
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  name="appName"
                  label="App Name"
                  placeholder="Thompson.com"
                  value={formik.values.appName}
                  onChange={formik.handleChange}
                  disabled={!editMode}
                />
              </Grid>
              {editMode && (
                <Grid item xs={12}>
                  <MDBox sx={{ display: 'flex', gap: 2 }}>
                    <MDButton
                      variant="contained"
                      color="primary"
                      onClick={formik.handleSubmit}
                      isLoading={isLoading}
                    >
                      Update Info
                    </MDButton>
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={handleCancelChanges}
                    >
                      Cancel
                    </MDButton>
                  </MDBox>
                </Grid>
              )}
            </Grid>
          </MDBox>
        </>
      )}
    </Card>
  )
}

export default AppInfo
