import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import Loader from 'components/Loader'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useNavigate } from 'react-router-dom'

const paperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '50vh'
}

export function ErrorFallback() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh'
      }}
    >
      <Stack
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{ textAlign: 'center' }}
      >
        <Typography variant="h4" component="div" sx={{ color: '#1976d2' }}>
          My Cald Notice
        </Typography>
        <Typography variant="h5" component="div">
          We're sorry but something went wrong :(
        </Typography>
        <Typography>
          Our team was automatically notified about the issue
        </Typography>
        <Typography>Please click below to refresh the application</Typography>
        <Button
          variant="contained"
          onClick={() => {
            window.location.assign(window.location)
          }}
        >
          Refresh
        </Button>
      </Stack>
    </Box>
  )
}

export const FetchingError = () => {
  const navigate = useNavigate()
  return (
    <Paper
      sx={{
        ...paperStyles
      }}
      pt={1}
      variant="elevation"
    >
      <MDBox
        p={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyCotent: 'center',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Typography variant="body2" sx={{ mt: 1 }}>
          Invalid Link, Please Return To Home
        </Typography>
        <MDBox>
          <MDButton
            variant="contained"
            color="error"
            onClick={() => navigate('../')}
          >
            Back to Home
          </MDButton>
        </MDBox>
      </MDBox>
    </Paper>
  )
}

export const FetchingLoader = () => (
  <Paper sx={{ ...paperStyles }} pt={1} variant="elevation">
    <MDBox sx={{ display: 'flex', justifyCotent: 'center' }}>
      <Loader />
    </MDBox>
  </Paper>
)
