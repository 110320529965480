import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { AgentType, EditorMode } from 'data/agentInfo'
import { TabPanel } from 'components/TabPanel'
import {
  Tabs,
  Tab,
  Tooltip,
  Alert,
  Typography,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton
} from '@mui/material'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import EmailEditor from 'react-email-editor'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { useMutation } from 'react-query'
import agentService from 'services/agent-service'
import Loader from 'components/Loader'
import SideBar from './components/SideBar'
import VariablesSection from './components/VariablesSection'
import { notifySuccess } from 'components/Messages'
import { notifyError } from 'components/Messages'
import MDModal from 'components/MDModal/index.js'
import MDButton from 'components/MDButton'
import MDSelect from 'components/MDSelect'
import * as Yup from 'yup'
import { replaceDoubleQuotes } from 'utils/utilsFunctions'
import { FetchingLoader } from 'components/ErrorFallback'
import { FETCH_DATA_STATUS } from 'utils/constants'
import { FetchingError } from 'components/ErrorFallback'
import { ContentCopy, Verified } from '@mui/icons-material'
import { useKnowledgeBases } from 'utils/customHooks'
import { useUserVoices } from 'utils/customHooks/useUserVoices'
import phoneNumberService from 'services/phoneNumber-service'

const phoneRegex =
  /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

const validatePhoneNumber = phoneNumber => {
  if (phoneNumber && phoneNumber[0] !== '+') {
    return phoneRegex.test(`+1${phoneNumber}`)
  }
  return phoneRegex.test(phoneNumber)
}

export const EMAIL_FIELDS = {
  emailSender: 'Email Sender',
  emailSubject: 'Email Subject'
}

const buildAgentForm = agent => ({
  agentName: agent ? agent.agentName : '',
  language: agent ? agent.language : 'US',
  greeting: agent ? agent.greeting : '',
  scriptTemplate: agent ? agent.scriptTemplate : '',
  pricePerMin: agent ? `${agent.pricePerMin * 100} c/min` : '',
  editorMode: agent ? agent.editorMode : EditorMode.STANDARD,
  type: agent ? agent.type : AgentType.SALES,
  knowledgeBase: agent
    ? agent.knowledgeBase === undefined || agent.knowledgeBase === 'undefined' || agent.knowledgeBase === "null"
      ? '-1'
      : agent.knowledgeBase
    : '-1',
  voiceType: agent ? agent.callSettingsKeys.tts : 'standard_female',
  callSettingsKeys: agent ? agent.callSettingsKeys : {},
  voicemail: agent ? agent.voicemail : '',
  isVoicemailEnabled: agent ? !!agent.isVoicemailEnabled : false,
  enableMachineCheck: agent ? !!agent.enableMachineCheck : false,
  isTxtMessageEnabled: agent ? !!agent.isTxtMessageEnabled : false,
  txtMessage: agent ? agent.txtMessage : '',
  isAppointmentSummarySMSEnabled: agent ? !!agent.isAppointmentSummarySMSEnabled : false,
  appointmentSummarySMS: agent ? agent.appointmentSummarySMS : '',
  isWebhookEnabled: agent ? !!agent.isWebhookEnabled : false,
  webhookUrl: agent ? agent.webhookUrl : '',
  kbStartQuestion: agent ? agent.kbStartQuestion : '',
  kbFallBackAnswer: agent ? agent.kbFallBackAnswer : '',
  isEmailEnabled: agent ? !!agent.isEmailEnabled : false,
  emailSender: agent ? agent.emailSender : '',
  emailSubject: agent ? agent.emailSubject : '',
  isEmailVerified: agent ? agent.isEmailVerified : false,
  variables: agent ? agent.variables : [],
  transferRule: agent ? agent.transferRule : '',
  transferPhoneNumber: agent ? agent.transferPhoneNumber : '',
  transferEnabled: agent ? !!agent.transferEnabled : false,
  maxCallDuration: agent ? agent.maxCallDuration : '',
  recordingEnabled: agent ? agent.recordingEnabled : false
})

const buildTextField = config => {
  const { fieldName, formik, tooltip, ...props } = config
  const { value } = props
  return (
    <Tooltip title={tooltip}>
      <TextField
        size="medium"
        name={fieldName}
        fullWidth
        sx={{
          marginTop: '1rem',
          marginBottom: '1rem',
          width: '100%'
        }}
        value={value ?? formik.values[fieldName]}
        onBlur={formik.handleBlur}
        error={formik.touched.agentName && Boolean(formik.errors[fieldName])}
        helperText={formik.touched.agentName && formik.errors[fieldName] }
        
        {...props}
      />
    </Tooltip>
  )
}

const Agent = () => {
  const [isDirty, setIsDirty] = useState(false)
  const [tabValue, setTabValue] = useState('agentScript')
  const [isEmailEditorOpen, setIsEmailEditorOpen] = useState(false)
  const [emailHtml, setEmailHtml] = useState(null)
  const [emailDesign, setEmailDesign] = useState(null)
  const [formikValues, setFormikValues] = useState(buildAgentForm(null))
  const [formikCopy, setFormikCopy] = useState(null)
  const [isEmailEditorCancelOpen, setIsEmailEditorCancelOpen] = useState(false)
  const [isEmailEditorSaveOpen, setIsEmailEditorSaveOpen] = useState(false)
  const [isEmailEditorLoading, setIsEmailEditorLoading] = useState(true)
  const [isSupportAgentType, setIsSupportAgentType] = useState(false)
  const [agentPhoneNumbers, setAgentPhoneNumbers] = useState([])

  const { data: kbList, isKnowledgeBaseLoading } = useKnowledgeBases()
  const { data: voices, isLoading: isVoiceLoading } = useUserVoices()

  let mappedVoices = []
  mappedVoices = voices?.map(voice => ({
    value: voice.voice,
    label: voice.voiceName
  }))

  const emailEditorRef = React.createRef()

  const closeEmailEditor = () => {
    setIsEmailEditorOpen(false)
    setIsEmailEditorCancelOpen(false)
    setIsEmailEditorSaveOpen(false)
    setFormikValues(formikCopy)
  }

  const kbOptions = () => {
    // add not selected option
    kbList?.unshift({ id: '-1', name: 'None' })
    return kbList?.map(item => ({
      value: item.id,
      label: item.name
    }))
  }

  const saveEmailEditor = async () => {
    const unlayer = emailEditorRef.current.editor

    // Wrap the exportHtml in a new Promise
    const exportedData = await new Promise(resolve => {
      unlayer.exportHtml(data => {
        resolve(data)
      })
    })

    const { design, html } = exportedData
    const designString = JSON.stringify(design)
    const encodedHtml = btoa(html)
    const encodedDesign = btoa(designString)

    if (emailHtml !== encodedHtml || emailDesign !== encodedDesign) {
      setEmailDesign(encodedDesign)
      setEmailHtml(encodedHtml)
      setIsDirty(true)
    }

    closeEmailEditor()
  }

  const openEmailEditor = () => {
    setFormikCopy(formik.values)
    setIsEmailEditorLoading(true)
    setIsEmailEditorOpen(true)
  }

  useEffect(() => {
    const handleBeforeUnload = event => {
      if (isDirty) {
        event.preventDefault()
        event.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleVariablesChange = newVariables => {
    const existingVariables = formik.values.variables
    const removedVariables = existingVariables.filter(
      variable =>
        !newVariables.some(
          newVariable => newVariable.variable === variable.variable
        )
    )
    // console.log('removed', removedVariables)
    if (removedVariables.length > 0) {
      let newScript = formik.values.scriptTemplate
      let newVoicemail = formik.values.voicemail
      let newTextMessage = formik.values.txtMessage
      let newEmailSubject = formik.values.emailSubject
      let newEmailHtml = emailHtml
      removedVariables.forEach(variable => {
        const regex = new RegExp(`{${variable.variable}}`, 'g')
        newScript = formik.values.scriptTemplate?.replace(regex, '')
        newVoicemail = formik.values.voicemail?.replace(regex, '')
        newEmailHtml = emailHtml?.replace(regex, '')
        newTextMessage = formik.values.txtMessage?.replace(regex, '')
        newEmailSubject = formik.values.emailSubject?.replace(regex, '')
      })
      formik.setFieldValue('scriptTemplate', newScript)
      formik.setFieldValue('voicemail', newVoicemail)
      formik.setFieldValue('txtMessage', newTextMessage)
      formik.setFieldValue('emailSubject', newEmailSubject)
      setEmailHtml(newEmailHtml)
      formik.setFieldValue('scriptTemplate', newScript)
    }
    formik.setFieldValue('variables', newVariables)
    // console.log(newVariables)
    setIsDirty(true)
  }

  const queryClient = useQueryClient()

  const handleKBChange = event => {
    event.target.id = event.target.value
    handleAgentFormChange(event)
  }

  const { agentId } = useParams()
  const {
    data: agent,
    isLoading: isAgentLoading,
    status
  } = useQuery(['agents', agentId], () => agentService.getAgent(agentId), {
    onSuccess: agent => {
      formik.resetForm({ values: buildAgentForm(agent) })
      setIsSupportAgentType(agent.type === 'support')

      phoneNumberService.getAgentPhoneNumbers(agentId).then(response => {
        if (response && response.phoneNumbers) {
          setAgentPhoneNumbers(response.phoneNumbers)
        }
      })
      if (!emailHtml) {
        setEmailHtml(agent.emailHtml)
      }
      if (!emailDesign) {
        setEmailDesign(agent.emailDesign)
      }
    },
    refetchOnWindowFocus: false
  })

  const { mutate, isLoading: isUpdating } = useMutation(
    ({ id, values }) =>
      agentService.updateAgent(id, {
        ...values,
        emailDesign: emailDesign,
        emailHtml: emailHtml
      }),
    {
      onSuccess: () => {
        setIsDirty(false)
        queryClient.invalidateQueries(['agents', agentId])
        notifySuccess('Update successful')
      },
      onError: error => {
        notifyError(error.error)
      }
    }
  )

  const { mutate: checkEmailMutation, isLoading: emailVerificationLoader } =
    useMutation(({ values }) => agentService.verifyEmail(values), {
      onSuccess: () => {
        queryClient.invalidateQueries(['agents', agentId])
        notifySuccess('Verification check completed')
      },
      onError: () => {
        notifyError('Verification check failed. Please try again later')
      }
    })

  const formik = useFormik({
    initialValues: formikValues,
    enableReinitialize: true,
    onSubmit: async props => {
      const obj = { ...props }
      obj.greeting = replaceDoubleQuotes(obj.greeting)
      obj.scriptTemplate = replaceDoubleQuotes(obj.scriptTemplate)
      obj.txtMessage = replaceDoubleQuotes(obj.txtMessage)
      obj.voicemail = replaceDoubleQuotes(obj.voicemail)
      obj.callSettingsKeys.tts = obj.voiceType
      mutate({ id: agentId, values: obj })
    },
    validationSchema: Yup.object({
      agentName: Yup.string().required('Required'),
      emailSender: Yup.string().when('isEmailEnabled', {
        is: true,
        then: Yup.string().email('Invalid email address').required('Required')
      }),
      emailSubject: Yup.string().when('isEmailEnabled', {
        is: true,
        then: Yup.string().required('Required')
      })
    })
  })

  const handleAgentFormChange = event => {
    if (event.target.type === 'checkbox') {
      // Use 'event.target.checked' for checkboxes
      formik.setFieldValue(event.target.name, event.target.checked)
    } else {
      formik.handleChange(event)
    }
    if (formik.values.enableMachineCheck) {
      formik.setFieldValue('isVoicemailEnabled', false)
    }
    setIsDirty(true)
  }

  const handleCopyAgentID = () => {
    navigator.clipboard
      .writeText(agentId)
      .then(() => {
        notifySuccess('Agent ID copied successfully')
      })
      .catch(() => {
        notifyError('Error while copying the agent ID')
      })
  }

  const handleKeyDown = event => {
    if (
      formik.values.editorMode === EditorMode.STANDARD &&
      event.key === 'Enter'
    ) {
      const { selectionStart, selectionEnd } = event.target
      let placeholder = '\n*Wait For Prospect To Respond*\n\n'
      const newValue = `${formik.values.scriptTemplate.substring(
        0,
        selectionStart
      )} ${placeholder} ${formik.values.scriptTemplate.substring(selectionEnd)}`
      formik.setFieldValue('scriptTemplate', newValue)
      const newSelectionStart = selectionStart + placeholder.length
      const newSelectionEnd = newSelectionStart
      setTimeout(() => {
        event.target.setSelectionRange(newSelectionStart, newSelectionEnd)
      }, 0)
      event.preventDefault()
    }
  }

  if (isAgentLoading || isKnowledgeBaseLoading) {
    return <FetchingLoader />
  }

  if (status === FETCH_DATA_STATUS.FAILURE) {
    return <FetchingError />
  }
  return (
    <MDBox mt={4}>
      <Grid container spacing={3} sx={{ position: 'relative' }}>
        <Grid
          item
          xs={8}
          lg={8}
          sx={{
            height: '80vh',
            overflowY: 'scroll'
          }}
        >
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <MDBox p={3} sx={{ height: '100vh', width: '100%' }}>
                <form>
                  {buildTextField({
                    fieldName: 'agentName',
                    formik,
                    label: 'Agent Name',
                    tooltip:
                      "What's in a name? Everything! Name your agent something memorable.",
                    onChange: handleAgentFormChange
                  })}
                  <MDSelect
                    label="Agent Voice"
                    name="voiceType"
                    value={formik.values.voiceType}
                    options={mappedVoices || []}
                    onChange={handleAgentFormChange}
                  ></MDSelect>
                  <Tooltip title="Use this copied ID in your API requests to access agent-specific information.">
                    <TextField
                      size="medium"
                      label="Agent ID"
                      fullWidth
                      inputProps={{
                        maxLength: 60,
                        style: { fontSize: '1rem' }
                      }}
                      value={agentId}
                      disabled
                      sx={{
                        width: '100%',
                        fontWeight: '1000',
                        marginTop: '1rem'
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="copy agent id"
                              edge="end"
                              onClick={handleCopyAgentID}
                            >
                              <ContentCopy />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Tooltip>
                  {buildTextField({
                    fieldName: 'pricePerMin',
                    formik,
                    label: 'Cost per minute',
                    disabled: true,
                    tooltip:
                      "Cost of the minute of the call. This is the price you'll pay for the call"
                  })}
                  {buildTextField({
                    fieldName: 'type',
                    formik,
                    value: isSupportAgentType
                      ? 'Inbound Agent (For Customer Support)'
                      : 'Outbound Agent (like sales)',
                    label: 'Agent Purpose',
                    disabled: true,
                    tooltip:
                      'What kind of role does this agent play? Sales, Support, or something else?'
                  })}
                  <MDSelect
                    label="Knowledge Base"
                    name="knowledgeBase"
                    value={formik.values.knowledgeBase}
                    options={kbOptions()}
                    onChange={handleAgentFormChange}
                  ></MDSelect>

                  <MDBox sx={{ marginTop: '1rem', width: '100%' }}>
                    {!isSupportAgentType && (
                      <VariablesSection
                        variables={formik.values.variables}
                        onChange={handleVariablesChange}
                      />
                    )}
                    <Tabs
                      sx={{ marginTop: '1rem' }}
                      value={tabValue}
                      onChange={handleTabChange}
                    >
                      <Tab value="agentScript" label="Agent Script" />
                      {isSupportAgentType ? null : (
                        <Tab value="voicemail" label="Voicemail / IVR" />
                      )}
                      {/* <Tab value="textMessage" label="Text Message" /> */}
                      {isSupportAgentType ? null : (
                        <Tab value="email" label="Email" />
                      )}
                       {/* <Tab value="textMessage" label="Text Message" /> */}
                      <Tab value="appointment" label="Appointment" />
                      <Tab value="webhook" label="Webhook" />
                      <Tab
                        value="phoneNumbers"
                        label="Assigned Phone Numbers"
                      />
                    </Tabs>
                    <TabPanel value={tabValue} index="agentScript">
                      {buildTextField({
                        fieldName: 'maxCallDuration',
                        formik,
                        placeholder: '10',
                        label:
                          'Max Call Duration in minutes (Default is 10 min)',
                        tooltip: 'Maximum duration of the call',
                        onChange: handleAgentFormChange,
                        type: 'number'
                      })}

                      <MDBox
                        sx={{
                          marginTop: '1rem',
                          marginBottom: '1rem',
                          width: '100%'
                        }}
                      >
                        <Tooltip title="">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="recordingEnabled"
                                checked={formik.values.recordingEnabled}
                                onChange={handleAgentFormChange}
                              />
                            }
                            label="Make a call recording available after the end of the call"
                          />
                        </Tooltip>
                      </MDBox>
                      <MDBox
                        sx={{
                          marginTop: '1rem',
                          marginBottom: '1rem',
                          width: '100%'
                        }}
                      >
                        <Tooltip title="Enable this option if you want to transfer the call to a live agent based on a rule">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="transferEnabled"
                                checked={formik.values.transferEnabled}
                                onChange={handleAgentFormChange}
                              />
                            }
                            label="Transfer call to a live agent"
                          />
                        </Tooltip>
                        {formik.values.transferEnabled && (
                          <>
                            {buildTextField({
                              fieldName: 'transferRule',
                              formik,
                              label: 'Call Transfer Rule',
                              tooltip:
                                'A rule that will make the AI agent to attempt to transfer the call',
                              placeholder:
                                'Transfer the call if you do not have an answer to a specific question or the prospect is asking for a live agent',
                              onChange: handleAgentFormChange,
                              minRows: 2,
                              multiline: true
                            })}

                            {buildTextField({
                              fieldName: 'transferPhoneNumber',
                              formik,
                              tooltip:
                                'A phone number to transfer the calls to',
                              placeholder: '+1-111-222-3333',
                              label: 'Phone Number',
                              onChange: handleAgentFormChange,
                              error: !validatePhoneNumber(
                                formik.values.transferPhoneNumber
                              ),
                              helperText:
                                !validatePhoneNumber(
                                  formik.values.transferPhoneNumber
                                ) && 'Invalid phone number'
                            })}
                          </>
                        )}
                      </MDBox>
                      {buildTextField({
                        fieldName: 'greeting',
                        formik,
                        tooltip:
                          'This is the opening line. Whatever you put here will be said verbatim',
                        label: 'Greeting',
                        multiline: true,
                        minRows: 2,
                        onChange: handleAgentFormChange
                      })}
                      <TextField
                        size="medium"
                        name="scriptTemplate"
                        label={
                          formik.values.editorMode === EditorMode.STANDARD
                            ? 'Standard Editor'
                            : 'Advanced Editor'
                        }
                        fullWidth
                        multiline
                        maxLength={
                          formik.values.editorMode === EditorMode.STANDARD
                            ? 500
                            : 10000
                        }
                        minRows={
                          formik.values.editorMode === EditorMode.STANDARD
                            ? 10
                            : 40
                        }
                        value={formik.values.scriptTemplate}
                        onChange={handleAgentFormChange}
                        onBlur={formik.handleBlur}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index="voicemail">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="enableMachineCheck"
                            checked={formik.values.enableMachineCheck || false}
                            onChange={handleAgentFormChange}
                          />
                        }
                        label="Hang up if machine answers (voicemail or IVR)"
                      />
                      {!formik.values.enableMachineCheck && (
                        <>
                          {' '}
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isVoicemailEnabled"
                                checked={
                                  formik.values.isVoicemailEnabled || false
                                }
                                onChange={handleAgentFormChange}
                              />
                            }
                            label="Leave a Voicemail"
                          />
                          {buildTextField({
                            fieldName: 'voicemail',
                            formik,
                            tooltip:
                              "Leave a lasting impression even when you can't connect directly",
                            label: 'Voicemail',
                            placeholder:
                              "Hi {prospectName}, this is Alexander from Cald.ai. I'm calling because I saw you were looking for a new CRM. I'd love to chat with you about how Cald.ai can help you. Give me a call back at 555-555-5555. Thanks!",
                            multiline: true,
                            minRows: 5,
                            onChange: handleAgentFormChange,
                            disabled: !formik.values.isVoicemailEnabled || false
                          })}
                        </>
                      )}
                    </TabPanel>
                    <TabPanel value={tabValue} index="textMessage">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isTxtMessageEnabled"
                            checked={formik.values.isTxtMessageEnabled}
                            onChange={handleAgentFormChange}
                          />
                        }
                        label="Send a Text Message after the call"
                      />
                      <MDBox m={2}>
                        <ul>
                          <li>
                            <MDTypography variant="body2">
                              This text message will be sent only if client
                              agrees to receive text messages during the call
                            </MDTypography>
                          </li>
                          <li>
                            <MDTypography variant="body2">
                              Keep in mind that only cell phone numbers can
                              receive text messages
                            </MDTypography>
                          </li>
                        </ul>
                      </MDBox>
                      {buildTextField({
                        fieldName: 'txtMessage',
                        formik,
                        tooltip:
                          "A text to keep the conversation going after the call. It's all about staying connected!",
                        label: 'Text Message',
                        placeholder:
                          'Hey, {prospectName}. Following up on our call with a few links that we discussed',
                        multiline: true,
                        minRows: 5,
                        onChange: handleAgentFormChange,
                        disabled: !formik.values.isTxtMessageEnabled
                      })}
                    </TabPanel>
                    <TabPanel value={tabValue} index="email">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isEmailEnabled"
                            checked={formik.values.isEmailEnabled}
                            onChange={handleAgentFormChange}
                          />
                        }
                        label="Send an Email after the call"
                      />
                      <MDBox sx={{ marginTop: '1rem' }}>
                        <MDTypography variant="body2" m={2}>
                          (Note: this email message will be sent only if client
                          agrees to receive email messages during the call)
                        </MDTypography>
                      </MDBox>
                      {!agent.isEmailVerified &&
                      formik.values.emailSender &&
                      formik.values.isEmailEnabled ? (
                        <Alert severity="error">
                          The email has not yet been verified. Please check
                          email's inbox and complete verification step before
                          you can use this email address.{' '}
                          <Typography
                            sx={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                              fontSize: '1rem',
                              fontWeight: 300,
                              textUnderlineOffset: 2
                            }}
                            component="span"
                            onClick={() => {
                              checkEmailMutation({
                                values: {
                                  email: formik.values.emailSender,
                                  agentId
                                }
                              })
                            }}
                          >
                            Click here
                          </Typography>{' '}
                          to check verification status{' '}
                          {emailVerificationLoader ? <Loader /> : null}
                        </Alert>
                      ) : null}
                      <MDBox sx={{ marginTop: '1rem', position: 'relative' }}>
                        {buildTextField({
                          fieldName: 'emailSender',
                          formik,
                          tooltip:
                            'Which address should the email come from? This can be any email address you have access to',
                          label: 'From Address',
                          placeholder:
                            'Which address should the email come from?',
                          onChange: handleAgentFormChange,
                          disabled: !formik.values.isEmailEnabled,
                          error:
                            formik.touched.emailSender &&
                            Boolean(formik.errors.emailSender),
                          helperText:
                            formik.touched.emailSender &&
                            formik.errors.emailSender
                        })}
                        <Tooltip
                          title={
                            formik.values.isEmailVerified
                              ? 'Email has been verified'
                              : 'Please verify your email'
                          }
                        >
                          <MDBox
                            sx={{
                              position: 'absolute',
                              right: '-2rem',
                              bottom: 20
                            }}
                          >
                            {formik.values.isEmailVerified ? (
                              <Verified />
                            ) : null}
                          </MDBox>
                        </Tooltip>
                      </MDBox>
                      <MDBox sx={{ marginTop: '1rem' }}>
                        {buildTextField({
                          fieldName: 'emailSubject',
                          formik,
                          tooltip: 'What should the subject of the email be?',
                          label: 'Subject',
                          placeholder:
                            'Hi {prospectName}, here are the links we discussed',
                          onChange: handleAgentFormChange,
                          disabled: !formik.values.isEmailEnabled,
                          error:
                            formik.touched.emailSubject &&
                            Boolean(formik.errors.emailSubject),
                          helperText:
                            formik.touched.emailSubject &&
                            formik.errors.emailSubject
                        })}
                      </MDBox>

                      <MDBox sx={{ margin: '1rem' }}>
                        <MDButton
                          color="primary"
                          onClick={openEmailEditor}
                          disabled={!formik.values.isEmailEnabled}
                        >
                          Open Email Editor
                        </MDButton>
                      </MDBox>
                      <MDModal
                        title="Email Editor"
                        open={isEmailEditorOpen}
                        sx={{
                          width: { xs: '90%', md: '90%' },
                          height: { xs: '90%', md: '90%' },
                          px: 0,
                          py: 0
                        }}
                      >
                        {/* center the loader */}
                        <MDBox
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                          }}
                        >
                          {isEmailEditorLoading && <Loader />}
                        </MDBox>
                        <EmailEditor
                          ref={emailEditorRef}
                          onReady={() => {
                            setIsEmailEditorLoading(false)
                            if (
                              emailDesign !== null &&
                              emailDesign !== undefined
                            ) {
                              const decodedDesign = atob(emailDesign)
                              const designJson = JSON.parse(decodedDesign)
                              emailEditorRef.current.editor.loadDesign(
                                designJson
                              )
                            }
                          }}
                          style={{
                            height: '89vh', // Adjust height as needed
                            width: '80%', // Adjust width as needed
                            margin: 'auto' // Centers the editor in the modal
                          }}
                        />
                        <MDButton
                          onClick={() => setIsEmailEditorSaveOpen(true)}
                          color="success"
                          variant="contained"
                          sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            right: '1rem'
                          }}
                        >
                          Save
                        </MDButton>
                        <MDButton
                          onClick={() => setIsEmailEditorCancelOpen(true)}
                          color="error"
                          variant="contained"
                          sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            right: '7rem'
                          }}
                        >
                          Cancel
                        </MDButton>
                      </MDModal>
                      <MDModal
                        title="Cancel Email Editor Changes"
                        open={isEmailEditorCancelOpen}
                      >
                        <MDTypography variant="h5" p={5} pb={10}>
                          Are you sure you want to cancel your changes?
                        </MDTypography>
                        <MDButton
                          onClick={closeEmailEditor}
                          color="error"
                          variant="contained"
                          sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            right: '1rem'
                          }}
                        >
                          Yes, Cancel Changes
                        </MDButton>
                        <MDButton
                          onClick={() => setIsEmailEditorCancelOpen(false)}
                          color="primary"
                          variant="contained"
                          sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            left: '1rem'
                          }}
                        >
                          No, Keep Editing
                        </MDButton>
                      </MDModal>
                      <MDModal
                        title="Save Email Editor Changes"
                        open={isEmailEditorSaveOpen}
                      >
                        <MDTypography variant="h5" p={5} pb={10}>
                          Are you sure you want to save your changes?
                        </MDTypography>
                        <MDButton
                          onClick={saveEmailEditor}
                          color="success"
                          variant="contained"
                          sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            right: '1rem'
                          }}
                        >
                          Yes, Save Changes
                        </MDButton>
                        <MDButton
                          onClick={() => setIsEmailEditorSaveOpen(false)}
                          color="primary"
                          variant="contained"
                          sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            left: '1rem'
                          }}
                        >
                          No, Keep Editing
                        </MDButton>
                      </MDModal>
                      {emailHtml && formik.values.isEmailEnabled && (
                        <MDBox>
                          <div
                            style={{
                              border: '1px solid #D3D3D3',
                              borderRadius: '5px',
                              minHeight: '500px'
                            }}
                            dangerouslySetInnerHTML={{
                              __html: atob(emailHtml)
                            }}
                          ></div>
                        </MDBox>
                      )}
                    </TabPanel>
                    <TabPanel value={tabValue} index="appointment">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isAppointmentSummarySMSEnabled"
                            checked={formik.values.isAppointmentSummarySMSEnabled}
                            onChange={handleAgentFormChange}
                          />
                        }
                        label="Enable SMS Appointment confirmation using Burst SMS (requires Burst SMS Integration Setup)"
                      />
                      <MDBox m={2}>
                        <ul>
                          <li>
                            <MDTypography variant="body2">
                              When enabled this will execute after a completed call if the call was completed with a follow up appointment
                            </MDTypography>
                          </li>
                          <li><MDTypography variant="body2">
                          Use Start Time variable &#123;startTime&#125; to mark the start of the appointment
                            </MDTypography></li>
                        </ul>
                      </MDBox>
                      {buildTextField({
                        fieldName: 'appointmentSummarySMS',
                        formik,
                        inputProps: {
                          maxLength: 200
                        },
                        multiline: true,
                        minRows:2,
                        tooltip:
                          "The text to summarize the appointment details to the client.",
                        label: 'Confirmation text message',
                        placeholder: 'Hi {prospectName}, your appointment is scheduled for {startTime}. We will call you then!',
                        onChange: handleAgentFormChange,
                        disabled: !formik.values.isAppointmentSummarySMSEnabled
                      })}
                    </TabPanel>
                    <TabPanel value={tabValue} index="webhook">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isWebhookEnabled"
                            checked={formik.values.isWebhookEnabled}
                            onChange={handleAgentFormChange}
                          />
                        }
                        label="Execute a webhook after the call"
                      />
                      <MDBox m={2}>
                        <ul>
                          <li>
                            <MDTypography variant="body2">
                              When enabled this webhook will be executed after
                              each completed call
                            </MDTypography>
                          </li>
                          <li>
                            <MDTypography variant="body2">
                              The payload returned is the same as that of the
                              <br />
                              <a
                                href="https://docs.cald.ai/api-reference/endpoint/get-call"
                                target="_blank"
                                rel="noreferrer"
                              >
                                get call logs endpoint
                              </a>
                              .
                            </MDTypography>
                          </li>
                        </ul>
                      </MDBox>
                      {buildTextField({
                        fieldName: 'webhookUrl',
                        formik,
                        tooltip:
                          "A text to keep the conversation going after the call. It's all about staying connected!",
                        label: 'Webhook',
                        placeholder: 'https://your.app.com/webhookURL',
                        onChange: handleAgentFormChange,
                        disabled: !formik.values.isWebhookEnabled
                      })}
                    </TabPanel>
                    <TabPanel value={tabValue} index="phoneNumbers">
                      <MDBox>
                        {agentPhoneNumbers.length === 0 ? (
                          <>
                            <MDTypography variant="h6">
                              No phone numbers assigned to this agent
                            </MDTypography>
                            <MDTypography variant="caption">
                              Use "My Phone Numbers Page" to assign phone
                              numbers to this agent
                            </MDTypography>
                          </>
                        ) : (
                          <>
                            <MDTypography variant="h6">
                              Assigned phone numbers
                            </MDTypography>
                            <ul>
                              {agentPhoneNumbers.map(phoneNumber => (
                                <li>{phoneNumber.friendlyName}</li>
                              ))}
                            </ul>
                          </>
                        )}
                      </MDBox>
                    </TabPanel>
                  </MDBox>
                </form>
              </MDBox>
            </Grid>
          </MDBox>
        </Grid>
        <div style={{ width: '20px' }} />
        <Grid
          item
          xs={3}
          lg={3}
          sx={{ overflowY: 'hidden', borderLeft: '1px solid #D3D3D3' }}
        >
          <SideBar
            agentId={agentId}
            isDirty={isDirty}
            formik={formik}
            isLoading={isUpdating}
          />
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default Agent
