import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import theme from 'assets/theme'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'

function Home() {
  const { loginWithRedirect } = useAuth0()
  const affiliateCode = sessionStorage.getItem('affiliateCode')

  return (
    <Grid container height="100vh">
      <Grid
        item
        xs={7}
        sx={{
          backgroundColor: '#0f0122'
        }}
      >
        <MDBox
          sx={{
            height: '90%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
          }}
        >
          <img
            src="https://cald.ai/wp-content/uploads/2023/11/hero-image-v1a.png"
            alt="cald"
            width="75%"
          />
        </MDBox>
      </Grid>
      <Grid
        item
        xs={5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000'
        }}
      >
        <MDBox
          sx={{
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <MDTypography variant="h2" sx={{ color: theme.palette.white.main }}>
            Get Started
          </MDTypography>
          <MDBox
            sx={{
              display: 'flex',
              gap: 1,
              mt: 2,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <MDButton
              sx={{
                backgroundColor: '#6000ce',
                color: theme.palette.white.main,
                width: '30%',
                '&:hover': {
                  backgroundColor: '#672aac'
                },
                '&:focus:not(:hover)': {
                  backgroundColor: '#672aac'
                }
              }}
              onClick={() =>
                loginWithRedirect({
                  appState: {
                    returnTo: `/?val=${affiliateCode}`
                  }
                })
              }
            >
              Log in
            </MDButton>
            <MDButton
              sx={{
                backgroundColor: '#6000ce',
                color: theme.palette.white.main,
                width: '30%',
                '&:hover, &:active': {
                  backgroundColor: '#672aac'
                },
                '&:focus:not(:hover)': {
                  backgroundColor: '#672aac'
                }
              }}
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: 'signup'
                  },
                  appState: {
                    returnTo: `/?val=${affiliateCode}`
                  }
                })
              }
            >
              Sign up
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox
          sx={{
            height: '20vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <MDTypography variant="h5" sx={{ color: theme.palette.white.main }}>
            Cald AI
          </MDTypography>
          <MDBox sx={{ display: 'flex', mt: 2, gap: 2 }}>
            <Link to="/terms" target="_blank" rel="noopener noreferrer">
              <MDTypography
                variant="body2"
                sx={{ color: theme.palette.white.main, cursor: 'pointer' }}
              >
                Terms of use
              </MDTypography>
            </Link>
            <MDTypography
              variant="body2"
              sx={{ color: theme.palette.white.main }}
            >
              |
            </MDTypography>
            <a
              href="https://cald.ai/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
              replace={true}
            >
              <MDTypography
                variant="body2"
                sx={{ color: theme.palette.white.main, cursor: 'pointer' }}
              >
                Privacy Policy
              </MDTypography>
            </a>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  )
}

export default Home
