import CallsList from 'pageComponents/calls/List'
import MDBox from 'components/MDBox'
import { useParams } from 'react-router-dom'

const AgentTestRecordings = () => {
  const { agentId } = useParams()


console.log('AgentTestRecordings agentId:', agentId)
  return (
    <MDBox sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: '20px' }}>
      <CallsList id={agentId} type='agent' />
    </MDBox>
  )
}



export default AgentTestRecordings
