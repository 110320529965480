import HttpService from './http.service'

class CampaignService {
  getCampaigns = async () => {
    return await HttpService.get('/api/campaigns')
  }
  deleteCampaign = async id => {
    return await HttpService.delete(`/api/campaigns/${id}`)
  }
  addCampaign = async payload => {
    return await HttpService.post('/api/campaigns', payload)
  }
  updateCampaign = async (id, payload) => {
    return await HttpService.patch(`/api/campaigns/${id}`, payload)
  }
  getCampaignCost = async payload => {
    return await HttpService.post(`/api/campaigns/cost`, payload)
  }
  getCampaignDetails = async id => {
    return await HttpService.get(`/api/campaigns/${id}`)
  }
  getAvailablePhoneNumbers = async () => {
    return await HttpService.get(`/api/campaigns/phonenumbers`)
  }
}

export default new CampaignService()
