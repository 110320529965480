import React, { useState, useEffect } from 'react'
import {
  styled,
  Typography,
  Button,
  IconButton,
  Stack,
  CardActions,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
  Box
} from '@mui/material'
import { CloudUpload, Delete } from '@mui/icons-material'
import MDButton from 'components/MDButton'
import Loader from 'components/Loader'
import AddKnowledgeBaseModal from './components/AddKnowledgeBaseModal'
import kbService from 'services/knowledge-service'
import * as yup from 'yup'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import AddActionModal from './components/AddActionModal'
import UpdateKnowledgeBaseModal from './components/UpdateKnowledgeModal'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { notifySuccess, notifyError } from 'components/Messages'
import AlertDialog from 'components/AlertDialog'
import MDTypography from 'components/MDTypography'
import { FetchingLoader } from 'components/ErrorFallback'
import { FETCH_DATA_STATUS } from 'utils/constants'
import { FetchingError } from 'components/ErrorFallback'
import { replaceDoubleQuotes } from 'utils/utilsFunctions'

const IconWrapper = styled(IconButton)(({ theme }) => ({
  width: '64px',
  height: '64px',
  color: '#888'
}))

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '8px',
  minWidth: '20rem',
  border: '1px solid #ccc',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  height: '12rem',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: '#f9f9f9'
  }
}))

const StyledActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: theme.spacing(1.5, 4),
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: '#f0f0f0'
  }
}))
const MemoizedStyledActionButton = React.memo(StyledActionButton)

const kbItemValidationSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, 'Name must be at least 2 characters')
    .required('Name is required'),
  content: yup
    .string()
    .min(2, 'Content must be at least 2 characters')
    .required('Content is required'),
  type: yup.string().required('Type is required').oneOf(['flashcard', 'file'])
})

const kbValidationSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, 'Name must be at least 2 characters')
    .required('Name is required')
})

const initialValues = {
  type: 'flashcard',
  name: '',
  content: ''
}

const KnowledgeBaseDetails = () => {
  const [openDialogue, setOpenDialogue] = useState('')
  const [mutateLoader, setMutateLoader] = useState(false)
  
  const queryClient = useQueryClient()
  const { id } = useParams()

  const { data, isLoading, status } = useQuery('knowledgeBase', () =>
    kbService.getKnowledgeBase(id)
  )

  const handleKbItemCreate = async () => {
    updateKnowledgeBaseItem({ id, values: formik.values })
  }
  const handleKbItemUpdate = async () => {
    const values = { ...formik.values, itemId: formik.values._id }
    updateKnowledgeBaseItem({ id, values })
  }

  const handleKbChange = async event => {
    const kb = { name: event.target.value }
    try {
      kbValidationSchema.validateSync(kb)
      updateKnowledgeBase({ id, payload: kb })
    } catch (error) {
      console.error(error)
      notifyError('Validation failed: ' + error.message)
      return
    }
  }
  const handleKbItemDelete = async () => {
    deleteKnowledgeBaseItem({ id, itemId: formik.values._id })
    setOpenDialogue('')
  }

  const { mutate: updateKnowledgeBase } = useMutation(
    ({ id, payload }) => kbService.updateKnowledgeBase(id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['knowledgeBase'])
        setOpenDialogue('')
      }
    }
  )
  const { mutate: updateKnowledgeBaseItem } = useMutation(
    ({ id, values }) => kbService.updateKnowledgeBaseItem(id, values),
    {
      onSuccess: payload => {
        queryClient.invalidateQueries(['knowledgeBase'])
        notifySuccess('Knowledge base updated successfully')
        formik.resetForm(initialValues)
        setOpenDialogue('')
      },
      onError: e => {
        notifyError('Error updating knowledge base')
      }
    }
  )
  const { mutate: deleteKnowledgeBaseItem } = useMutation(
    ({ id, itemId }) => kbService.deleteKnowledgeBaseItem(id, itemId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('knowledgeBase')
        formik.resetForm(initialValues)
      }
    }
  )

  const confirmKdbItemDelete = (e, card) => {
    e.stopPropagation()
    formik.resetForm({
      values: card
    })
    setOpenDialogue('delete')
  }

  const handleKbItemUpdateStart = (card, idx) => {
    formik.resetForm({
      values: card
    })
    setOpenDialogue('update')
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: kbItemValidationSchema
  })

  if (isLoading) {
    return <FetchingLoader />
  }

  if (status === FETCH_DATA_STATUS.FAILURE) {
    return <FetchingError />
  }
  return (
    <Grid>
      <Grid item xs={12}>
        <Stack gap={5} mb={5}>
          {data.knowledgeBase?.name && (
            <TextField
              variant="outlined"
              name="knowledgeName"
              component="h1"
              value={data.knowledgeBase.name}
              onChange={handleKbChange}
              sx={{
                fontWeight: 700,
                width: '100%',
                textAlign: { xs: 'center', sm: 'left' }
              }}
              InputProps={{
                ...(mutateLoader && {
                  startAdornment: (
                    <InputAdornment position="start">
                      <CircularProgress size={20} />
                    </InputAdornment>
                  )
                })
              }}
            />
          )}
        </Stack>
      </Grid>
      <Grid
        xs={12}
        item
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        <Grid item xs={12} md={4}>
          <StyledCard
            sx={{
              borderRadius: '8px',
              border: '2px dashed #888',
              // marginRight: 4,
              cursor: 'pointer',
              height: '15rem',
              width: '22rem'
            }}
            onClick={() => {
              setOpenDialogue('add')
            }}
          >
            <Stack
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              gap={3}
            >
              <IconWrapper>
                <CloudUpload />
              </IconWrapper>
              <Typography variant="body1">Add To Knowledge Base</Typography>
            </Stack>
          </StyledCard>
        </Grid>

        {data?.knowledgeBaseItems && data.knowledgeBaseItems.length > 0
          ? data.knowledgeBaseItems.map((card, idx) => {
              return (
                <Grid item xs={12} md={3} key={card._id}>
                  <StyledCard
                    onClick={() => handleKbItemUpdateStart(card, idx)}
                    sx={{ width: '22rem', height: '15rem' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}
                    >
                      <CardContent
                        sx={{
                          width: '22rem',
                          height: '10rem'
                        }}
                      >
                        <Typography
                          variant="body1"
                          component="div"
                          title="test"
                        >
                          {card.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="div"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '4',
                            WebkitBoxOrient: 'vertical'
                          }}
                        >
                          {card.content}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Stack
                          display="flex"
                          flexDirection="row"
                          width="100%"
                          justifyContent="space-between"
                        >
                          <IconButton
                            onClick={e => confirmKdbItemDelete(e, card)}
                          >
                            <Delete />
                          </IconButton>
                          <MemoizedStyledActionButton
                            variant="outlined"
                            disabled={true}
                            onClick={e => {
                              e.stopPropagation()
                              setOpenDialogue('action')
                            }}
                          >
                            ⚡️ Edit Action
                          </MemoizedStyledActionButton>
                        </Stack>
                      </CardActions>
                    </Box>
                  </StyledCard>
                </Grid>
              )
            })
          : null}
      </Grid>
      <AddKnowledgeBaseModal
        open={openDialogue === 'add'}
        onClose={() => setOpenDialogue('')}
        onSubmit={handleKbItemCreate}
        formik={formik}
      />
      <UpdateKnowledgeBaseModal
        formik={formik}
        open={openDialogue === 'update'}
        onClose={() => {
          setOpenDialogue('')
          formik.resetForm({
            values: initialValues
          })
        }}
        onSubmit={handleKbItemUpdate}
      />
      <AddActionModal
        formik={formik}
        id={data?.knowledgeBase?.id}
        open={openDialogue === 'action'}
        onClose={() => setOpenDialogue('')}
        isLoading={mutateLoader}
      />
      <AlertDialog
        open={openDialogue === 'delete'}
        onClose={() => {
          setOpenDialogue('')
          formik.resetForm({
            values: initialValues
          })
        }}
        title="Just Checking In!"
        content={
          <MDTypography variant="body2">
            Hold on! Deleting this will remove it for good. Are you sure you
            want to say goodbye to this item?
          </MDTypography>
        }
      >
        <MDButton
          color="error"
          onClick={handleKbItemDelete}
          disabled={mutateLoader}
        >
          Remove Card
        </MDButton>
      </AlertDialog>
    </Grid>
  )
}

export default KnowledgeBaseDetails
