import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'

function CustomAudioPlayer({ src }) {
  return (
    <AudioPlayer
      customProgressBarSection={[
        RHAP_UI.MAIN_CONTROLS,
        RHAP_UI.CURRENT_TIME,
        <div>/</div>,
        RHAP_UI.DURATION,
        RHAP_UI.PROGRESS_BAR,
        RHAP_UI.VOLUME
      ]}
      customControlsSection={[]}
      customVolumeControls={[]}
      showJumpControls={false}
      src={src}
    />
  )
}

export default CustomAudioPlayer
