import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import NewCampaignForm from './NewCampaignForm'

const CreateNewCampaignModal = ({
  onOkHandler,
  isOpen = false,
  onClose,
  setNewCampaign
}) => {
  return (
    <Dialog onOkHandler={onOkHandler} onClose={onClose} open={isOpen}>
      <DialogTitle textAlign="Center">Create New Campaign</DialogTitle>
      <DialogContent>
        <NewCampaignForm
          onOkHandler={onOkHandler}
          onClose={onClose}
          setNewCampaign={setNewCampaign}
        />
      </DialogContent>
    </Dialog>
  )
}

export default CreateNewCampaignModal
