import MDBox from 'components/MDBox'

import './styles.css'
import MDTypography from 'components/MDTypography'

const TermsOfUse = () => {
  return (
    <MDBox>
      <MDTypography variant="body2">
        The following Terms of Service are entered into by Cald AI, LLC ("Cald
        AI") and you (the "Subscriber") governing our platform services,
        including programs, features, account portals, and support (the
        "Services"). For clarification, Subscriber includes both paying and
        non-paying users. If you disagree, you can't use or access our Services.
        We may change these terms by posting updates on our website. By
        continuing to use our Services, you accept any changes.
      </MDTypography>

      <MDTypography variant="body2">
        <strong>
          Cald AI may monitor but isn't responsible for uploading of
          information, databases or audio messages.
        </strong>{' '}
        In addition to these Terms, Subscriber agrees to comply with all
        applicable laws and requirements below.
      </MDTypography>

      <MDTypography variant="h5">What we need from you</MDTypography>

      <ul>
        <li>
          <MDTypography variant="body2">
            Follow all telemarketing and dialing laws and regulations.
          </MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            Provide accurate Caller ID information.
          </MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            Give all legally required disclosures.
          </MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            You're solely responsible for complying with these laws.
          </MDTypography>
        </li>
      </ul>

      <MDTypography variant="h5">Getting consent</MDTypography>

      <MDTypography variant="body2">
        You must get recipient consent as needed by law and understand different
        consent levels. Without consent or a legal exemption, don't communicate.
        You must keep consent records and provide them if we request.{' '}
      </MDTypography>

      <MDTypography variant="h5">Using your data</MDTypography>

      <MDTypography variant="body2">
        You give us a royalty-free license to use, copy, reproduce, display,
        distribute, and create derivative works from Subscriber Data to fulfill
        our obligations.
      </MDTypography>

      <MDTypography variant="body2">
        Usage Data belongs solely to us and isn't Subscriber Data. We can use
        and disclose it without restriction.
      </MDTypography>

      <MDTypography variant="h5">Keeping it confidential</MDTypography>

      <MDTypography variant="body2">
        We'll both protect each other's confidential information like our own.
        We'll only disclose it on a need-to-know basis to fulfill obligations
        here. We won't allow unauthorized copying, remove it, or use it in
        violation of laws.
      </MDTypography>

      <MDTypography variant="body2">
        These obligations don't apply to:
      </MDTypography>

      <ul>
        <li>
          <MDTypography variant="body2">
            Information already known without obligation of confidence
          </MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            Independently developed information
          </MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            Information acquired from third parties without obligation of
            confidence
          </MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            Publicly available information
          </MDTypography>
        </li>
      </ul>

      <MDTypography variant="body2">
        We may disclose Confidential Information if legally required after
        reasonable notice to the other party so they can seek protections,
        unless prohibited by law.
      </MDTypography>

      <MDTypography variant="h5">Compensating losses</MDTypography>

      <MDTypography variant="body2">
        You'll defend and indemnify Cald AI Indemnitees against claims,
        investigations, demands, actions, proceedings, losses, judgments,
        awards, settlements, damages, fines, injuries, penalties, and costs
        (including attorneys’ fees) arising from:{' '}
      </MDTypography>

      <ul>
        <li>
          <MDTypography variant="body2">Breach of this Agreement</MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            Your negligence or misconduct
          </MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            Your Service use or obtained information
          </MDTypography>
        </li>
      </ul>

      <MDTypography variant="h5">No guarantees</MDTypography>

      <MDTypography variant="body2">
        USE OF THIS SERVICE IS AT YOUR OWN RISK. ALL MATERIALS, INFORMATION,
        CONTENT, PRODUCTS, SOFTWARE, PROGRAMS, AND SERVICES ARE PROVIDED "AS
        IS," WITH NO WARRANTIES OR GUARANTEES WHATSOEVER. WE EXPRESSLY DISCLAIM
        TO THE FULLEST EXTENT PERMITTED BY LAW ALL EXPRESS, IMPLIED, STATUTORY,
        AND OTHER WARRANTIES, GUARANTEES, OR REPRESENTATIONS, INCLUDING, WITHOUT
        LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY AND INTELLECTUAL PROPERTY
        RIGHTS.
      </MDTypography>

      <MDTypography variant="h5">Limiting liabilities</MDTypography>

      <MDTypography variant="body2">
        UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL OR EQUITABLE THEORY, WHETHER
        IN TORT, CONTRACT, STRICT LIABILITY OR OTHERWISE, SHALL Cald AI OR ANY
        OF ITS EMPLOYEES, DIRECTORS, OFFICERS, AGENTS, SHAREHOLDERS,
        SUBSIDIARIES, OR AFFILIATES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR
        ANY DIRECT OR INDIRECT LOST PROFITS OR LOST BUSINESS DAMAGES, OR
        INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY
        NATURE, ARISING OUT OF OR IN CONNECTION WITH THE SERVICE OR THE
        AGREEMENT, EVEN IF AN AUTHORIZED REPRESENTATIVE OF Cald AI HAS BEEN
        ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
      </MDTypography>

      <MDTypography variant="h5">Following the law</MDTypography>

      <MDTypography variant="body2">
        Use our Services according to all applicable laws and regulations. This
        includes the Telephone Consumer Protection Act (TCPA). You acknowledge
        all calls are recorded. You're solely responsible for legal compliance
        and liability.
      </MDTypography>

      <MDTypography variant="h5">Third parties</MDTypography>

      <MDTypography variant="body2">
        Our Service may interact with third party services we don't control.
        Your use of third party services is at your own risk per their terms. We
        aren't responsible for third party service quality, fees, or damages
        from their use. You grant us an irrevocable perpetual license to use any
        third party service content connected to us or our Service.
      </MDTypography>

      <MDTypography variant="h5">We're partners</MDTypography>

      <MDTypography variant="body2">
        We're independent contractors, not employees, agents, partners or joint
        ventures. Neither of us can bind the other.
      </MDTypography>

      <MDTypography variant="h5">Settling disputes</MDTypography>

      <MDTypography variant="body2">
        Disputes must be resolved solely by arbitration per American Arbitration
        Association rules in Maricopa County, Arizona. Small claims actions are
        permitted. The arbitrator can't award punitive, special, consequential,
        or indirect damages. Each party pays its own attorneys’ fees, costs, and
        expenses, regardless of outcome. No class or representative arbitration
        is permitted. BY AGREEING TO THIS AGREEMENT AND USING THE SERVICES, YOU
        IRREVOCABLY WAIVE ANY RIGHT TO JOIN CLAIMS WITH OTHERS OR PRESENT CLAIMS
        IN COURT OR BEFORE A JURY.
      </MDTypography>

      <MDTypography variant="h5">Arizona law governs</MDTypography>

      <MDTypography variant="body2">
        Arizona law governs this Agreement without regard to conflicts of law
        principles. Lawsuits must be in Maricopa County, Arizona courts.
      </MDTypography>

      <MDTypography variant="h5">Keeping your data secure</MDTypography>

      <MDTypography variant="body2">
        Comply with all applicable U.S. and other export laws. You can't use
        Services for EU, EEA, UK, Swiss or non-US persons' data.
      </MDTypography>

      <MDTypography variant="h5">Severability</MDTypography>

      <MDTypography variant="body2">
        If any provision is unenforceable, the rest remain in effect. Portions
        like warranty disclaimers and limitations of liability survive
        termination.
      </MDTypography>

      <MDTypography variant="h5">No implied waivers</MDTypography>

      <MDTypography variant="body2">
        Waivers must be in writing signed by the waiving party. Consent to one
        breach doesn’t waive later breaches.
      </MDTypography>

      <MDTypography variant="h5">Miscellaneous</MDTypography>

      <MDTypography variant="body2">
        Each party represents it can legally enter this Agreement. Except for
        your fee payments, force majeure events suspend obligations. You can't
        assign rights/obligations without our consent. We may assign ours
        without notice. Ambiguities won't be construed against us.
      </MDTypography>
    </MDBox>
  )
}

export default TermsOfUse
