import { Box, Stack } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import Modal from 'components/Modal'
import { useFormik } from 'formik'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import knowledgeService from 'services/knowledge-service'
import * as yup from 'yup'

const getKnowledgeContent = formik => {
  return (
    <MDBox>
      <MDTypography>
        Create a new knowledge base for AI to answer questions for you
      </MDTypography>
      <Stack
        sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}
        my={2}
        px={2}
      >
        <MDTypography variant="body1">Name</MDTypography>
        <MDInput
          fullWidth
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          placeholder="eg. Support knowledge base"
          helperText={
            formik.touched.name &&
            Boolean(formik.errors.name) &&
            formik.errors.name
          }
          error={formik.touched.name && Boolean(formik.errors.name)}
        />
      </Stack>
    </MDBox>
  )
}

function CreateKnowledgeBaseModal({ open, onClose }) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(
    ({ values }) => knowledgeService.addKnowledgeBase(values),
    {
      onSuccess: ({ knowledgeBase }) => {
        queryClient.invalidateQueries(['knowledge-base'])
        onClose()
        navigate(`/knowledgebases/${knowledgeBase._id}`)
      }
    }
  )
  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validateOnChange: false,
    validationSchema: yup.object({
      name: yup
        .string()
        .min(2, 'Name must be at least 2 characters')
        .required('Name field is required')
    }),
    onSubmit: async values => {
      mutate({ values })
    }
  })
  return (
    <MDModal
      open={open}
      onClose={onClose}
      sx={{
        padding: '1.5rem',
        borderRadius: '8px',
        borderColor: '#FAFAFA',
        overflow: 'auto'
      }}
    >
      <MDBox component="form">
        <MDBox sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <MDTypography variant="h5">Create a New Knowledge Base</MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography>
            Create a new knowledge base for AI to answer questions for you
          </MDTypography>
          <Stack
            sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}
            my={2}
            px={2}
          >
            <MDTypography variant="body1">Name</MDTypography>
            <MDInput
              fullWidth
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder="eg. Support knowledge base"
              helperText={
                formik.touched.name &&
                Boolean(formik.errors.name) &&
                formik.errors.name
              }
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
          </Stack>
        </MDBox>
        <MDBox sx={{ display: 'flex', justifyContent: 'end' }}>
          <MDButton color="success" onClick={formik.handleSubmit}>
            {isLoading ? 'Creating...' : 'Create'}
          </MDButton>
        </MDBox>
      </MDBox>
    </MDModal>
  )
}

export default CreateKnowledgeBaseModal
