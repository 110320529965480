import React from 'react'
import { styled } from '@mui/system'

import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import { Button, Grid } from '@mui/material'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

export default function Instructions(props) {
  const { editorMode } = props
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)

  const totalSteps = editorMode === 'advanced' ? 4 : 3

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <Grid container style={{ justifyContent: 'center' }}>
      {editorMode === 'advanced'
        ? getInstructionContentAdvanced(activeStep)
        : getInstructionContent(activeStep)}

      <MobileStepper
        variant="dots"
        steps={totalSteps}
        position="static"
        activeStep={activeStep}
        sx={{ maxWidth: 400, flexGrow: 1 }}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === totalSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Grid>
  )
}

const getInstructionContent = stepIndex => {
  switch (stepIndex) {
    case 0:
      return <InstructionContentOne />
    case 1:
      return <InstructionContentTwo />
    case 2:
      return <InstructionContentThree />

    default:
      return null
  }
}

const getInstructionContentAdvanced = stepIndex => {
  switch (stepIndex) {
    case 0:
      return <InstructionContentAdvanced />
    case 1:
      return <InstructionContentOne />
    case 2:
      return <InstructionContentTwo />
    case 3:
      return <InstructionContentThree />

    default:
      return null
  }
}

const ParentListItem = styled('div')({
  marginBottom: '10px', // Adjust the margin as needed
  listStyleType: 'square' // Adjust the bullet point style (disc, circle, square)
})

const ChildListItem = styled('li')({
  paddingLeft: '20px', // Adjust the padding as needed
  listStyleType: 'circle' // Adjust the bullet point style (disc, circle, square)
})

export const InstructionContentAdvanced = () => {
  return (
    <MDBox>
      <MDTypography variant="h5">Tips for using advanced editor:</MDTypography>
      <ul>
        <ParentListItem>
          <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
            Although you are using advanced model, we highly recommend you model
            our template prompts. There are lot of tiny, simple things we do
            that are crucial to Cald working.
          </MDTypography>
        </ParentListItem>

        <ParentListItem>
          <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
            VITAL for appointment booking: Make sure to place the variable{' '}
            {'{calendarAvailabilities}'} wherever you want cal.com’s API to
            place available times for appointments. Without this, Cald will have
            no way of knowing what times are available. You can reference our
            default prompts to see how we execute this personally.
          </MDTypography>
        </ParentListItem>
        <ParentListItem>
          <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
            When you want Cald to stop speaking and wait for your contact to
            respond just paste “*Wait For Prospect To Respond*” on the next
            line. Without this, Cald will often read multiple parts of your
            script at the same time.
          </MDTypography>
          <ChildListItem>
            <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
              (Reference how we do this in the template prompt and take note of
              the paragraph we have in the instructions that causes Cald to
              follow this instruction closely.)
            </MDTypography>
          </ChildListItem>
        </ParentListItem>
      </ul>
    </MDBox>
  )
}

export const InstructionContentOne = () => {
  return (
    <MDBox>
      <MDTypography variant="h5">How not to confuse AI:</MDTypography>
      <ul>
        <ParentListItem>
          <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
            Don't type out ANY symbols or else you may confuse your AI.
          </MDTypography>
          <ul>
            <ChildListItem>
              {' '}
              <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
                Instead of “$” type out “dollar”.
              </MDTypography>
            </ChildListItem>
            <ChildListItem>
              {' '}
              <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
                Instead of “%” type out “percent”.
              </MDTypography>
            </ChildListItem>
            <ChildListItem>
              <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
                Instead of “1-10” type out “one to ten”.
              </MDTypography>
            </ChildListItem>
            <ChildListItem>
              <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
                Instead of Cald.ai” type out “Cald dot AI”.
              </MDTypography>
            </ChildListItem>
          </ul>
        </ParentListItem>

        <ParentListItem>
          <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
            When typing acronyms make sure to space them out. Instead of “PDF”
            type out “P D F.” This is easier for your Cald to understand.
          </MDTypography>
        </ParentListItem>
        <ParentListItem>
          <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
            Avoid starting lines in your script with fillers like “uhhh”,
            “ummm”, “yah”, etc. Cald does this automatically when it's thinking,
            so by adding them in your script, Cald might accidentally repeat
            itself.
          </MDTypography>
        </ParentListItem>
      </ul>
    </MDBox>
  )
}

export const InstructionContentTwo = () => {
  return (
    <MDBox>
      <MDTypography variant="h5">How to insert variables:</MDTypography>
      <ul>
        <ParentListItem>
          <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
            Use <span>{'{prospectName}'}</span> to dynamically insert the
            prospect's name into the script.{' '}
          </MDTypography>
          <ul>
            <ChildListItem>
              <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
                Ex: "Hey, <span>{'{prospectName}'}</span>!"
              </MDTypography>
            </ChildListItem>
            <ChildListItem>
              <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
                <strong>Note:</strong> <span>{'{prospectName}'}</span> is the
                only contact variable Cald currently supports. We will be adding
                more (email, phone #, etc) in some of our next few updates.
              </MDTypography>
            </ChildListItem>
          </ul>
        </ParentListItem>

        <ParentListItem>
          <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
            You can use <span>[brackets]</span> in your script when you want
            Cald to fill in the blank with previous conversational context.
          </MDTypography>
          <ul>
            <ChildListItem>
              <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
                Ex: "Tell me more about{' '}
                <span>[insert the problem the prospect just stated here]</span>
                ."
              </MDTypography>
            </ChildListItem>
            <ChildListItem>
              <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
                <strong>Important:</strong> It is important you test this
                thoroughly via testing your AI to make sure the way you worded
                it doesn't cause the AI to say what's in the brackets out loud.
              </MDTypography>
            </ChildListItem>
          </ul>
        </ParentListItem>
      </ul>
    </MDBox>
  )
}

export const InstructionContentThree = () => {
  return (
    <MDBox>
      <p>
        <MDTypography variant="h5">Final Tips:</MDTypography>
      </p>
      <ul>
        <li>
          <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
            To diminish the effect of any latency, add this line somewhere near
            the beginning of your script:
            <em>
              "and by the way, the audio has been a bit laggy today so if I cut
              you off or if there's a bit of a delay, I apologize in advance."
            </em>
          </MDTypography>
        </li>
        <li>
          <MDTypography variant="body2" sx={{ fontWeight: '500' }}>
            Make sure to test & tweak your script <strong>A LOT</strong> before
            getting Cald on live calls. The magic happens when testing your AI
            and tweaking it until the details are great.
          </MDTypography>
        </li>
      </ul>
    </MDBox>
  )
}
