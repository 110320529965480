import { Stack, Typography } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'
import MDButton from 'components/MDButton'
import VideoPlayer from 'components/VideoPlayer'

export default function NoItemsView(props) {
  const { onClick, title, content, cta, tutorialLink = false, tutorialOpenText = 'see how', tutorialCloseText = 'done' } = props

  const handleOnClick = () => {
    onClick()
  }

  return (
    <MDBox>
      <Stack gap={4}>
        <Typography
          variant="h4"
          component="h1"
          sx={{ pl: { sm: 4 }, textAlign: 'center' }}
        >
          {title}
        </Typography>
        <MDBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 5
          }}
        >
          <MDTypography sx={{ textAlign: 'center' }}>{content}</MDTypography>
          {tutorialLink && <VideoPlayer openText={tutorialOpenText} closeText = {tutorialCloseText} url = {tutorialLink} />}
          <MDButton color="success" onClick={handleOnClick}>
            {cta}
          </MDButton>
        </MDBox>
      </Stack>
    </MDBox>
  )
}
