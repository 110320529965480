import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

function MDSelect({ label, name, options, onChange, value, sx }) {
  return (
    <FormControl fullWidth sx={{ marginTop: '1rem', ...sx }}>
      <InputLabel id={`${name}Label`}>{label}</InputLabel>
      <Select
        labelId={`${name}Label`}
        name={name}
        label={label}
        value={value}
        sx={{ height: '3rem' }}
        onChange={onChange}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MDSelect
