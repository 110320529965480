import { Check, Close, Delete, Edit, Add } from '@mui/icons-material'
import { IconButton, Stack } from '@mui/material'
import AlertDialog from 'components/AlertDialog'
import { LoadingSpinner } from 'components/LoadingSpinner'
import MDButton from 'components/MDButton'
import MDSelect from 'components/MDSelect'
import MDTypography from 'components/MDTypography'
import { notifyError, notifySuccess } from 'components/Messages'
import { DataTable } from 'components/Tables/material'
import { useMaterialUIController } from 'context'
import React, { useCallback, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import agentService from 'services/agent-service'
import phoneNumberService from 'services/phoneNumber-service'
import { getTimeDifferenceString } from 'utils/utilsFunctions'
import AddPhoneNumber from './add'
import { set } from 'date-fns'

function PhoneNumbersPage() {
  const [agent, setAgent] = React.useState('')
  const [controller] = useMaterialUIController()
  const { darkMode } = controller
  const queryClient = useQueryClient()

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [deleteItem, setDeleteItem] = React.useState(null)
  const [showAddAgentDialog, setShowAddAgentDialog] = React.useState(false)
  const [selectedPhoneNumber, setSelectedPhoneNumber] = React.useState(null)
  const handleAgentChangeMutation = async (agentId, phoneNumberId) => {
    try {
      const selectedAgentId = agentId

      const result = await phoneNumberService.editPhoneNumber({
        phoneNumberId: phoneNumberId,
        agentId: selectedAgentId
      })

      if (result) {
        queryClient.invalidateQueries('phoneNumbers')
        notifySuccess('Agent updated successfully')
      } else {
        notifyError(result?.error || 'Failed to update agent')
      }
    } catch (error) {
      console.error(error)
      notifyError('An error occurred while updating agent')
    }
  }

  const { data, isLoading, isError } = useQuery(
    'phoneNumbers',
    phoneNumberService.getUserPhoneNumbers,
    {
      select: response => {
        if (response && response.phoneNumbers) {
          return response.phoneNumbers.map(number => ({
            ...number,
            boughtAt: getTimeDifferenceString(number.boughtAt)
          }))
        }
        return []
      },
      onError: error => {
        notifyError('Error loading your phone numbers: ' + error.message)
      }
    }
  )
  const {
    data: agentList,
    isLoading: isLoadingList,
    isError: isErrorList
  } = useQuery('agents', agentService.getAgents, {
    select: response => {
      if (response && response.agents) {
        return response.agents.map(agent => ({
          value: agent._id,
          label: agent.agentName
        }))
      }
      return []
    },
    onError: error => {
      notifyError('Error loading agents: ' + error.message)
    }
  })
  const ActionCell = ({ params, darkMode }) => (
    <Stack sx={{ flexDirection: 'row', gap: 4 }}>
      <IconButton onClick={e => handleDelete(e, params.row.original)}>
        <Delete color={darkMode ? 'white' : ''} />
      </IconButton>
    </Stack>
  )

  const handleDelete = useCallback((e, row) => {
    e.stopPropagation()
    setDeleteItem(row)
    setShowDeleteDialog(true)
  }, [])
  const handleAddAgent = useCallback((e, row) => {
    e.stopPropagation()
    setSelectedPhoneNumber(row)
    setShowAddAgentDialog(true)
  }, [])

  const refresh = () => {
    queryClient.invalidateQueries(['phoneNumbers'])
  }
  const deletePhoneNumberMutation = useMutation(
    phoneNumberId => phoneNumberService.deletePhoneNumber({ phoneNumberId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['phoneNumbers'])
        notifySuccess('Phone number deleted')
      },
      onError: error => notifyError(error?.error)
    }
  )

  const columns = useMemo(
    () => [
      { accessorKey: 'friendlyName', header: 'Number' },
      { accessorKey: 'isoCountry', header: 'Country' },
      { accessorKey: 'region', header: 'Region' },
      {
        accessorKey: 'agent',
        accessorFn: row => row.agent?.name,
        header: 'Agent',
        Cell: info => {
          const id = info.cell.row.original.agentId?._id
          const handleDetailsClick = e => {
            window.open(`/agents/${id}`, '_blank')
          }
          if (!info.cell.row.original.agentId) {
            return (
              <Stack sx={{ flexDirection: 'row', gap: 4 }}>
                <IconButton
                  onClick={e => handleAddAgent(e, info.cell.row.original)}
                >
                  <Add color={darkMode ? 'white' : ''} />
                </IconButton>
              </Stack>
            )
          }
          return (
            <Stack sx={{ flexDirection: 'row', gap: 2 }}>
              <MDButton
                size="small"
                onClick={handleDetailsClick}
              >
                {info.cell.row.original.agentId.agentName}
              </MDButton>
              <IconButton
                onClick={e => handleAddAgent(e, info.cell.row.original)}
              >
                <Edit color={darkMode ? 'white' : ''} />
              </IconButton>
            </Stack>
          )
        }
      },
      {
        accessorKey: 'voice',
        accessorFn: row => row.capabilities?.voice,
        header: 'Voice',
        Cell: ({ cell }) => {
          return cell?.getValue() ? <Check /> : <Close />
        }
      },
      {
        accessorKey: 'sms',
        accessorFn: row => row.capabilities?.sms,
        header: 'SMS',
        Cell: ({ cell }) => {
          return cell?.getValue() ? <Check /> : <Close />
        }
      },
      {
        accessorKey: 'mms',
        accessorFn: row => row.capabilities?.mms,
        header: 'MMS',
        Cell: ({ cell }) => {
          return cell?.getValue() ? <Check /> : <Close />
        }
      },
      {
        accessorKey: 'fax',
        accessorFn: row => row.capabilities?.fax,
        header: 'Fax',
        Cell: ({ cell }) => {
          return cell?.getValue() ? <Check /> : <Close />
        }
      },
      { accessorKey: 'boughtAt', header: 'Bought' },
      {
        accessorKey: 'Action',
        header: 'Action',
        Cell: params => <ActionCell params={params} darkMode={darkMode} />
      }
    ],
    [darkMode, agentList]
  )
  if (isLoading || isLoadingList) {
    return <LoadingSpinner />
  }

  if (isError) {
    return <div>Error loading phone numbers</div>
  }
  const renderAddAgentModalContent = () => (
    <MDSelect
      label="Selected Agent"
      value={agent}
      name="agent"
      options={agentList}
      onChange={e => setAgent(e.target.value)}
    ></MDSelect>
  )

  return (
    <>
      <AddPhoneNumber onClose={refresh} />
      <DataTable table={{ columns, rows: data ?? [] }} canSearch />
      <AlertDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        title="Just Checking In!"
        content={renderDeleteModalContent(deleteItem)}
      >
        <MDButton onClick={() => setShowDeleteDialog(false)}>Cancel</MDButton>
        <MDButton
          color="error"
          onClick={() => {
            deletePhoneNumberMutation.mutate(deleteItem.phoneNumberId)
            setShowDeleteDialog(false)
          }}
        >
          Delete
        </MDButton>
      </AlertDialog>
      <AlertDialog
        open={showAddAgentDialog}
        onClose={() => setShowAddAgentDialog(false)}
        title="Assign an Agent"
        content={renderAddAgentModalContent()}
      >
        <MDButton onClick={() => setShowAddAgentDialog(false)}>Cancel</MDButton>
        <MDButton
          color="success"
          onClick={() => {
            handleAgentChangeMutation(agent, selectedPhoneNumber.phoneNumberId)
            setShowAddAgentDialog(false)
            setAgent('')
          }}
        >
          Assign
        </MDButton>
      </AlertDialog>
    </>
  )
}
const renderDeleteModalContent = deleteItem => (
  <div>
    About to release {deleteItem?.phoneNumber}. Proceed?
    <br />
    <br />
    <MDTypography variant="cation" component="div">
      This action cannot be undone. However, if a campaign is using the number,
      it will continue running it until the campaign is stopped or finished.
    </MDTypography>
    <br />
    <MDTypography variant="cation" component="div">
      Keep in mind costs will be charged until the last day of the monthly
      billing cycle.
    </MDTypography>
    {/* <strong>Active Campaigns:</strong> 0 */}
  </div>
)

export default PhoneNumbersPage
