import HttpService from './http.service'

class ListService {
  getLists = async () => {
    return await HttpService.get('/api/groups')
  }
  getList = async id => {
    return await HttpService.get(`/api/groups/${id}`)
  }
  deleteList = async id => {
    return await HttpService.delete(`/api/groups/${id}`)
  }
  addList = async payload => {
    return await HttpService.post('/api/groups', payload)
  }
  // updateAgent = async (id, payload) => {
  //     return await HttpService.patch(`/agents/${id}`, payload);
  // };
}

export default new ListService()
