import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
  Stack,
  Typography,
  Link
} from '@mui/material'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useFormik } from 'formik'
import { ReactSpreadsheetImport } from 'react-spreadsheet-import'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useMutation, useQueryClient } from 'react-query'
import contactService from 'services/contact-service'
import { notifyError } from 'components/Messages'
import MDTypography from 'components/MDTypography'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'

export const parsePhoneNumber = phone_number => {
  // Remove any non-digit characters from the phone number
  if (!phone_number) {
    return null
  }
  const digitsOnly = phone_number.replace(/\D/g, '')

  // Check the length of the phone number
  if (digitsOnly.length === 10) {
    // US phone number format with 10 digits
    return '1' + digitsOnly
  } else if (digitsOnly.length === 11) {
    // US phone number format with country code (11 digits starting with 1)
    return digitsOnly
  } else if (
    digitsOnly.length === 12 &&
    digitsOnly[0] === '4' &&
    digitsOnly[1] === '4'
  ) {
    // UK phone number format (11 digits starting with 0)
    return digitsOnly
  } else {
    // Invalid phone number
    return null
  }
}

function UploadContactsWizard({ open, onClose, lists }) {
  const queryClient = useQueryClient()

  const { mutate } = useMutation(values => contactService.addContact(values), {
    onSuccess: () => {
      queryClient.invalidateQueries(['contactsAndLists'])
    },
    onError: error => {
      notifyError(error.message)
    }
  })

  const formik = useFormik({
    initialValues: {
      file: null,
      activeStep: 0,
      fileData: [],
      listName: '',
      terms: false,
      invalidPhones: [],
      additionalFields: ''
    },
    validationSchema: yup.object({
      terms: yup
        .boolean()
        .oneOf(
          [true],
          'Please confirm that you have reviewed the file and are ready to upload'
        )
    }),
    onSubmit: async values => {
      const file_name = values.file?.name
      mutate({
        listName: values.listName,
        data: values.fileData,
        fileName: file_name,
        additionalFields: values.additionalFields
      })
      formik.resetForm()
      onClose()
    }
  })
  const handleImporterClose = () => {
    formik.setFieldValue('activeStep', 0)
    onClose()
  }
  useEffect(() => {}, [formik.values.fileData])

  const steps = [
    <ListInfo formik={formik} lists={lists} />,
    <CustomVariables formik={formik} />,
    <FileImporter
      formik={formik}
      onUpload={formik.handleSubmit}
      onClose={handleImporterClose}
    />
  ]

  return (
    <MDModal
      open={open}
      onClose={onClose}
      sx={{
        padding: '1.5rem',
        borderRadius: '8px',
        width: { xs: '90%', md: '90%' },
        maxWidth: '90rem',
        maxHeight: '90%',
        overflow: 'auto',
        borderColor: 'white',
        outline: 'none',
        minHeight: '40vh'
      }}
    >
      <Stack>{steps[formik.values.activeStep]}</Stack>
    </MDModal>
  )
}

export function validateAdditionalFields(value) {
  // Split the value into fields
  let fields = value
    .split(',')
    // trim each field
    .map(field => field.trim())
    // remove empty fields
    .filter(field => field.length > 0)
    // remove duplicates
    .filter((field, index, self) => self.indexOf(field) === index)

  // Replace white spaces within fields
  fields = fields.map(field => field.replace(/\s/g, ''))

  const hasSpecialCharacters = /[^a-zA-Z0-9\s]/g
  const onlyNumbers = /^\d+$/

  const containsInvalidCharacters = fields.some(field =>
    hasSpecialCharacters.test(field)
  )
  const containsOnlyNumbers = fields.some(field => onlyNumbers.test(field))

  if (containsInvalidCharacters) {
    return {
      fields: null,
      error: 'Field names can contain only letters and numbers.'
    }
  }

  if (containsOnlyNumbers) {
    return {
      fields: null,
      error: 'Field names cannot consist of only numbers.'
    }
  }

  // Check if any field is one of the forbidden fields
  const forbiddenFields = ['phone', 'prospectname', 'email']
  //ignore case when checking for forbidden fields
  if (fields.some(field => forbiddenFields.includes(field.toLowerCase()))) {
    return {
      fields: null,
      error:
        'Do not enter phone, prospectName, email - as these fields are included already.'
    }
  }

  // Check if any field contains more than 500 characters
  if (fields.some(field => field.length >= 20)) {
    return {
      fields: null,
      error: 'Field name cannot be longer than 20 characters.'
    }
  }

  // If all checks pass, return the fields and an empty error message
  return { fields, error: '' }
}

function CustomVariables({ formik }) {
  const [errorMessage, setErrorMessage] = useState('')
  return (
    <>
      <MDTypography variant="h4">
        Additional Data to be Uploaded (optional){' '}
      </MDTypography>

      <MDTypography variant="body2" mb={2}>
        Note, in addition to phone, name and email, you can specifiy up to 10
        fields you would like to upload for your contacts. These fields can be
        used to provide additional context for your agents.
      </MDTypography>
      <MDTypography variant="body2" mb={2}>
        If you don't have any additioanl fields, simply click "next" to proceed.
      </MDTypography>

      <MDTypography variant="h5">
        Rules for including the additional fields:
      </MDTypography>
      <MDTypography variant="caption" mb={2}>
        <ul>
          <li>Use comma to specify multiple additional fields</li>
          <li>
            Use CamelCase for field name, no dashes, underscores or spaces
          </li>
          <li>Example: website, city, country, linkedinProfile</li>
          <li>
            Do not enter phone, prospectName, email - as these fields are
            included already
          </li>
          <li>
            Note, that each additional field cannot contain more than 500
            characters.
          </li>
        </ul>
      </MDTypography>

      <MDInput
        name="additionalFields"
        label="Additional Fields"
        placeholder="website, city, country, linkedInProfile"
        formik={formik.values.additionalFields}
        helperText={errorMessage}
        error={!!errorMessage}
        onChange={e => {
          const { fields, error } = validateAdditionalFields(e.target.value)
          setErrorMessage(error)
          if (errorMessage) {
            // Display the error message
          } else {
            // Update the value
            formik.setFieldValue('additionalFields', fields)
          }
        }}
      />
      <MDBox sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
        <MDButton
          color="success"
          sx={{ width: '100px', mr: 2 }}
          onClick={() => {
            formik.setFieldValue('additionalFields', null)
            formik.setFieldValue('activeStep', 2)
          }}
        >
          Skip
        </MDButton>
        <MDButton
          color="success"
          sx={{ width: '100px' }}
          onClick={() => {
            formik.setFieldValue('activeStep', 2)
          }}
        >
          Next
        </MDButton>
      </MDBox>
    </>
  )
}

function ListInfo({ formik, lists }) {
  return (
    <MDBox component="form" sx={{ display: 'flex', flexDirection: 'column' }}>
      {lists !== undefined && lists.length > 0 && (
        <>
          <MDTypography variant="h4">
            Select or create a new Contact Group
          </MDTypography>
          <MDTypography variant="caption" mb={2}>
            The group will be used to organized your contacts into compaign
            audiences. You can create a new group or pick from exising one to
            assign contacts to them.
          </MDTypography>

          <Autocomplete
            freeSolo
            options={lists}
            getOptionLabel={option => option.listName}
            label="Group Name"
            onInputChange={(event, newInputValue) => {
              formik.setFieldValue('listName', newInputValue)
            }}
            renderInput={params => <TextField name="listName" {...params} />}
          />
        </>
      )}
      {(lists === undefined || lists.length === 0) && (
        <>
          <Typography variant="h4" mb={2}>
            Naming Your First Group - Exciting!
          </Typography>
          <MDInput
            name="listName"
            label="Group Name"
            formik={formik.values.listName}
            onChange={e => formik.setFieldValue('listName', e.target.value)}
          />
        </>
      )}
      <Stack sx={{ gap: 2, py: 1, width: '100%' }}>
        {formik.touched.list && formik.errors.list && (
          <Typography color="red" fontSize="12px">
            Group value required
          </Typography>
        )}
        <Typography variant="h6" style={{ margin: '8px' }}>
          Before we leap into action
        </Typography>
        <Typography variant="body2" style={{ marginBottom: '16px' }}>
          Let's make sure everything's on the up and up! By ticking this box
          below, you're confirming that each person on your list has said 'Yes,
          please!' to receiving AI and pre-recorded calls from you. It's also a
          promise from you to us that you'll stick to the rules and guidelines
          of laws like the TCPA and TSR in all your communications. Let's keep
          it friendly and lawful!
        </Typography>
        <FormControlLabel
          sx={{ display: 'flex', mt: 2 }}
          control={
            <Checkbox
              checked={formik.values.terms}
              onChange={evt => {
                formik.setFieldValue('terms', evt.target.checked)
              }}
            />
          }
          label="I confirm I have consent & agree to abide by the laws"
        />
        {formik.touched.terms && formik.errors.terms && (
          <FormHelperText error={!!formik.errors.terms}>
            {formik.errors.terms}
          </FormHelperText>
        )}
        <Typography variant="body2" style={{ marginTop: '16px' }}>
          Need a refresher on what these laws entail?
          <span> </span>
          <Link
            href="https://www.fcc.gov/general/telemarketing-and-robocalls"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'blue'
            }}
          >
            Click here for a quick overview!
          </Link>
        </Typography>
        <MDBox sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
          <MDButton
            color="success"
            sx={{ width: '100px' }}
            disabled={
              formik.values.terms === false ||
              (formik.values?.listName?.length ?? 0) < 3
            }
            onClick={() => {
              formik.setFieldValue('activeStep', 1)
            }}
          >
            Next
          </MDButton>
        </MDBox>
      </Stack>
    </MDBox>
  )
}
function FileImporter({ formik, onUpload, onClose }) {
  const fields = [
    {
      // Visible in table header and when matching columns.
      label: 'Name',
      // This is the key used for this field when we call onSubmit.
      key: 'name',
      // Allows for better automatic column matching. Optional.
      alternateMatches: [
        'first name',
        'firstName',
        'first-name',
        'contact',
        'contact-name',
        'contactName',
        'prospect name',
        'prospectName',
        'lead',
        'leadName',
        'lead-name'
      ],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: 'input'
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: 'Stephanie'
      // Can have multiple validations that are visible in Validation Step table.
    },
    {
      // Visible in table header and when matching columns.
      label: 'Email',
      // This is the key used for this field when we call onSubmit.
      key: 'email',
      // Allows for better automatic column matching. Optional.
      alternateMatches: ['email', 'email_1'],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: 'input'
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: 'Stephanie@mb.com',
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          regex:  /^$|^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
          errorMessage: 'failed email validation',
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: 'error'
        }
      ]
    },
    {
      // Visible in table header and when matching columns.
      label: 'Phone',
      // This is the key used for this field when we call onSubmit.
      key: 'phone_number',
      // Allows for better automatic column matching. Optional.
      alternateMatches: ['Phone number', 'phone', 'phone_number', 'phone_1'],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: 'input'
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: '+1 415-244-3322',
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: 'unique',
          errorMessage: 'phone number must be unique',
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: 'error'
        },
        {
          // Can be "required" / "unique" / "regex"
          rule: 'required',
          errorMessage: 'Phone Number is required',
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: 'error'
        }
      ]
    }
  ]
  if (formik.values.additionalFields) {
    formik.values.additionalFields.forEach(field => {
      fields.push({
        label: field,
        key: field,
        alternateMatches: [field],
        fieldType: {
          type: 'input'
        }
      })
    })
  }
  let isOpen = true
  const onSubmit = data => {
    formik.setFieldValue('fileData', data.validData)
    onUpload()
    isOpen = false
  }
  const handleClose = () => {
    isOpen = false
    onClose()
  }
  return (
    <ReactSpreadsheetImport
      rowHook={(data, addError) => {
        // Validation
        let phone_number = parsePhoneNumber(data.phone_number)
        if (!phone_number) {
          addError('phone_number', {
            message: 'Skipping, failed to validate format:' + data.phone_number,
            level: 'error'
          })
        }
        // Transformation
        return { ...data, phone_number }
        // Sorry John
      }}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={onSubmit}
      fields={fields}
      isNavigationEnabled={true}
    />
  )
}

export default UploadContactsWizard
