import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import MDBox from 'components/MDBox'

export default function ScriptSourceSelector(props) {
  const { onSelected } = props
  const [selectedValue, setSelectedValue] = useState('template')

  const handleChange = event => {
    setSelectedValue(event.target.value)
  }

  const handleButtonClick = () => {
    onSelected(selectedValue)
    // alert(`Selected: ${selectedValue}`);
  }

  return (
    <MDBox>
      <FormControl component="fieldset">
        <MDBox
          sx={{
            border: '1px solid rgb(191 219 254 / .5)',
            borderRadius: 2
          }}
          mt={1}
        >
          <RadioGroup
            aria-label="options"
            name="radio-buttons-group"
            value={selectedValue}
            onChange={handleChange}
          >
            <FormControlLabel
              sx={{ mx: 2, my: 1 }}
              value="template"
              control={
                <RadioOption
                  header="Choose a Proven Template"
                  label={
                    <MDTypography variant="body2">
                      Not sure where to start? Pick a proven template, give us a
                      bit of context, and let our AI tailor it just for your
                      business.
                    </MDTypography>
                  }
                />
              }
            >
              {' '}
            </FormControlLabel>

            {/* <FormControlLabel
              sx={{ mx: 2, my: 1 }}
              value="callRecording"
              disabled={true}
              control={
                <RadioOption
                  header="Upload a Call Recording"
                  label={
                    <MDTypography variant="body2">
                      Got a star call? Upload a recording and let us craft a
                      winning script framework from it. Your team can follow
                      these star moves easily!
                    </MDTypography>
                  }
                />
              }
            >
              {' '}
            </FormControlLabel>

            <FormControlLabel
              sx={{ mx: 2, my: 1 }}
              value="transcript"
              disabled={true}
              control={
                <RadioOption
                  header="Paste Your Script"
                  label={
                    <MDTypography variant="body2">
                      Have a script already? Paste it here and watch Cald
                      transform it into an easy-to-follow format for your
                      agents.
                    </MDTypography>
                  }
                />
              }
            >
              {' '}
            </FormControlLabel> */}
          </RadioGroup>
        </MDBox>
      </FormControl>
      <MDBox
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center'
        }}
        mt={1}
      >
        <MDButton
          variant="gradient"
          color="success"
          style={{ marginTop: '20px' }}
          onClick={handleButtonClick}
        >
          Next
        </MDButton>
      </MDBox>
    </MDBox>
  )
}

function RadioOption(props) {
  const { label, header, ...rest } = props
  return (
    <Grid container style={{ marginBottom: '1rem' }}>
      <Grid item xs={1}>
        <Radio {...rest} />
      </Grid>
      <Grid item xs={11}>
        <MDTypography variant="h6" component="div" sx={{ textAlign: 'left' }}>
          {header}
        </MDTypography>
        <div>{label}</div>
      </Grid>
    </Grid>
  )
}
