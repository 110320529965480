import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'

import MDBox from 'components/MDBox'

import CheckoutForm from './CheckoutForm'

const CreditCardModal = ({
  isOpen = false,
  onClose,
  clientSecret,
  stripePromise,
  title,
  updateCredit
}) => {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>
        Enter Your Card Information To Launch A Live Campaign
      </DialogTitle>
      <DialogContent>
        By adding call credit minutes to your account,{' '}
        <b>your agents will be able to call real, live contacts at scale</b>.
        You only pay when Cald is talking to you via a role play or to a contact
        via a live campaign.
        <MDBox pt={2}>
          {stripePromise && clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm
                title={title}
                onClose={onClose}
                updateCredit={updateCredit}
              />
            </Elements>
          )}
        </MDBox>
      </DialogContent>
    </Dialog>
  )
}

export default CreditCardModal
