import React from 'react'
import { MaterialReactTable } from 'material-react-table'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import { useMaterialUIController } from 'context'
import theme from 'assets/theme'
import { materialReactTableStyles } from './style'

const DataTable = ({
  table,
  canSearch,
  onNewItem,
  newItemLabel,
  initialState = { pagination: { pageSize: 100  }, density: 'compact' },
  totalCount = 0,
  onRowClick = () => {},
  enableTopToolbar = true,
  selectedRow = {},
  setRowSelection = () => {}
}) => {
  const { columns, rows } = table
  const [controller] = useMaterialUIController()
  const { darkMode } = controller

  // TODO: Find other way to style the dark mode
  const tableProps = {
    columns,
    data: rows,
    initialState,
    enableColumnFiltering: canSearch,
    enableGlobalFilter: canSearch,
    enablePagination: true,
    enableRowSelection: false,
    enableSorting: true,
    enableTopToolbar,
    positionToolbarAlertBanner: 'none',
    muiTableBodyRowProps: ({ row }) => ({
      onClick: e => {
        onRowClick(row)
      },
      selected: selectedRow[row.id],
      sx: {
        cursor: 'pointer',
        '&.Mui-selected': {
          backgroundColor: `${
            darkMode ? theme.palette.selected.dark : theme.palette.selected.main
          } !important`,
          td: {
            backgroundColor: 'inherit'
          }
        }
      }
    }),
    onRowSelectionChange: setRowSelection,
    ...materialReactTableStyles(theme, darkMode),
    state: { rowSelection: selectedRow }
  }

  if (totalCount) {
    tableProps.rowCount = totalCount
  }
  return (
    <MDBox>
      {onNewItem && (
        <MDBox sx={{ mb: 2 }}>
          <MDButton color="success" variant="contained" onClick={onNewItem}>
            {newItemLabel || 'Add New Item'}
          </MDButton>
        </MDBox>
      )}
      <MaterialReactTable {...tableProps} />
    </MDBox>
  )
}

export { DataTable }
