import HttpService from './http.service'

class UserService {
  updateUser = async (id, payload) => {
    return await HttpService.patch(`/api/users/${id}`, payload)
  }
  getUser = async id => {
    return await HttpService.get(`/api/users`)
  }
  getUsers = async () => {
    return await HttpService.get('/api/impersonation/users')
  }
}

export default new UserService()
