import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'

const TranscriptModal = ({ isOpen, onClose, transcriptData }) => {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle textAlign="center" sx={{ color: 'inherit' }}>
        Transcript
      </DialogTitle>
      <DialogContent>
        <MDBox p={2}>
          {transcriptData.map((item, index) => {
            return (
              <MDBox key={index}>
                <b>{item.role}</b>: {item.content}
              </MDBox>
            )
          })}
        </MDBox>
        <MDButton
          onClick={onClose}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: '1rem' }}
        >
          Close
        </MDButton>
      </DialogContent>
    </Dialog>
  )
}

export default TranscriptModal
