import { Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { TermsPage } from 'pages'
import Home from 'pages/home'
import Register from 'scenes/Register'

const PreAuth = () => {
  return (
    <Suspense>
      <Routes>
        <Route index element={<Home />} />
        <Route path="terms" element={<TermsPage />} />
        <Route path="signup" element={<Register />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  )
}

export default PreAuth
