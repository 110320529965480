import HttpService from './http.service'

class PhoneNumberService {
  searchPhoneNumbers = async ({ countryCode, type, prefix }) => {
    return await HttpService.get(
      `/api/phoneNumbers/searchPhoneNumbers${
        countryCode ? `?countryCode=${countryCode}` : ''
      }${type ? `&type=${type}` : ''}${prefix ? `&prefix=${prefix}` : ''}`
    )
  }
  getUserPhoneNumbers = async () => {
    return await HttpService.get(`/api/phoneNumbers/getUserPhoneNumbers`)
  }
  getAgentPhoneNumbers = async (agentId) => {
    return await HttpService.get(`/api/phoneNumbers/getAgentPhoneNumbers/${agentId}`)
  }
  buyPhoneNumber = async payload => {
    return await HttpService.post(`/api/phoneNumbers/buyPhoneNumber`, payload)
  }
  deletePhoneNumber = async ({ phoneNumberId }) => {
    return await HttpService.delete(`/api/phoneNumbers/${phoneNumberId}`)
  }
  editPhoneNumber = async ({ phoneNumberId, agentId }) => {
    return await HttpService.patch(`/api/phonenumbers/${phoneNumberId}`, {
      agentId: agentId
    })
  }
}

export default new PhoneNumberService()
