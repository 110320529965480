import CallsList from 'pageComponents/calls/List'
import MDBox from 'components/MDBox'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import campaignService from 'services/campaign-service'
import {
  formatUtcToReadableDate,
  capitalizeFirstLetter
} from 'utils/utilsFunctions'
import { FETCH_DATA_STATUS } from 'utils/constants'
import { FetchingError, FetchingLoader } from 'components/ErrorFallback'

import StatsSection from 'components/StatsSection'

const CampaignDetails = () => {
  const { id } = useParams()

  const { data, isLoading, status } = useQuery(['campaigns', id], () =>
    campaignService.getCampaignDetails(id)
  )

  if (isLoading) {
    return <FetchingLoader />
  }

  if (status === FETCH_DATA_STATUS.FAILURE) {
    return <FetchingError />
  }

  const campaignDetails = [
    {
      title: 'Status',
      value: data.campaign.status
        ? capitalizeFirstLetter(data.campaign.status) +
          (data.campaign.statusMessage
            ? ' (' + data.campaign.statusMessage + ')'
            : '')
        : ''
    },
    { title: 'Cost', value: data.campaign.price ? data.campaign.price : '' },
    { title: 'Agent Name', value: data.campaign.agent.agentName },
    { title: 'Group Name', value: data.campaign.list.listName },
    { title: 'Contacts', value: data.totalContacts },
    {
      title: 'Start Date',
      value: formatUtcToReadableDate(data.campaign.createdAt)
    }
  ]


  return (
    <MDBox sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: '20px' }}>
      <StatsSection title={'Campaign Details: ' + data.campaign.campaignName} items={campaignDetails} />
      <CallsList id={id} summary type='campaign'/>
    </MDBox>
  )
}



export default CampaignDetails
