import HttpService from './http.service'

class VoiceService {
  getUserVoices = async () => {
    return await HttpService.get(`/api/voices`)
  }
  cloneVoice = async payload => {
    return await HttpService.post(`/api/voices/clone`, payload)
  }
  deleteVoice = async ({ id }) => {
    return await HttpService.delete(`/api/voices/${id}`)
  }
}

export default new VoiceService()
