import HttpService from './http.service'

class ContactService {
  getContactsAndLists = async () => {
    return await HttpService.get('/api/contacts')
  }
  deleteContact = async id => {
    return await HttpService.delete(`/api/contacts/${id}`)
  }
  addContact = async payload => {
    return await HttpService.post('/api/contacts', payload)
  }
  // updateAgent = async (id, payload) => {
  //     return await HttpService.patch(`/agents/${id}`, payload);
  // };
}

export default new ContactService()
