import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'

function UpdateKnowledgeBaseModal({ open = '', formik, onClose, onSubmit }) {
  const handleSubmit = async () => {
    await formik.validateForm()
    if (formik.isValid) {
      onSubmit()
    } else {
      formik.setTouched({ name: true, content: true }) // Optionally, mark all fields as touched to show validation errors
    }
  }

  return (
    <MDModal
      open={open}
      onClose={onClose}
      sx={{
        padding: '1.5rem',
        borderRadius: '8px',
        borderColor: '#FAFAFA',
        width: '100%',
        maxWidth: '50rem',
        maxHeight: '85%',
        overflow: 'auto'
      }}
    >
      <MDBox component="form">
        <Box width="100%">
          <Stack
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box flex={1}>
              <Typography variant="h5" textAlign="center">
                Update Flash Card
              </Typography>
            </Box>
          </Stack>
          <Box p={2}>
            <Typography variant="body2">Question/Objection</Typography>
            <TextField
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              sx={{ width: '100%', marginTop: 2 }}
              helperText={
                formik.touched.name &&
                Boolean(formik.errors.name) &&
                formik.errors.name
              }
              placeholder="eg. What's the name of your company"
            />
          </Box>
          <Box p={2}>
            <Typography variant="body2">Answer/Response</Typography>
            <TextField
              name="content"
              variant="outlined"
              type="textarea"
              value={formik.values.content}
              onChange={formik.handleChange}
              sx={{ width: '100%', marginTop: 2 }}
              placeholder="eg. Tesla's Super-Computers"
              multiline
              rows={6}
              helperText={
                formik.touched.content &&
                Boolean(formik.errors.content) &&
                formik.errors.content
              }
            />
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
            p={3}
            pt={1}
          >
            <Button
              variant="contained"
              sx={{
                color: 'whitesmoke'
              }}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Box>
        </Box>
      </MDBox>
    </MDModal>
  )
}

export default UpdateKnowledgeBaseModal
