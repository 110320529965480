/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'

import { useMutation } from 'react-query'
import { changepassword } from 'services/authentication.service'
import { notifySuccess, notifyError } from 'components/Messages'
import { useAuth0 } from '@auth0/auth0-react'

function ChangePassword() {
  const { user } = useAuth0()

  const { mutate, isSuccess } = useMutation(changepassword, {
    onSuccess: res => {
      notifySuccess('Check your email for password reset.')
    },
    onError: () => {
      notifyError('Failed to send email')
    }
  })

  const onSubmitHandler = async e => {
    e.preventDefault()
    mutate({
      email: user.email
    })
  }

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3} onSubmit={onSubmitHandler}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Email"
              inputProps={{ type: 'email', autoComplete: '' }}
              value={user.email}
              disabled
              name="email"
            />
          </Grid>
        </Grid>
        {isSuccess && (
          <MDBox mt={3} mb={1}>
            <MDTypography variant="body2" color="text">
              Kindly check your email for instructions on resetting your
              password.
            </MDTypography>
          </MDBox>
        )}
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <MDBox ml="auto">
            <MDButton color="primary" size="small" type="submit">
              Send password reset email
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default ChangePassword
