import HttpService from './http.service'

class BillingService {
  getAvailableBalance = async () => {
    return await HttpService.post('/api/billing/balance')
  }
  getPayments = async () => {
    return await HttpService.post('/api/billing/payments')
  }
  getTotalSpent = async () => {
    return await HttpService.post('/api/billing/spent')
  }
  getTotalPaid = async () => {
    return await HttpService.post('/api/billing/paid')
  }
  getCallPricingDetails = async () => {
    return await HttpService.post('/api/billing/call-pricing-details')
  }
}

export default new BillingService()
