import UploadContactsWizard from './components/UploadContactsWizard'
import NoItemsView from 'components/NoItemsView'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import listService from 'services/list-service'
import GroupList from './components/GroupList'

function ContactGroupsPage() {
  const [showAddDialog, setShowAddDialog] = React.useState(false)
  const [showCreateList, setShowCreateList] = useState(false)
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(
    ['contactsAndLists'],
    listService.getLists
  )
  const lists = data?.lists

  const { mutate } = useMutation(values => listService.addList(values), {
    onSuccess: () => {
      queryClient.invalidateQueries(['contactsAndLists'])
      setShowCreateList(false)
    }
  })

  const formik = useFormik({
    initialValues: {
      listName: ''
    },
    onSubmit: async values => {
      mutate(values)
    }
  })

  return lists?.length ? (
    <GroupList
      initialData={lists}
      isLoading={isLoading}
      openAddDialog={showCreateList}
      onCloseAddDialog={() => setShowCreateList(false)}
      onOpenAddDialog={() => setShowCreateList(true)}
      formik={formik}
    />
  ) : (
    <>
      <NoItemsView
        cta="Upload Contacts"
        title="Your Contact List Awaits!"
        content="Looks like your agents are all ears and ready to chat! Let's get your contact list uploaded. You can easily add contacts using a CSV file. Need a hand with this? Just let us know!"
        onClick={() => {
          setShowAddDialog(true)
        }}
      />
      <UploadContactsWizard
        open={showAddDialog}
        data={lists}
        onClose={() => setShowAddDialog(false)}
      />
    </>
  )
}

export default ContactGroupsPage
