import { useState } from 'react'

import TabsLayout from 'components/Layout/TabsLayout'
import { TabPanel } from 'components/TabPanel'
import { Settings, ClientAPI } from './components'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'

const tabs = ['Cald API Keys', 'External Apps',]

const IntegrationsPage = () => {
  const [activeTab, setActiveTab] = useState(0)

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  return (
    <MDBox>
      <MDTypography variant="h3" gutterBottom>
        Integrations
      </MDTypography>
      <TabsLayout tabs={tabs} onChange={handleChange} activeTab={activeTab}>
        <TabPanel value={activeTab} index={0}>
          <ClientAPI />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Settings />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Settings />
        </TabPanel>
        {/* <TabPanel value={activeTab} index={1}>
        <Webhooks />
      </TabPanel> */}
      </TabsLayout>
    </MDBox>
  )
}

export default IntegrationsPage
