/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

// Images
import { signup, checkPromo } from 'services/authentication.service'
import { Alert, Button, Paper, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'

// Validators
import * as yup from 'yup'
import { notifySuccess } from 'components/Messages'
import { useMutation } from 'react-query'
import { notifyError } from 'components/Messages'
import { useAuth0 } from '@auth0/auth0-react'
import MDButton from 'components/MDButton'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input'
// import { sendOtp, resendOtp } from 'services/authentication.service'
import colors from 'assets/theme/base/colors'

const { grey, inputBorderColor, info } = colors

const phoneNumberInputStyles = {
  padding: '1rem',
  width: '100%',
  borderColor: inputBorderColor,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '6px',
  color: grey[700],
  outlineColor: info.main
}

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim('Please remove whitespaces')
    .strict(true)
    .min(2, 'The First Name field must be at least 2 characters')
    .required('First Name is required'),
  lastName: yup
    .string()
    .trim('Please remove whitespaces')
    .strict(true)
    .min(2, 'The Last Name field must be at least 2 characters')
    .required('Last Name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .trim('Please remove whitespaces')
    .strict(true)
    .min(7, 'Password must be at least 7 characters')
    .required('Password is required')
  // otp: yup.string().required('OTP is required')
})

function Register() {
  // const location = useLocation()
  // const queryParams = new URLSearchParams(location.search)
  // const affiliateCode = queryParams.get('via')
  const [isErrorState, setIsErrorState] = useState(false)
  const { loginWithRedirect } = useAuth0()
  const [promoDescription, setPromoDescription] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState()
  const code = sessionStorage.getItem('promoCode')
  // const [verifyNumberState, setVerifyNumberState] = useState({
  //   sessionId: '',
  //   isOtpGenerated: false,
  //   isLoading: 'idle'
  // })

  const affiliateCode = sessionStorage.getItem('affiliateCode')
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      promoCode: ''
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      setIsErrorState(false)
      try {
        const { email, firstName, lastName, password, promoCode } = values
        if (phoneNumber) {
          if (!isValidPhoneNumber(phoneNumber)) {
            notifyError('Invalid Phone Number')
            return
          }
        }
        mutate({
          email,
          firstName,
          lastName,
          password,
          promoCode,
          phoneNumber,
          // otp,
          referralCode: affiliateCode ?? ''
          // sessionId: verifyNumberState.sessionId
        })
      } catch (err) {
        setIsErrorState(true)
      }
    }
  })
  const { mutate, isLoading } = useMutation(signup, {
    onSuccess: res => {
      notifySuccess('Registered Successfully. Please Login')
      let email = res.email
      window.fpr('referral', { email: email })
      setTimeout(() => {
        navigate('/', { replace: true })
        sessionStorage.removeItem('affiliateCode')
      }, 500)
    },
    onError: error => {
      notifyError('Something Went Wrong. Please try again later')
      setIsErrorState(true)
    }
  })

  const { mutate: checkPromoMutation, isLoading: isPromoLoading } = useMutation(
    checkPromo,
    {
      onSuccess: data => {
        const message = data.data.message
        setPromoDescription(message)
      },
      onError: () => {
        notifyError('Invalid Promo Code')
      }
    }
  )

  useEffect(() => {
    if (code) {
      formik.setFieldValue('promoCode', code)
      sessionStorage.removeItem('promoCode')
      checkPromoMutation({ promoCode: code })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const handleVerifyPhoneNumber = async () => {
  //   const isValidNumber = isValidPhoneNumber(phoneNumber)
  //   if (!isValidNumber) {
  //     notifyError('Phone number is not valid')
  //     return
  //   }
  //   setVerifyNumberState(prev => ({
  //     ...prev,
  //     isLoading: 'pending'
  //   }))
  //   try {
  //     const response = await sendOtp(phoneNumber)
  //     const sessionId = response.data.session_id
  //     setVerifyNumberState(prev => ({
  //       ...prev,
  //       isOtpGenerated: true,
  //       sessionId,
  //       isLoading: 'resolved'
  //     }))
  //     notifySuccess('OTP has been sent to your number')
  //   } catch (error) {
  //     console.log(error)
  //     setVerifyNumberState(prev => ({
  //       ...prev,
  //       isLoading: 'rejected'
  //     }))
  //   }
  // }

  // const handleResendCode = async () => {
  //   setVerifyNumberState(prev => ({
  //     ...prev,
  //     isLoading: 'pending'
  //   }))
  //   try {
  //     await resendOtp(verifyNumberState.sessionId)
  //     setVerifyNumberState(prev => ({
  //       ...prev,
  //       isOtpGenerated: true,
  //       isLoading: 'resolved'
  //     }))
  //     notifySuccess('OTP has been sent to your number')
  //   } catch (error) {
  //     console.log(error)
  //     setVerifyNumberState(prev => ({
  //       ...prev,
  //       isLoading: 'rejected'
  //     }))
  //   }
  // }

  // const { isLoading: otpLoader, isOtpGenerated } = verifyNumberState

  return (
    <Paper
      elevation={3}
      style={{
        padding: '2rem',
        minWidth: '500px',
        width: '550px',
        margin: 'auto',
        marginTop: 40
      }}
    >
      <MDBox
        style={{
          marginBottom: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItem: 'center',
          width: '100%'
        }}
      >
        <MDBox sx={{ textAlign: ' center' }}>
          <img
            src="https://cald.ai/wp-content/uploads/2023/11/cald-logo.png"
            alt="Cald Logo"
            style={{
              width: 'auto',
              height: '58px',
              display: 'inline-block',
              margin: '0 0 11px'
            }}
          />
        </MDBox>
        <Typography
          variant="h5"
          gutterBottom
          fontWeight={400}
          fontSize={'24px'}
          textAlign={'center'}
        >
          Welcome to Cald
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          fontWeight={400}
          fontSize={'14px'}
          textAlign={'center'}
          mt={1}
        >
          Sign Up to continue.
        </Typography>

        {/* <Button
          variant="outlined"
          startIcon={<GoogleIcon />}
          style={{
            marginBottom: '4px',
            fontSize: '16px',
            color: '#2d333a',
            fontWeight: 400,
            marginTop: '15px',
            border: '1px solid #d9c5c5'
          }}
        >
          Continue with Google
        </Button>
        <div
          style={{
            width: '100%',
            height: '20px',
            borderBottom: '1px solid #d9c5c5',
            textAlign: 'center'
          }}
        >
          <span
            style={{
              backgroundColor: ' #F3F5F6',
              paddingLeft: '10px',
              paddingRight: '10px',
              fontSize: '12px'
            }}
          >
            Or
          </span>
        </div> */}
      </MDBox>

      <MDBox
        sx={{
          width: '80%',
          margin: '0 0 5rem 1.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItem: 'center'
        }}
      >
        <form>
          <TextField
            label="Email address"
            variant="outlined"
            fullWidth
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            margin="normal"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            name="password"
            label="Password"
            variant="outlined"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            fullWidth
            margin="normal"
            type="password"
          />
          <TextField
            name="firstName"
            label="First Name"
            variant="outlined"
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            fullWidth
            value={formik.values.firstName}
            onChange={formik.handleChange}
            margin="normal"
            type="text"
          />
          <TextField
            name="lastName"
            label="Last Name"
            variant="outlined"
            fullWidth
            value={formik.values.lastName}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            onChange={formik.handleChange}
            margin="normal"
            type="text"
          />
          <MDBox mt={1}>
            <PhoneInput
              name="phoneNumber"
              value={phoneNumber}
              style={{
                ...phoneNumberInputStyles
              }}
              placeholder="Enter Phone Number"
              onChange={setPhoneNumber}
            />
          </MDBox>
          <MDBox>
            <TextField
              name="promoCode"
              label="Promo Code"
              variant="outlined"
              fullWidth
              disabled={promoDescription}
              value={formik.values.promoCode}
              error={
                formik.touched.promoCode && Boolean(formik.errors.promoCode)
              }
              helperText={formik.touched.promoCode && formik.errors.promoCode}
              onChange={formik.handleChange}
              margin="normal"
              type="text"
            />
            {promoDescription ? (
              <Alert
                severity={'success'}
                onClose={() => {
                  setPromoDescription(null)
                }}
              >
                Congratulations! Your Promo Code "<b>{promoDescription}</b>" has
                been successfully applied.
              </Alert>
            ) : (
              <MDButton
                color="primary"
                isLoading={isPromoLoading}
                onClick={() =>
                  checkPromoMutation({ promoCode: formik.values.promoCode })
                }
              >
                Apply
              </MDButton>
            )}
          </MDBox>
          {/* <MDBox pt={2}>
            <MDButton
              variant="contained"
              color="warning"
              isLoading={otpLoader === 'pending'}
              disabled={!phoneNumber?.length}
              onClick={() => {
                if (isOtpGenerated) {
                  handleResendCode()
                } else {
                  handleVerifyPhoneNumber()
                }
              }}
              mt={2}
            >
              {isOtpGenerated ? 'Resend Code' : 'Send Code'}
            </MDButton>
          </MDBox> */}

          {/* {isOtpGenerated && (
            <TextField
              name="otp"
              label="OTP"
              variant="outlined"
              fullWidth
              value={formik.values.otp}
              onChange={formik.handleChange}
              margin="normal"
              type="text"
              error={formik.touched.otp && Boolean(formik.errors.otp)}
              helperText={formik.touched.otp && formik.errors.otp}
            />
          )} */}
          <MDButton
            variant="contained"
            color="info"
            fullWidth
            onClick={formik.handleSubmit}
            // disabled={!isOtpGenerated}
            isLoading={isLoading}
            style={{ marginTop: '1rem', color: '#fff' }}
          >
            {/* {isOtpGenerated ? 'Verify & Proceed' : 'Continue'} */}
            Continue
          </MDButton>
          <Typography
            variant="body2"
            style={{ marginTop: '1rem', textAlign: 'center' }}
          >
            Already have an account?
            <span style={{ color: '#3498db', fontWeight: 600 }}>
              <Button onClick={() => loginWithRedirect()}> Log in</Button>
            </span>
          </Typography>
        </form>
      </MDBox>
    </Paper>
  )
}

export default Register
