/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// Material Dashboard 2 PRO React components
import MDInput from 'components/MDInput'
import MDBox from 'components/MDBox'
import { FormLabel } from '@mui/material'

function FormField({ label, ...rest }) {
  return (
    <MDBox>
      <FormLabel
        sx={{
          display: 'block',
          fontWeight: '500',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          color: 'rgb(17 24 39 / 1)'
        }}
      >
        {label}
      </FormLabel>
      <MDInput
        variant="standard"
        fullWidth
        InputLabelProps={{ shrink: true }}
        {...rest}
      />
    </MDBox>
  )
}

// Setting default values for the props of FormField
FormField.defaultProps = {
  label: ' '
}

// Typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string
}

export default FormField
