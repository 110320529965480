import { Stack, Typography } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import React, { useState } from 'react'
import CreateKnowledgeBaseModal from '../CreateKnowledgeBaseModal'

const KnowledgebasePage = () => {
  const [createKnowledgeDialog, setCreateKnowledgeDialog] = useState(false)

  return (
    <MDBox>
      <Stack gap={4}>
        <Typography
          variant="h4"
          component="h1"
          sx={{ pl: { sm: 4 }, textAlign: 'center' }}
        >
          Create Your First Knowledge Base
        </Typography>
        <MDBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 5
          }}
        >
          <MDTypography sx={{ textAlign: 'center' }}>
            Create a knowledge base to give your agents important context and
            information about your company. Once your agent is connected to a
            knowledge base, it will have the ability to handle literally
            thousands of FAQs, overcome any objection and more... with perfect
            recall and infinite memory.
          </MDTypography>
          <CreateKnowledgeBaseModal
            open={createKnowledgeDialog}
            onClose={() => setCreateKnowledgeDialog(false)}
          />
          <MDButton
            color="success"
            onClick={() => setCreateKnowledgeDialog(true)}
          >
            New Knowledge Base
          </MDButton>
        </MDBox>
      </Stack>
    </MDBox>
  )
}

export default KnowledgebasePage
