import Axios from 'axios'
import Cookies from 'js-cookie'

const API_URL = process.env.REACT_APP_API_URL
Axios.defaults.baseURL = API_URL

export class HttpService {
  _axios = Axios.create()

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected)
  }

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected)
  }

  get = async url => await this.request(this.getOptionsConfig('get', url))

  post = async (url, data) =>
    await this.request(this.getOptionsConfig('post', url, data))

  put = async (url, data) =>
    await this.request(this.getOptionsConfig('put', url, data))

  patch = async (url, data) =>
    await this.request(this.getOptionsConfig('patch', url, data))

  delete = async url => await this.request(this.getOptionsConfig('delete', url))

  getOptionsConfig = (method, url, data) => {
    const options = {
      method,
      url,
      data,
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const overrideEmail = Cookies.get('overrideEmail')
    if (overrideEmail) {
      options.headers.overrideEmail = overrideEmail
    }

    return options
  }

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then(res => resolve(res.data))
        .catch(ex => {
          // Check if the response exists before trying to access its data
          if (ex.response && ex.response.data) {
            reject(ex.response.data)
          } else {
            // Handle cases where the error does not have a response
            // This could be a network error, or something else that prevented a response
            reject({
              message: 'Network error or no response received',
              originalError: ex
            })
          }
        })
    })
  }
}

export default new HttpService()
