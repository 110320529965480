import HttpService from './http.service'

export const setupAxiosInterceptors = (
  getAccessTokenSilently,
  onUnauthenticated
) => {
  const onRequestSuccess = async config => {
    const token = await getAccessTokenSilently()
    config.headers.Authorization = `Bearer ${token}`
    return config
  }
  const onRequestFail = error => Promise.reject(error)

  HttpService.addRequestInterceptor(onRequestSuccess, onRequestFail)

  const onResponseSuccess = response => response

  const onResponseFail = error => {
    const status = error.status || error.response.status
    if (status === 403 || status === 401) {
      // onUnauthenticated()
      console.log(error)
    }

    return Promise.reject(error)
  }
  HttpService.addResponseInterceptor(onResponseSuccess, onResponseFail)
}
