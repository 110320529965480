/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

import {
  Sidenav,
  AffiliateInfo,
  BillingInfo,
  ChangePassword,
  BasicInfo
} from './components'
import AppInfo from './components/AppInfo'

function SettingsPage() {
  return (
    <MDBox mt={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <Sidenav />
        </Grid>
        <Grid item xs={12} lg={9}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BasicInfo />
              </Grid>
              {/* <Grid item xs={12}>
                <AppInfo />
              </Grid> */}
              <Grid item xs={12}>
                <AffiliateInfo />
              </Grid>
              <Grid item xs={12}>
                <BillingInfo />
              </Grid>
              <Grid item xs={12}>
                <ChangePassword />
              </Grid>
              <Grid item xs={12}>
                {/* <DeleteAccount /> */}
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default SettingsPage
