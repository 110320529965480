/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import colors from 'assets/theme-dark/base/colors'
import boxShadows from 'assets/theme-dark/base/boxShadows'
import borders from 'assets/theme-dark/base/borders'

const { background } = colors
const { md } = boxShadows
const { borderRadius } = borders

const tableContainer = {
  styleOverrides: {
    root: {
      backgroundColor: background.card,
      boxShadow: md,
      borderRadius: borderRadius.xl
    }
  }
}

export default tableContainer
