/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Ability } from '@casl/ability'
import App from 'App'

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider, AuthContextProvider } from 'context'
import { AbilityContext } from 'Can'
import 'react-phone-number-input/style.css'
import 'draft-js/dist/Draft.css'
import 'react-toastify/dist/ReactToastify.css'
import './spinners.scss'
import './style.scss'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/ErrorFallback'
import Auth0WithNavigate from './auth/Auth0WithNavigate'

const container = document.getElementById('root')
const root = createRoot(container)
const ability = new Ability()
const queryClient = new QueryClient()

const isDevelopment = process.env.NODE_ENV === 'development'

const DevelopmentErrorFallback = ({ error }) => (
  <div>
    <h2>An error occurred:</h2>
    <pre>{error.message}</pre>
  </div>
)

root.render(
  <AbilityContext.Provider value={ability}>
    <BrowserRouter>
      <AuthContextProvider>
        <Auth0WithNavigate>
          <QueryClientProvider client={queryClient}>
            <ErrorBoundary
              FallbackComponent={
                isDevelopment ? DevelopmentErrorFallback : ErrorFallback
              }
            >
              <MaterialUIControllerProvider>
                <App ability={ability} />
              </MaterialUIControllerProvider>
            </ErrorBoundary>
          </QueryClientProvider>
        </Auth0WithNavigate>
      </AuthContextProvider>
    </BrowserRouter>
  </AbilityContext.Provider>
)
