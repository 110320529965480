import HttpService from './http.service'

class KnowledgeBaseService {
  getKnowledgeBases = async () => {
    return await HttpService.get('/api/knowledge-base')
  }
  addKnowledgeBase = async payload => {
    return await HttpService.post(`/api/knowledge-base`, payload)
  }
  deleteKnowledgeBase = async id => {
    return await HttpService.delete(`/api/knowledge-base/${id}`)
  }
  deleteKnowledgeBaseItem = async (id, itemId) => {
    return await HttpService.delete(`/api/knowledge-base/${id}/item/${itemId}`)
  }
  getKnowledgeBase = async id => {
    return await HttpService.get(`/api/knowledge-base/${id}`)
  }
  updateKnowledgeBase = async (id, payload) => {
    return await HttpService.patch(`/api/knowledge-base/${id}`, payload)
  }
  updateKnowledgeBaseItem = async (id, payload) => {
    try {
      let response

      if (payload.type === 'file' && payload.itemId === undefined) {
        // only for new file uploads (not updates)
        response = await HttpService.patch(
          `${process.env.REACT_APP_PROXY_URL}/knowledge-base/${id}/upload`,
          {
            base64: payload.content,
            name: payload.name
          }
        )
      } else {
        response = await HttpService.patch(
          `/api/knowledge-base/${id}/item`,
          payload
        )
      }

      // Check if the response status code is 400
      if (response.status === 400) {
        throw new Error('Bad Request (400)')
      }

      return response
    } catch (error) {
      console.error(error)

      throw error // Throw the error so that it can be caught by onError in React Query
    }
  }
}

export default new KnowledgeBaseService()
