import React from 'react'


import KnowledgeTable from './components/KnowledgeTable'
import CreateKnowledgeBase from './components/CreateKnowledgeBase'

import { useKnowledgeBases } from 'utils/customHooks'


function KnowledgeBaseList() {

  const { data, isKnowledgeBaseLoading } = useKnowledgeBases()

  return (
    <div>
      {!isKnowledgeBaseLoading && data?.length ? (
        <KnowledgeTable data={data} />
      ) : (
        <CreateKnowledgeBase />
      )}
    </div>
  )
}

export default KnowledgeBaseList
