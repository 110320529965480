/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Settings page components
import { FormField } from 'pages/account/components'

// Hooks
import { useUser } from 'context'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import MDButton from 'components/MDButton'
import { useMutation } from 'react-query'
import userService from 'services/user-service'
import { notifySuccess } from 'components/Messages'
import { notifyError } from 'components/Messages'

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim('Please remove whitespaces')
    .strict(true)
    .min(2, 'The First Name field must be at least 2 characters')
    .required('First Name is required'),
  lastName: yup
    .string()
    .trim('Please remove whitespaces')
    .strict(true)
    .min(2, 'The Last Name field must be at least 2 characters')
    .required('Last Name is required')
})

function BasicInfo() {
  const [editMode, setEditMode] = useState(false)
  const { authState, setAuthInfo } = useUser()
  const { mutate, isLoading } = useMutation(
    data => userService.updateUser(authState?.userInfo?.id, data),
    {
      onSuccess: data => {
        localStorage.setItem('userInfo', JSON.stringify(data?.userInfo))
        setEditMode(false)
        notifySuccess('User Updated Successfully')
      },
      onError: () => {
        notifyError('Something Went Wrong')
      }
    }
  )
  const formik = useFormik({
    initialValues: {
      firstName: authState?.userInfo?.firstName,
      lastName: authState?.userInfo?.lastName
    },
    validationSchema,
    onSubmit: async values => {
      mutate(values)
    }
  })

  const handleCancelChanges = () => {
    formik.resetForm()
    setEditMode(false)
  }
  return (
    <Card id="basic-info" sx={{ overflow: 'visible' }}>
      <MDBox p={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <MDTypography variant="h5">User Profile</MDTypography>
        {!editMode && (
          <EditIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => setEditMode(true)}
          />
        )}
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              name="firstName"
              label="First Name"
              placeholder="Alec"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              name="lastName"
              label="Last Name"
              placeholder="Thompson"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              label="Email"
              placeholder="example@email.com"
              inputProps={{ type: 'email' }}
              value={authState?.userInfo?.email}
              disabled
            />
          </Grid>
          {editMode && (
            <Grid item xs={12}>
              <MDBox sx={{ display: 'flex', gap: 2 }}>
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={formik.handleSubmit}
                  isLoading={isLoading}
                >
                  Update Info
                </MDButton>
                <MDButton
                  variant="contained"
                  color="secondary"
                  onClick={handleCancelChanges}
                >
                  Cancel
                </MDButton>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </Card>
  )
}

export default BasicInfo
