import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import MDBox from 'components/MDBox'
import { useMaterialUIController } from 'context'

// TODO: CONVERT THIS INTO COMPOUND COMPONENT FOR BETTER REUSABILITY
const AlertDialog = ({
  title,
  content,
  maxWidth,
  children,
  sx,
  open,
  onClose,
  contentMinWidth
}) => {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller
  const dialogStyle = {
    backgroundColor: darkMode ? '#0F141F' : '#FFF',
    color: darkMode ? '#FFF' : '#0F141F'
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth
      sx={{ ...sx }}
    >
      {title && (
        <DialogTitle sx={{ ...dialogStyle }}>
          <MDBox sx={{ textAlign: 'center', ...dialogStyle }}>{title}</MDBox>
        </DialogTitle>
      )}
      <DialogContent sx={{ ...dialogStyle }}>
        <MDBox
          p={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: contentMinWidth,
            ...dialogStyle
          }}
        >
          {content}
        </MDBox>
      </DialogContent>
      {children && (
        <DialogActions sx={{ ...dialogStyle }}>{children}</DialogActions>
      )}
    </Dialog>
  )
}

export default AlertDialog
