import React from 'react'
import { Card, CardContent, Grid, IconButton, TextField } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDTooltip from 'components/MDTooltip'
import MDSelect from 'components/MDSelect'
import phoneNumberService from 'services/phoneNumber-service'
import MDModal from 'components/MDModal'

function AddPhoneNumber({ onClose }) {
  const [country, setCountry] = React.useState('')
  const [numberType, setNumberType] = React.useState('')
  const [prefix, setPrefix] = React.useState('')
  const [availableNumbers, setAvailableNumbers] = React.useState([])
  const [purchasedNumbers, setPurchasedNumbers] = React.useState([])
  const [showAddNumberDialog, setShowAddNumberDialog] = React.useState(false)

  const countryOptions = [{ value: 'US', label: 'USA' }]
  const numberTypeOptions = [
    // { value: 'mobile', label: 'Mobile' },
    { value: 'local', label: 'Local' }
  ]
  const handleSearchClick = async () => {
    console.log('search', country, numberType, prefix)
    try {
      const result = await phoneNumberService.searchPhoneNumbers({
        countryCode: country,
        type: numberType,
        prefix
      })
      setAvailableNumbers(result.numbers)
      console.log(availableNumbers)
      console.log(result)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCountryChange = event => {
    setCountry(event.target.value)
  }

  const handleNumberTypeChange = event => {
    setNumberType(event.target.value)
  }

  const handlePrefixChange = event => {
    setPrefix(event.target.value)
  }
  const handleBuyClick = async phoneNumber => {
    try {
      const result = await phoneNumberService.buyPhoneNumber(phoneNumber)
      console.log(result)
      setPurchasedNumbers([...purchasedNumbers, result.phoneNumber])
      setAvailableNumbers(
        availableNumbers?.map(item =>
          item.phoneNumber === result.phoneNumber
            ? { ...item, bought: true }
            : item
        )
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <MDBox
      sx={{
        paddingBottom: '1rem',
        borderRadius: '8px',
        borderColor: '#FAFAFA',
        width: '100%',
        overflow: 'auto'
      }}
    >
      {' '}
      <MDButton color="success" onClick={() => setShowAddNumberDialog(true)}>
        Add New Phone Number
      </MDButton>
      <MDModal
        open={showAddNumberDialog}
        title="Add New Phone Number"
        onClose={() => {
          onClose()
          setShowAddNumberDialog(false)
        }}
      >
        <MDBox sx={{ minWidth: '600px', margin: '3rem' }}>
          <MDTypography variant="h6" component="div">
            Get A New Phone Number
          </MDTypography>
          <br />
          <MDTypography variant="body2" component="div">
            You can get a new phone number for your agent. You can use this
            phone number to make and receive calls (and SMS messages where
            available). Phone numbers are charged at a monthly rate, and you can
            cancel at any time, but keep in mind that you will be charged until
            the end of the billing cycle.
          </MDTypography>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <MDSelect
                label="Country"
                value={country}
                name="country"
                options={countryOptions}
                onChange={handleCountryChange}
              ></MDSelect>
            </Grid>
            <Grid item xs={4}>
              <TextField
                sx={{ marginTop: '1rem' }}
                fullWidth
                label="Number Prefix"
                placeholder="+1650"
                value={prefix}
                onChange={handlePrefixChange}
              />
            </Grid>
            <Grid item xs={4}>
              <MDButton
                sx={{ marginTop: '1rem' }}
                color="success"
                onClick={handleSearchClick}
              >
                Search
              </MDButton>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ marginTop: '1rem' }}>
            {availableNumbers?.length > 0 &&
              availableNumbers?.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <MDTypography variant="h6">
                        {item.friendlyName}
                      </MDTypography>
                      <MDTypography variant="body2">
                        Phone Number: {item.phoneNumber}
                      </MDTypography>
                      <MDTypography variant="body2">
                        Capabilities: Voice -{' '}
                        {item.capabilities.voice ? 'Yes' : 'No'}, SMS -{' '}
                        {item.capabilities.SMS ? 'Yes' : 'No'}, MMS -{' '}
                        {item.capabilities.MMS ? 'Yes' : 'No'}
                      </MDTypography>
                      <MDTypography variant="body2">
                        Postal Code: {item.postalCode}
                      </MDTypography>
                      <MDTypography variant="body2">
                        Address Requirements: {item.addressRequirements}
                      </MDTypography>
                      <MDTypography variant="body2">
                        Region: {item.region}
                      </MDTypography>
                      <MDTypography variant="body2">
                        Price: {item.price} {item.priceUnit}/month{' '}
                        <MDButton
                          size="small"
                          color="success"
                          variant="outlined"
                          disabled={item.bought}
                          onClick={() => handleBuyClick(item)}
                        >
                          {item.bought ? 'Bought!' : 'Buy'}
                        </MDButton>
                      </MDTypography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </MDBox>
      </MDModal>
    </MDBox>
  )
}

export default AddPhoneNumber
