import React from 'react'
import MDTypography from 'components/MDTypography'

function TermsPage() {
  return (
    <div style={{ maxWidth: '800px', margin: '0 auto' }}>
      <MDTypography
        variant="h3"
        style={{ marginTop: '3rem', marginBottom: '2rem' }}
      >
        Let's go over the terms of use
      </MDTypography>

      <MDTypography
        variant="body2"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        These Terms of Service are between you (the “Subscriber”) and Cald AI,
        LLC (“Cald AI”) for our platform services like programs, features,
        account tools, and support (“Services”). Both paying and non-paying
        users are “Subscribers”. If you disagree, you can’t access our Services.
        We may change these terms by posting updates on our site. By continuing
        to use our Services, you accept any changes.
      </MDTypography>

      <MDTypography
        variant="body2"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        <strong>
          Cald AI can monitor but isn't responsible for uploaded info, databases
          or audio.
        </strong>{' '}
        In addition to these Terms, you’ll comply with applicable laws and
        requirements below.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        What we need from you
      </MDTypography>

      <MDTypography variant="body2">
        Follow telemarketing, dialing, and other communication laws. Give
        accurate Caller ID info. Make required disclosures. You're solely
        responsible for legal compliance.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Getting consent
      </MDTypography>

      <MDTypography variant="body2">
        Get consent as required by law and note consent levels. Without it,
        don't communicate. Keep consent records and share if we request.{' '}
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Using your data
      </MDTypography>

      <MDTypography variant="body2">
        You grant us a royalty-free license to use Subscriber Data to fulfill
        our obligations.
      </MDTypography>

      <MDTypography variant="body2">
        Usage Data is solely ours, not Subscriber Data. We can use it without
        limits.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Keeping it confidential{' '}
      </MDTypography>

      <MDTypography variant="body2">
        We'll both protect confidential information like our own, disclosing
        only on a need-to-know basis to fulfill obligations here. No
        unauthorized copying, removing, or improper use.
      </MDTypography>

      <MDTypography variant="body2">
        These obligations don’t apply to:
      </MDTypography>

      <ul>
        <li>
          <MDTypography variant="body2">
            Pre-existing information without obligation of confidence
          </MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            Independently developed information
          </MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            Third party information without obligation of confidence
          </MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            Publicly available information
          </MDTypography>
        </li>
      </ul>

      <MDTypography variant="body2">
        Disclose Confidential Information only when legally required after
        reasonable notice so the other party can seek protections, unless
        prohibited by law.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Compensating losses
      </MDTypography>

      <MDTypography variant="body2">
        You’ll defend and indemnify the Cald AI Indemnitees against claims,
        investigations, demands, actions, proceedings, losses, judgments,
        awards, settlements, damages, fines, injuries, penalties, and costs
        (including attorneys’ fees) arising from:
      </MDTypography>

      <ul>
        <li>
          <MDTypography variant="body2">Breach of this Agreement</MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            Your negligence or misconduct
          </MDTypography>
        </li>

        <li>
          <MDTypography variant="body2">
            Your Service use or obtained information
          </MDTypography>
        </li>
      </ul>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        No guarantees
      </MDTypography>

      <MDTypography variant="body2">
        USE SERVICE AT YOUR OWN RISK. ALL MATERIALS, INFORMATION, CONTENT,
        PRODUCTS, SOFTWARE, AND SERVICES ARE PROVIDED "AS IS," WITH NO
        WARRANTIES OR GUARANTEES. WE EXPRESSLY DISCLAIM ALL EXPRESS AND IMPLIED
        WARRANTIES INCLUDING OF MERCHANTABILITY, FITNESS FOR PURPOSE, AND
        NON-INFRINGEMENT.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Limiting liabilities
      </MDTypography>

      <MDTypography variant="body2">
        UNDER NO THEORY OF LIABILITY, INCLUDING CONTRACT, STRICT LIABILITY OR
        TORT, WILL Cald AI BE LIABLE FOR INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL
        OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS OR DAMAGES FROM SERVICE
        USE, EVEN IF ADVISED OF THE POSSIBILITY.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Following the law
      </MDTypography>

      <MDTypography variant="body2">
        Use Services according to applicable laws like TCPA. You acknowledge
        call recording. You're solely responsible for compliance and liability.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Third parties
      </MDTypography>

      <MDTypography variant="body2">
        Our Service may interact with unaffiliated third party services. Use
        them at your own risk per their terms. We aren't responsible for their
        quality, fees or damages from use. You grant us an irrevocable license
        to use any third party content connected to us or our Service.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        We're partners
      </MDTypography>

      <MDTypography variant="body2">
        We're independent contractors, not employees, agents, partners or joint
        ventures.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Settling disputes
      </MDTypography>

      <MDTypography variant="body2">
        Arbitrate disputes per American Arbitration Association rules in
        Maricopa County, AZ. Small claims actions are permitted. No punitive,
        special, consequential or indirect damages. Each party pays its own
        attorneys’ fees and costs regardless of outcome. No class or
        representative arbitration is permitted. BY AGREEING TO THIS AGREEMENT
        AND USING THE SERVICES, YOU IRREVOCABLY WAIVE ANY RIGHT TO JOIN CLAIMS
        WITH OTHERS OR MAKE CLAIMS IN COURT OR BEFORE A JURY.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Arizona law governs
      </MDTypography>

      <MDTypography variant="body2">
        Arizona law governs without regard to conflicts of law principles.
        Lawsuits must be in Maricopa County, AZ courts.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Keeping your data secure
      </MDTypography>

      <MDTypography variant="body2">
        Comply with applicable U.S. and other export laws. You can’t use
        Services for EU, EEA, UK, Swiss or non-US persons' data.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Severability
      </MDTypography>

      <MDTypography variant="body2">
        If any provision is invalid, the rest remain effective. Terms like
        warranties limitations and indemnification survive termination.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        No implied waivers
      </MDTypography>

      <MDTypography variant="body2">
        Waivers must be in writing signed by the waiving party. Consent to one
        breach doesn’t waive subsequent breaches.
      </MDTypography>

      <MDTypography
        variant="h6"
        style={{ marginTop: '2rem', marginBottom: '2rem' }}
      >
        Miscellaneous
      </MDTypography>

      <MDTypography variant="body2">
        Each party can legally enter this Agreement and bind the other party.
        Except for your fee payments, force majeure events suspend obligations.
        You can't assign rights/obligations without our consent. We may assign
        ours without notice. Ambiguities won't be construed against us.
      </MDTypography>
    </div>
  )
}

export default TermsPage
