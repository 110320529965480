import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon, IconButton, Tooltip } from '@mui/material'
import {
  Bolt,
  CalendarViewMonth,
  Description,
  Psychology,
  Feedback
} from '@mui/icons-material'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import { useFormik } from 'formik'
import * as yup from 'yup'

import Instructions from './components/instructions.js'
import ScriptWizard from './components/ScriptWizard/index.js'
import agentService from 'services/agent-service'
import { notifyError } from 'components/Messages/index.js'
import { notifySuccess } from 'components/Messages/index.js'
import AlertDialog from 'components/AlertDialog/index.js'
import MDModal from 'components/MDModal/index.js'
import Modal from 'components/Modal/index.js'
import Loader from 'components/Loader'
import { EMAIL_FIELDS } from '../../index.js'

const stripHtml = html => {
  const regex = /(<([^>]+)>)/gi
  return html.replace(regex, '')
}

const imagePath = '/images/cal-integrations-instructions.png'

const calendarIntegrationsContent = () => {
  return <img src={imagePath} width="100%" alt="Calendar Instructions" />
}

const SideBarAction = ({ icon, label, tooltip, onClick }) => (
  <MDBox my={1} display="flex" alignItems="center">
    <Tooltip title={tooltip}>
      <IconButton onClick={onClick}>{icon}</IconButton>
    </Tooltip>
    <MDTypography
      variant="body2"
      component="div"
      role="button"
      onClick={onClick}
      tabIndex={0}
      sx={{
        fontWeight: 500,
        cursor: 'pointer',
        marginLeft: 1
      }}
    >
      {label}
    </MDTypography>
  </MDBox>
)

const SideBar = props => {
  const { agentId, isDirty, formik, isLoading } = props
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogType, setDialogType] = useState(null)
  const [testCallLoader, setTestCallLoader] = useState('idle')

  const location = useLocation()
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const useAI = searchParams.get('useAI')

    if (useAI !== null) {
      showDialog('generateScript')
    }
  }, [location.search])

  const showDialog = dialog => {
    setDialogType(dialog)
    setOpenDialog(true)
  }
  const getScriptGenerationWizard = () => {
    return <ScriptWizard onScriptReady={handleScriptGenerated} />
  }
  const navigate = useNavigate()
  const handleCallRecording = () => {
    navigate(`/agents/${agentId}/tests`)
  }

  const handleScriptGenerated = async script => {
    const template = await buildFinalScript(script.scriptTemplate)
    formik.setFieldValue('scriptTemplate', template)
    formik.setFieldValue('greeting', script.greeting)
    
    setOpenDialog(false)
  }
  const buildFinalScript = async script => {
    const { ScriptHeader, ScriptFooter } =
      await agentService.getScriptTemplatesInfo()


    script = stripHtml(script)
    if (formik.values.editorMode === 'standard') {
      return script
    } else {
      script = ScriptHeader + script + ScriptFooter
    }
    return script
  }
  const callForm = useFormik({
    initialValues: {
      phone: '',
      variables: {}
    },
    validationSchema: yup.object({
      phone: yup.string().required('Required')
    }),
    onSubmit: async values => {
      values.agentId = agentId
      values['agentId'] = agentId
      setTestCallLoader('pending')
      try {
        await agentService.callAgent(values)
        notifySuccess('You should receive a call shortly')
      } catch (err) {
        notifyError(err.error)
      } finally {
        setTestCallLoader('resolved')
        setOpenDialog(false)
      }
    }
  })

  const formSubmitHandler = async () => {
    const errors = await formik.validateForm()
    if (Object.keys(errors).length) {
      Object.keys(errors).forEach(key => {
        notifyError(
          `${EMAIL_FIELDS[key]} is a required field when emails are enabled`
        )
      })
    }
    formik.handleSubmit()
  }

  return (
    <>
      <SideBarAction
        icon={<Bolt />}
        label="Create a New Action"
        tooltip="Add a new action for your agent"
        onClick={() => showDialog('addAction')}
      />
      <SideBarAction
        icon={<Description />}
        label="AI Script Wizardry"
        tooltip="Generate a script using AI"
        onClick={() => showDialog('generateScript')}
      />
      <SideBarAction
        icon={<CalendarViewMonth />}
        label="Sync Your Schedule"
        tooltip="Connect your calendar with the agent"
        onClick={() => showDialog('connectCalendar')}
      />
      <SideBarAction
        icon={<Feedback />}
        label="Call Digest"
        tooltip="Get a summary of your calls"
        onClick={() => showDialog('callDigest')}
      />
      <SideBarAction
        icon={<Psychology />}
        label="Essential Agent Guidelines"
        tooltip="Read the important instructions for your agent"
        onClick={() => showDialog('readInstructions')}
      />
      <MDBox my={1} display="flex" alignItems="center">
        {isDirty === true ? (
          <MDButton
            style={{ margin: '2rem' }}
            color="success"
            onClick={formSubmitHandler}
            disabled={isLoading}
          >
            Save
          </MDButton>
        ) : (
          <MDButton
            style={{ margin: '2rem' }}
            color="success"
            onClick={() => {
              setOpenDialog(true)
              setDialogType('talkToAgent')
            }}
          >
            Test Your Agent
          </MDButton>
        )}
        {isLoading ? <Loader /> : null}
      </MDBox>
      <MDBox my={1} display="flex" alignItems="center">
        <MDButton
          onClick={handleCallRecording}
          color="success"
          variant="contained"
          style={{ marginLeft: '2rem' }}
        >
          Recordings
        </MDButton>
      </MDBox>

      {/* for Script Assistant modal */}
      {dialogType === 'generateScript' && (
        <MDModal
          sx={{ width: { xs: '90%', md: '70%' }, px: 7, py: 2 }}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        >
          <MDBox sx={{ textAlign: 'center' }}>
            <MDTypography variant="h4" mb={2}>
              Script Assistant
            </MDTypography>
          </MDBox>
          {getScriptGenerationWizard()}
        </MDModal>
      )}
      {dialogType === 'addAction' && (
        <AlertDialog
          title="Adding Actions"
          content=" Hold on Tight! External integrations are coming soon."
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        ></AlertDialog>
      )}
      {dialogType === 'connectCalendar' && (
        <Modal title="" open={openDialog} onClose={() => setOpenDialog(false)}>
          <MDBox
            sx={{
              width: '1200px'
            }}
          >
            {calendarIntegrationsContent()}
          </MDBox>
        </Modal>
      )}
      {dialogType === 'readInstructions' && (
        <MDModal
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          sx={{ px: 4, py: 2, width: { xs: '90%', md: '40%' } }}
        >
          <MDBox sx={{ textAlign: 'center' }}>
            <MDTypography variant="h5" mb={2}>
              Must Read Instructions
            </MDTypography>
          </MDBox>
          <Instructions editorMode={formik.values.editorMode} />
        </MDModal>
      )}
      {dialogType === 'callDigest' && (
        <AlertDialog
          title="Call Digest"
          content="Hold on Tight! Call Digest is coming soon."
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        ></AlertDialog>
      )}
      {dialogType === 'talkToAgent' && (
        <MDModal
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          sx={{ px: 5, width: { md: '40%', xs: '90%' } }}
        >
          <MDTypography sx={{ textAlign: 'center', my: 2 }}>
            Talk To Your Agent
          </MDTypography>
          <MDTypography variant="body2">
            Click "Call" to join the queue. It may take up to a minute for your
            turn. Rest assured, Cald will call the number you've entered as soon
            as you're first in line.
          </MDTypography>
          <form>
            <MDInput
              label="What phone number to call?"
              name="phone"
              type="text"
              placeholder="+1-415-233-4555"
              fullWidth
              onChange={callForm.handleChange}
              onBlur={callForm.handleBlur}
              error={callForm.touched.phone && Boolean(callForm.errors.phone)}
              helperText={callForm.touched.phone && callForm.errors.phone}
              inputProps={{
                maxLength: 40
              }}
              value={callForm.values.phone}
              sx={{ marginTop: '2rem' }}
            />
            <MDTypography variant="body2" sx={{ marginTop: '2rem' }}>
              Set your variables below
            </MDTypography>
            {formik.values.variables.map((variable, index) => {
              if (variable.variable === 'phoneNumber') {
                return null // Skip rendering the input for phoneNumber variable
              }
              return (
                <MDInput
                  key={index}
                  label={variable.variable}
                  name={`variables.${variable.variable}`} // Fix the formik variables dict key
                  type="text"
                  placeholder={variable.value}
                  fullWidth
                  onChange={callForm.handleChange}
                  onBlur={callForm.handleBlur}
                  error={
                    callForm.touched.variables?.[variable.variable] && // Fix the formik variables dict key
                    Boolean(callForm.errors.variables?.[variable.variable]) // Fix the formik variables dict key
                  }
                  helperText={
                    callForm.touched.variables?.[variable.variable] && // Fix the formik variables dict key
                    callForm.errors.variables?.[variable.variable] // Fix the formik variables dict key
                  }
                  inputProps={{
                    maxLength: 40
                  }}
                  value={callForm.values.variables?.[variable.variable]} // Fix the formik variables dict key
                  sx={{ marginTop: '2rem' }}
                />
              )
            })}
          </form>
          <MDBox
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <MDButton
              icon="call"
              style={{ marginTop: '2rem', marginBottom: '2rem' }}
              color="success"
              onClick={callForm.handleSubmit}
            >
              <Icon>call</Icon>&nbsp; Call{' '}
              {testCallLoader === 'pending' ? (
                <MDBox sx={{ display: 'flex', alignItems: 'center' }} ml={2}>
                  <Loader color="white" />
                </MDBox>
              ) : null}
            </MDButton>
          </MDBox>
        </MDModal>
      )}
    </>
  )
}

export default SideBar
