import { toast } from 'react-toastify'

export const notifySuccess = message =>
  toast(<p style={{ fontSize: 16 }}>{message}</p>, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    type: 'success'
  })

export const notifyError = message =>
  toast(<p style={{ fontSize: 16 }}>{message}</p>, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    type: 'error'
  })

export const notifyWarning = message =>
  toast(<p style={{ fontSize: 16 }}>{message}</p>, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    type: 'warning'
  })
