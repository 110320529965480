import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import MDBox from 'components/MDBox'
import React from 'react'

// TODO: CONVERT THIS INTO COMPOUND COMPONENT FOR BETTER REUSABILITY
const Modal = ({
  title,
  content,
  maxWidth,
  children,
  sx,
  open,
  onClose,
  contentMinWidth
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth
      sx={{ ...sx }}
    >
      {title && (
        <DialogTitle>
          <MDBox sx={{ textAlign: 'center' }}>{title}</MDBox>
        </DialogTitle>
      )}
      <DialogContent>
        <MDBox
          p={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: contentMinWidth
          }}
        >
          {content}
        </MDBox>
      </DialogContent>
      {children && <DialogActions>{children}</DialogActions>}
    </Dialog>
  )
}

export default Modal
