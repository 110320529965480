import React from 'react'
import { Box, TextField, Typography, Paper } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import aiService from 'services/ai-service'
import MDButton from 'components/MDButton'
import { Grid } from '@mui/material'
import Loader from 'components/Loader'

const replaceTemplateValues = (template, variables) => {
  const regex = /{([^}]+)}/g
  return template.replace(
    regex,
    (match, variableName) => variables[variableName.trim()] || match
  )
}

const extractDraftScript = html => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const div = doc.querySelector('div.draft_script')
  return div ? div.innerHTML : null
}

const system_prompt_template = `
You are the world's best Sales Coach and Consultant advising businesses on how to properly do cold calls / warm calls etc. You teach world class techniques/ provide ground breaking SOPs that generate amazing results for sales people. Your secret sauce is in providing great templates that sales people follow to achieve the amazing results. Your goal now is to review this template that a sales person will be using and improve vastly to produce the best possible results by asking the specific questions you need answers on to provide the revised template.

Start with finding an objective of the call. Your first sentence should be "Hi, I'd be happy to help you with improving the {scriptName} script. What is a specific objective of the call?". 

Keep in mind that this is a script template! there is no specific prospect yet.

Then wait for a user to respond and follow up with questions to fill in any missing information - especially in the square brackets of the template. Ask one question at a time and wait for a response. 

End with the conversation "Here is your script template: ...". Respond with the final adopted template as "HTML" content wrapped iside the <div class='draft_script'> tags where each sentence follows this schema:
  
      <span>~ "$your_sentence"</span>
      <br/>
      <span>*Wait For Prospect To Respond*</span>
      <br/>
    
      """
    [Script Template]
    {scriptTemplate}
`

let history = [] //for storing the history of the conversation when talking to the AI
let messages = [] //for storing the messages that are displayed in the chat window

const ScriptGeneration = props => {
  const { scriptTemplate, onScriptGenerated } = props
  const [input, setInput] = React.useState('')
  const [disabled, setDisabed] = React.useState(false)
  const [draftScript, setDraftScript] = React.useState(null)
  // let [messages, setMessages] = React.useState([]);
  const [forceRender, setForceRender] = React.useState(false)

  async function ai(input) {
    if (history.length === 0) {
      history.push({ role: 'system', content: system_prompt })
    }
    history.push({ role: 'user', content: input })
    let response = await aiService.getCompletion(history)
    history.push({ role: 'assistant', content: response })
    return response
  }

  const handleGeneratedScript = () => {
    onScriptGenerated(draftScript)
    resetChat()
  }

  let system_prompt = replaceTemplateValues(system_prompt_template, {
    scriptTemplate: scriptTemplate.body,
    scriptName: scriptTemplate.name
  })

  if (history.length === 0) {
    handleSend('Please help me to create the script')
  }

  const send = (msg, type) => {
    // setMessages([...messages, { id: messages.length + 1, text: msg, sender: type }]);
    messages.push({ id: messages.length + 1, text: msg, sender: type })
  }

  async function handleSend(input) {
    if (input.trim() !== '') {
      setDisabed(true)

      if (history.length !== 0) {
        send(input, 'user') // don't send the first message that we created on behalf of the user to AI
      }
      let response = await ai(input)
      send(response, 'bot')
      setDraftScript(extractDraftScript(response))
      setInput('')
      setDisabed(false)
    }
  }

  const handleInputChange = event => {
    setInput(event.target.value)
  }

  const resetChat = () => {
    // setMessages([]);
    messages.length = []
    history.length = []
    setDraftScript(null)
    setInput('')
    setForceRender(!forceRender)
  }

  return (
    <Box
      sx={{
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
        {messages.map(message => (
          <Message key={message.id} message={message} />
        ))}
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField
              fullWidth
              size="small"
              placeholder="Type your answer"
              value={input}
              onChange={handleInputChange}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  handleSend(input)
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            {disabled ? (
              <Loader />
            ) : (
              <MDButton
                variant="outlined"
                fullWidth
                endIcon={<SendIcon />}
                onClick={() => handleSend(input)}
                color="info"
                disabled={disabled}
              >
                Send{' '}
              </MDButton>
            )}
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: '4rem' }}>
            <Grid item xs={6}>
              <MDButton
                fullWidth
                variant="outlined"
                color="info"
                disabled={messages.length === 1}
                onClick={resetChat}
              >
                Reset Chat
              </MDButton>
            </Grid>
            <Grid item xs={6}>
              <MDButton
                fullWidth
                variant="outlined"
                color="info"
                onClick={handleGeneratedScript}
                disabled={draftScript === null}
              >
                Use this script
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const Message = ({ message }) => {
  const isBot = message.sender === 'bot'

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isBot ? 'flex-start' : 'flex-end',
        mb: 2
      }}
    >
      <Paper
        sx={{
          p: 2,
          borderRadius: isBot ? '20px 20px 20px 5px' : '20px 20px 5px 20px'
        }}
      >
        <Typography component="div" color="light">
          {' '}
          <div dangerouslySetInnerHTML={{ __html: message.text }} />
        </Typography>
      </Paper>
    </Box>
  )
}

export default ScriptGeneration
