import MDBox from 'components/MDBox'
export const LoadingSpinner = ({ height }) => {
  return (
    <div>
      <MDBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: height ? height : '100vh'
        }}
      >
        {' '}
        <DoubleBounce />
      </MDBox>
    </div>
  )
}

function DoubleBounce() {
  return (
    <div className="sk-spinner sk-spinner-double-bounce">
      <div className="sk-double-bounce1"></div>
      <div className="sk-double-bounce2"></div>
    </div>
  )
}
