import Icon from '@mui/material/Icon'
import {
  CampaignsPage,
  AgentsPage,
  ContactGroupsPage,
  KnowledgeBaseList,
  PhoneNumbersPage
} from 'pages'
import Users from 'pages/users'

export const ROLES = {
  ADMIN: 'admin'
}

const routes = [
  {
    type: 'collapse',
    name: 'My Contacts',
    key: 'contactgroups',
    component: <ContactGroupsPage />,
    route: '/contactgroups',
    icon: <Icon fontSize="medium">contact_phone</Icon>,
    noCollapse: true,
    role: ''
  },
  {
    type: 'collapse',
    name: 'My Agents',
    key: 'agents',
    component: <AgentsPage />,
    route: '/agents',
    icon: <Icon fontSize="medium">support_agent</Icon>,
    noCollapse: true,
    role: ''
  },
  {
    type: 'collapse',
    name: 'Knowledge Hub',
    key: 'knowledgebase',
    component: <KnowledgeBaseList />,
    route: '/knowledgebase',
    icon: <Icon fontSize="medium">library_books</Icon>,
    noCollapse: true,
    role: ''
  },
  {
    type: 'collapse',
    name: 'Campaign Central',
    key: 'campaigns',
    component: <CampaignsPage />,
    route: '/campaigns',
    icon: <Icon fontSize="medium">ring_volume</Icon>,
    noCollapse: true,
    role: ''
  },
  {
    type: 'collapse',
    name: 'My Phone Numbers',
    key: 'phoneNumbers',
    component: <PhoneNumbersPage />,
    route: '/phoneNumbers',
    icon: <Icon fontSize="medium">phone</Icon>,
    noCollapse: true,
    role: ''
  },
  {
    type: 'collapse',
    name: 'Voice Library',
    key: 'voices',
    component: <PhoneNumbersPage />,
    route: '/voices',
    icon: <Icon fontSize="medium">keyboard_voice</Icon>,
    noCollapse: true,
    role: ''
  },
  {
    type: 'collapse',
    name: 'Users',
    key: 'user',
    component: <Users />,
    route: '/users',
    icon: <Icon fontSize="medium">person</Icon>,
    noCollapse: true,
    role: ROLES.ADMIN
  }
]

export default routes
