import HttpService from './http.service'

class AgentService {
  getAgents = async () => {
    return await HttpService.get('/api/agents')
  }
  getScriptTemplatesInfo = async () => {
    return await HttpService.get('/api/agents/scriptTemplatesInfo')
  }
  getAgent = async id => {
    return await HttpService.get(`/api/agents/${id}`)
  }
  deleteAgent = async id => {
    return await HttpService.delete(`/api/agents/${id}`)
  }
  addAgent = async payload => {
    return await HttpService.post(`/api/agents`, payload)
  }
  updateAgent = async (id, payload) => {
    return await HttpService.patch(`/api/agents/${id}`, payload)
  }
  callAgent = async payload => {
    return await HttpService.post(`/api/agents/call`, payload)
  }
  verifyEmail = async payload => {
    return await HttpService.post(`/api/agents/verify`, payload)
  }
  cloneAgent = async payload => {
    return await HttpService.post('/api/agents/clone', payload)
  }
}

export default new AgentService()
