import { Dialog, DialogContent } from '@mui/material'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'

const CreateCampaignSuccess = ({ isOpen = false, onClose, newCampaign }) => {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogContent sx={{ padding: '2rem' }}>
        <MDBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'center'
          }}
        >
          <MDBox sx={{ fontSize: '2.25rem' }}>🎉</MDBox>
          <MDTypography
            component="h2"
            sx={{ fontWeight: 600, color: '#344767' }}
            px={3}
          >
            Hooray! You've Just Set Your Campaign Soaring! 🚀
          </MDTypography>
          <MDBox sx={{ fontSize: '2.25rem' }}>🎉</MDBox>
        </MDBox>
        <MDBox sx={{ color: '#344767', padding: '1rem' }}>
          <MDTypography variant="body2" sx={{ marginTop: '1rem' }}>
            Congratulations on launching your campaign with Cald! You're now
            among the pioneers shaping the future of conversational AI in
            business.
          </MDTypography>
          <MDTypography variant="body2" sx={{ marginTop: '1rem' }}>
            A quick heads-up: your campaign is queuing up, and it might take
            15-30 minutes for Cald to start reaching out to your leads, or more
            if you have a large list. We're working on speeding this up. Keep an
            eye on the{' '}
            <MDTypography
              component="a"
              variant="body2"
              href={`/campaigns/${newCampaign}`}
              target="_blank"
              rel="noopener noreferrer"
              color="info"
              textGradient
            >
              Campaign Results
            </MDTypography>{' '}
            page to see how it's going.
          </MDTypography>
          <MDTypography variant="body2" sx={{ marginTop: '1rem' }}>
            We're thrilled to be part of your journey and are here for you every
            step of the way. Reach out anytime in our discord or through support
            - we read every message and love hearing from you.
          </MDTypography>
          <MDTypography variant="body2" sx={{ marginTop: '1rem' }}>
            Here's to the exciting journey ahead in transforming your business!
          </MDTypography>
        </MDBox>
        <MDButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={onClose}
        >
          Got It, Thank you!
        </MDButton>
      </DialogContent>
    </Dialog>
  )
}

export default CreateCampaignSuccess
