import React, { useState, useCallback } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import MDButton from 'components/MDButton'

import MDBox from 'components/MDBox'
import MDModal from 'components/MDModal'
import EditorChoice from './components/EditorChoice'
import AgentInfo from './components/AgentInfo'
import ScriptToFollow from './components/ScriptToFollow'

import { AgentType, EditorMode } from 'data/agentInfo'
import agentService from 'services/agent-service'
import { notifyError } from 'components/Messages'

// Define validation schema outside of the component
const validationSchema = yup.object({
  agentName: yup
    .string()
    .min(2, 'Agent name must be at least 2 characters')
    .required('Agent name is required'),
  type: yup.string().required('You have not selected a use case'),
  editorMode: yup.string().required('You have not selected a mode')
})
const steps = 3

const CreateAgentDialog = ({ open, onClose }) => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const isLastStep = activeStep === steps - 1

  const formik = useFormik({
    initialValues: {
      agentName: '',
      type: AgentType.SALES,
      editorMode: EditorMode.STANDARD,
      useAI: false,
      scriptTemplate: ''
    },
    validationSchema,
    onSubmit: (values, actions) => {
      if (isLastStep) {
        handleSubmitForm(values, actions)
      } else {
        setActiveStep(prevStep => prevStep + 1)
        actions.setTouched({})
        actions.setSubmitting(false)
      }
    }
  })

  const handleSubmitForm = async (values, actions) => {
    mutate({ values })
  }

  const { mutate } = useMutation(agentService.addAgent, {
    onSuccess: data => {
      let link = `/agents/${data.agent._id}`
      if (formik.values.useAI) {
        link += '?useAI'
      }
      navigate(link)
      formik.resetForm()
      onClose()
    },
    onError: error => {
      console.error(error)
      notifyError(error.message ?? 'Something went wrong')
      formik.resetForm()
      onClose()
    }
  })

  const handleBack = useCallback(
    () => setActiveStep(prevStep => prevStep - 1),
    []
  )

  return (
    <MDModal
      open={open}
      onClose={onClose}
      sx={{
        padding: '1.5rem',
        borderRadius: '8px',
        borderColor: '#FAFAFA',
        width: '100%',
        maxWidth: '50rem',
        maxHeight: '85%',
        overflow: 'auto'
      }}
    >
      <MDBox component="form" onSubmit={formik.handleSubmit}>
        {getStepContent(activeStep, formik)}
        <MDBox
          mt={2}
          width="100%"
          display="flex"
          justifyContent="space-between"
        >
          {activeStep === 0 ? (
            <MDBox />
          ) : (
            <MDButton variant="gradient" color="primary" onClick={handleBack}>
              Back
            </MDButton>
          )}
          <MDButton
            disabled={formik.isSubmitting}
            type="submit"
            variant="gradient"
            color="success"
          >
            {isLastStep ? 'Send' : 'Next'}
          </MDButton>
        </MDBox>
      </MDBox>
    </MDModal>
  )
}

const getStepContent = (stepIndex, formData) => {
  switch (stepIndex) {
    case 0:
      return <AgentInfo formik={formData} />
    case 1:
      return <EditorChoice formik={formData} />
    case 2:
      return <ScriptToFollow formik={formData} />
    default:
      return null
  }
}

export default CreateAgentDialog
