import React, { useMemo, useState, useEffect, Fragment } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import MDBox from 'components/MDBox'
import { DataTable } from 'components/Tables/material'
import AlertDialog from 'components/AlertDialog'
import MDButton from 'components/MDButton'

import listService from 'services/list-service'
import { notifySuccess } from 'components/Messages'
import { useNavigate } from 'react-router-dom'
import UploadContactsWizard from '../UploadContactsWizard'

const GroupList = ({ initialData, isLoading }) => {
  const [showAddDialog, setShowAddDialog] = React.useState(false)
  const [data, setData] = useState(initialData)
  const [deleteItem, setDeleteItem] = useState(null)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  useEffect(() => {
    setData(initialData)
  }, [initialData])

  const { mutate } = useMutation(listService.deleteList, {
    onSuccess: () => {
      queryClient.invalidateQueries(['contactsAndLists'])
      notifySuccess('Contacts group deleted successfully')
      setDeleteItem(null) // Close the dialog on success
    }
  })

  const openDeleteDialog = item => {
    setDeleteItem(item)
  }

  const confirmDelete = () => {
    if (deleteItem) {
      handleDelete(deleteItem)
    }
  }

  const handleDelete = id => {
    const newData = data.filter(item => item._id !== id)
    setData(newData)
    mutate(id)
  }

  const tableData = useMemo(
    () => ({
      columns: [
        { header: 'Contact Group', accessorKey: 'listName' },
        { header: 'Number of Contacts', accessorKey: 'nbContacts' },
        {
          header: 'Additional Fields',
          accessorKey: 'additionalFields',
          Cell: ({ value }) => (value ? value.join(', ') : '')
        },
        {
          header: 'Action',
          accessorKey: 'action',
          Cell: ({ row }) => (
            <MDBox display="flex" alignItems="center">
              <Tooltip title="Delete">
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    openDeleteDialog(row.original.id)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </MDBox>
          )
        }
      ],
      rows:
        data?.map(list => ({
          id: list._id,
          listName: list.listName,
          nbContacts: list.nbContacts,
          additionalFields: list.additionalFields || []
        })) || []
    }),
    [data]
  )

  if (isLoading) return 'Loading...'

  const renderDeleteModalContent = () => (
    <div>
      <strong>Warning:</strong> Your contact group will be permanently deleted
    </div>
  )

  return (
    <Fragment>
      <DataTable
        table={tableData}
        canSearch
        onRowClick={row => navigate(`/groupdetails/${row.original.id}`)}
        onNewItem={() => setShowAddDialog(true)}
        newItemLabel="Upload Contacts"
      />
      <UploadContactsWizard
        open={showAddDialog}
        lists={data}
        onClose={() => setShowAddDialog(false)}
      />
      <AlertDialog
        open={Boolean(deleteItem)}
        onClose={() => setDeleteItem(null)}
        title="Just Checking In!"
        content={renderDeleteModalContent()}
      >
        <MDButton onClick={() => setDeleteItem(null)}>Cancel</MDButton>
        <MDButton color="error" onClick={confirmDelete}>
          Delete
        </MDButton>
      </AlertDialog>
    </Fragment>
  )
}

export default GroupList
