import HttpService from './http.service'

class CallService {
  getCalls = async (page = 1, limit = 10) => {
    return await HttpService.get(`/api/calls?page=${page}&limit=${limit}`)
  }

  getCallsByType = async ({ type, id, page = 1, limit = 10000 }) => {
    const types = ['agent', 'campaign', 'user']
    if (!types.includes(type)) {
      throw new Error('Invalid type')
    }
    if (!id) {
      throw new Error('id is required')
    }
    return await HttpService.get(
      `/api/calls?type=${type}&id=${id}&page=${page}&limit=${limit}`
    )
  }
  getCall = async id => {
    return await HttpService.get(`/api/calls/${id}`)
  }
  deleteCall = async id => {
    return await HttpService.delete(`/api/calls/${id}`)
  }
  addCall = async payload => {
    return await HttpService.post(`/api/calls`, payload)
  }
  updateCall = async (id, payload) => {
    return await HttpService.patch(`/api/calls/${id}`, payload)
  }
  getPresignedUrl = async path => {
    return await HttpService.get(`/api/calls/presigned-url/${path}`)
  }
}

export default new CallService()
