import HttpService from './http.service'

class AuthService {
  login = async payload => {
    const loginEndpoint = 'login'
    return await HttpService.post(loginEndpoint, payload)
  }

  register = async credentials => {
    const registerEndpoint = 'register'
    return await HttpService.post(registerEndpoint, credentials)
  }

  logout = async () => {
    const logoutEndpoint = '/api/auth/logout'
    return await HttpService.post(logoutEndpoint)
  }

  forgotPassword = async payload => {
    const forgotPassword = 'password-forgot'
    return await HttpService.post(forgotPassword, payload)
  }

  resetPassword = async credentials => {
    const resetPassword = '/api/password-reset'
    return await HttpService.post(resetPassword, credentials)
  }

  getProfile = async () => {
    const getProfile = 'me'
    return await HttpService.get(getProfile)
  }

  updateProfile = async newInfo => {
    const updateProfile = 'me'
    return await HttpService.patch(updateProfile, newInfo)
  }
}

export default new AuthService()
