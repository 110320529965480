export const materialReactTableStyles = (theme, darkMode) => ({
  muiTablePaperProps: {
    elevation: 0
  },
  mrtTheme: theme => ({
    baseBackgroundColor: darkMode
      ? theme.palette.background.card
      : theme.palette.white.main
  }),
  muiTableHeadCellProps: {
    sx: {
      color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
      '.MuiButtonBase-root': {
        '& .MuiSvgIcon-root': {
          fill: darkMode ? theme.palette.white.main : theme.palette.dark.main
        }
      }
    }
  },
  muiTableBodyCellProps: {
    sx: {
      color: darkMode ? theme.palette.white.main : theme.palette.dark.main
    }
  },
  muiBottomToolbarProps: {
    color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
    sx: {
      '.MuiInputBase-root': {
        color: darkMode ? theme.palette.white.main : theme.palette.dark.main
      },
      '.MuiButtonBase-root': {
        color: darkMode ? theme.palette.white.main : theme.palette.dark.main
      }
    }
  },
  muiTopToolbarProps: {
    sx: {
      '.MuiButtonBase-root': {
        color: darkMode ? theme.palette.white.main : theme.palette.dark.main
      }
    }
  }
})
