import React, { useState } from 'react'
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  Tooltip,
  IconButton
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import MDButton from 'components/MDButton'
import AlertDialog from 'components/AlertDialog'
import VariablesEditor from './components/VariablesEditor'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import MDTooltip from 'components/MDTooltip'




function renderAsVariable(str, defaultValue = '') {
  if (defaultValue === '') {
    return `{${str}}`
  } else {
    return `{${str}} - by default: ${defaultValue}`
  }
}


const VariablesSection = ({ variables, onChange }) => {
  const [isVariableEditorOpen, setIsVariableEditorOpen] = useState(false)
  const [selectedVariable, setSelectedVariable] = useState({})
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)

  const handleDelete = variableToDelete => {
    setDeleteItem(variableToDelete)
    setShowDeleteDialog(true)
  }

  const confirmDelete = () => {
    const newVariables = variables.filter(
      variable => variable.variable !== deleteItem.variable
    )
    onChange(newVariables)
    setDeleteItem(null)
    setShowDeleteDialog(false)
  }

  const handleEdit = variable => {
    setSelectedVariable(variable)

    setIsVariableEditorOpen(true)
  }

  const handleUpdate = updatedVariable => {
    const variableIndex = variables.findIndex(
      variable => variable.variable === updatedVariable.variable
    )

    if (variableIndex === -1) {
      // The variable doesn't exist, so add it
      const newVariables = [...variables, updatedVariable]
      onChange(newVariables)
    } else {
      // The variable exists, so update it
      const newVariables = [...variables]
      newVariables[variableIndex] = updatedVariable
      onChange(newVariables)
    }
    setIsVariableEditorOpen(false)
    setSelectedVariable(null)
  }

  return (
    <>
      <Card sx={{ marginTop: '1rem', width: '100%' }}>
        <CardContent>
          <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" component="div">
              Variables
            </Typography>
            <MDTooltip
              title={
                <React.Fragment>
                  <MDTypography variant="h5" m={1}>
                    {' '}
                    Variables Overview and Tips
                  </MDTypography>
                  <ul>
                    <li key="1">
                      A variable is a placeholder for a value that will be
                      filled in during the call to make it more personal and
                      relevant to your prospect.
                    </li>
                    <li key="2">
                      For example, if you type{' '}
                      {renderAsVariable('prospectName')} anywhere in your
                      script, it will be replaced with your prospect's actual
                      name.
                    </li>
                    <li key="3">
                      Anything inside '&#123;' curly '&#125;' brackets will be
                      automatically treated as a variable. You define a variable
                      directly in your script every time you put anything into a
                      curly brackets
                    </li>
                  </ul>
                  <MDTypography variant="h6" m={1}>
                    {' '}
                    Colors and Meaning
                  </MDTypography>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Tooltip title="This variable is always available">
                        <Chip label="System Variable" color="info" />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="This variable is used in at at least one of your templates">
                        <Chip
                          label="Variable is being used in a template"
                          color="success"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="This variable is defined but is not being used anywhere in your templates">
                        <Chip
                          label="Variable is not being used"
                          color="warning"
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              placement="right-end"
              arrow={false}
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </MDTooltip>
          </MDBox>
          <Grid container spacing={1}>
            {variables.map((variable, index) => (
              <Tooltip
                key={index}
                title={
                  (variable?.default !== '' && variable?.default !== undefined)
                    ? 'By default - ' + variable?.default + ' - value will be used if your contact info does not contain it... Click to edit.'
                    : "This variable doesn't yet have a default value set in case your contact info doesn't have this variable specified... Click to set a default value."
                }
              >
                <Chip
                  key={index}
                  color={
                    variable.system
                      ? 'info'
                      : variable?.used
                      ? 'success'
                      : 'warning'
                  }
                  label={renderAsVariable(variable.variable, variable?.default)}
                  onClick={() => handleEdit(variable)}
                  onDelete={
                    variable.system ? undefined : () => handleDelete(variable)
                  }
                />
              </Tooltip>
            ))}
            {/* <Tooltip title="Add a new variable directly, alternatively you can simply define a variable by using curly brackets in your templates">
              <Chip
                icon={<AddIcon />}
                label="Add Variable"
                variant="outlined"
                onClick={() => handleEdit(null)}
              />
            </Tooltip> */}
          </Grid>
          <VariablesEditor
            key={selectedVariable?.variable || 'new'} 
            variable={selectedVariable}
            open={isVariableEditorOpen}
            mode="edit"
            onClose={() => { setIsVariableEditorOpen(false)}}
            onUpdate={variable => handleUpdate(variable)}
          />
        </CardContent>
      </Card>
      <AlertDialog
        open={showDeleteDialog}
        onClose={() => setDeleteItem(null)}
        title="Just Checking In!"
        content={`Are you sure you want to delete ${deleteItem?.variable} variable? This will delete the variable from all of your script templates`}
      >
        <MDButton
          onClick={() => {
            setShowDeleteDialog(false)
            setDeleteItem(null)
          }}
        >
          Cancel
        </MDButton>
        <MDButton color="error" onClick={confirmDelete}>
          Delete
        </MDButton>
      </AlertDialog>
    </>
  )
}
export default VariablesSection
