import React from 'react'
import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
  FormHelperText
} from '@mui/material'
import MDInput from 'components/MDInput'

const AgentInfo = ({ formik }) => {
  const radioOptions = [
    { value: 'sales', label: 'Outbound Calls - The Deal Makers (like a Sales Agent)' },
    { value: 'support', label: 'Inbound Calls - The Day Savers (like a Support Agent)' }
    // { value: 'other', label: 'Other (Custom use case) – Chart Your Own Course', disabled: false },
  ]

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <FormControl
        sx={{ m: 3 }}
        error={Boolean(formik.errors.type)}
        variant="standard"
      >
        <Stack sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Welcome a New Team Star!</Typography>
          <Typography variant="body1">
            Let's set the stage for your new agent.
          </Typography>
        </Stack>
        <Box sx={{ width: '100%' }} mt={2}>
          <MDInput
            label="Agent's Name"
            fullWidth
            value={formik.values.agentName}
            id="agentName"
            autoComplete="off"
            placeholder="Give your new agent a name that sparkles!"
            name="agentName"
            onChange={formik.handleChange}
            error={formik.touched.agentName && Boolean(formik.errors.agentName)}
            helperText={formik.touched.agentName && formik.errors.agentName}
            inputProps={{ autoComplete: 'off' }}
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6" textAlign="left">
            Select Responsibility Type
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            border: '1px solid rgb(191 219 254 / .5)',
            borderRadius: 2
          }}
          mt={2}
        >
          <RadioGroup
            aria-labelledby="type-label"
            name="type"
            value={formik.values.type}
            onChange={formik.handleChange}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            {radioOptions.map(option => (
              <Box
                key={option.value}
                width="100%"
                borderBottom={1}
                p={2}
                sx={{
                  borderColor: 'rgb(191 219 254 / .5)',
                  backgroundColor:
                    formik.values.type === option.value
                      ? 'rgb(191 219 254 / .1)'
                      : ''
                }}
              >
                <FormControlLabel
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  disabled={option.disabled}
                />
              </Box>
            ))}
          </RadioGroup>
        </Box>
        {formik.errors.type && (
          <FormHelperText>{formik.errors.type}</FormHelperText>
        )}
      </FormControl>
    </Box>
  )
}

export default AgentInfo
