import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import MDModal from 'components/MDModal'
import React from 'react'

function AddActionModal({ formik, isLoading, open, onClose }) {
  return (
    <MDModal
      disableAutoFocus
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: 'whitesmoke'
      }}
    >
      <Box
        component="form"
        sx={{
          padding: '1.5rem',
          borderRadius: '8px',
          borderColor: '#FAFAFA',
          backgroundColor: '#FAFAFA',
          width: '100%',
          maxWidth: '40rem',
          maxHeight: '85%',
          overflow: 'auto'
        }}
      >
        <AddAction formik={formik} isLoading={isLoading} />
      </Box>
    </MDModal>
  )
}

const AddAction = ({ formik, isLoading }) => {
  return (
    <Box width="100%">
      <Stack sx={{ textAlign: 'center', pt: 5 }}>
        <Typography variant="h4" id="demo-error-radios">
          Update Action
        </Typography>
      </Stack>
      <Box pt={3}>
        <Typography variant="h6">Name of Action</Typography>
        <TextField
          variant="outlined"
          name="question"
          value={''}
          onChange={() => {}}
          sx={{ width: '100%', marginTop: 2 }}
          helperText={
            formik.touched.actionName &&
            Boolean(formik.errors.actionName) &&
            formik.errors.actionName
          }
        />
      </Box>
      <Box pt={3}>
        <Typography variant="h6">
          Tell Zapier to trigger this action when your Cald Agent says something
          like:
        </Typography>
        <TextField
          variant="outlined"
          name="question"
          value={''}
          onChange={() => {}}
          sx={{ width: '100%', marginTop: 2 }}
          helperText={
            formik.touched.actionTrigger &&
            Boolean(formik.errors.actionTrigger) &&
            formik.errors.actionTrigger
          }
        />
      </Box>
      <FormControlLabel
        sx={{ display: 'flex', mt: 3, fontSize: '9px' }}
        control={
          <Checkbox
            checked={true}
            onChange={evt => {
              formik.setFieldValue('terms', evt.target.checked)
            }}
          />
        }
        label="Check this box to confirm that you already have or you will setup a zapier to fire for this action. Remember, if you don't attach a zapier to this action, then nothing will actually happen. If you want to learn how to setup a zapier to fire on this action click here."
      />
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}
        pt={2}
      >
        <Button
          variant="contained"
          sx={{
            color: 'whitesmoke',
            width: '50%',
            backgroundColor: 'grey'
          }}
          //   onClick={handleNextStep}
        >
          Delete Action
        </Button>
        <Button
          variant="contained"
          sx={{
            color: 'whitesmoke',
            width: '50%'
          }}
          //   onClick={handleNextStep}
        >
          Update Action
        </Button>
      </Box>
    </Box>
  )
}

export default AddActionModal
