import { useCallback, useEffect, useState } from 'react'

// @material-ui core components
import {
  Alert,
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Link, useNavigate } from 'react-router-dom'

// Material Dashboard 2 PRO React components
import MDAlert from 'components/MDAlert'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import MDButton from 'components/MDButton'
import FormField from './FormField'

import agentService from 'services/agent-service'
import BillingService from 'services/billing-service'
import campaignService from 'services/campaign-service'
import listService from 'services/list-service'

import { Info } from '@mui/icons-material'
import Loader from 'components/Loader'
import MDDatePicker from 'components/MDDatePicker'
import MDTooltip from 'components/MDTooltip'
import { notifyError, notifySuccess } from 'components/Messages'
import { useFormik } from 'formik'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { roundToTwo } from 'utils/utilsFunctions'
import * as Yup from 'yup'
const convertTimeToMinutes = timeString => {
  const [hours, minutes] = timeString.split(':')
  return parseInt(hours, 10) * 60 + parseInt(minutes, 10)
}

const validationSchema = Yup.object().shape({
  campaignName: Yup.string().required('Campaign Name is required'),
  startDate: Yup.string().required('Start date is required'),
  endDate: Yup.string().required('End time is required')
})

const USE_OWN_NUMBER_OPTIONS = {
  YES: 'yes',
  NO: 'no'
}

const NewCampaignForm = ({ onClose, onOkHandler, setNewCampaign }) => {
  const [showDescription, setShowDescription] = useState(true)
  const [cost, setCost] = useState(null)

  const defaultAgentOption = { id: '', agentName: 'Not Selected' }
  const defaultListOption = { id: '', listName: 'Not Selected' }
  const [availableFunds, setAvailableFunds] = useState(null)
  const [loading, setLoading] = useState('idle')
  const queryClient = useQueryClient()

  const { data: agentData, isLoading: agentLoading } = useQuery(
    ['agents'],
    agentService.getAgents,
    { refetchOnWindowFocus: false }
  )
  const { data: listData, isLoading: listLoading } = useQuery(
    ['lists'],
    listService.getLists,
    { refetchOnWindowFocus: false }
  )
  const { data: phoneNumbers } = useQuery(
    'phoneNumbers',
    campaignService.getAvailablePhoneNumbers,
    {
      select: response => {
        if (response && response.phoneNumbers) {
          return response.phoneNumbers.map(number => ({
            phoneNumber: number.phoneNumber
          }))
        }
        return []
      },
      onError: error => {
        notifyError('Error loading your phone numbers: ' + error.message)
      }
    }
  )

  const balance = () => {
    return roundToTwo(availableFunds - cost)
  }

  const { mutate } = useMutation(e => campaignService.addCampaign(e), {
    onSuccess: data => {
      const { campaign } = data
      const id = campaign._id
      setNewCampaign(id)
      queryClient.refetchQueries(['campaigns'])
      onOkHandler()
    },
    onError: error => {
      notifyError(error?.error)
      onClose()
    }
  })

  const formik = useFormik({
    initialValues: {
      campaignName: '',
      list: null,
      agent: null,
      useCustomPhoneNumber: USE_OWN_NUMBER_OPTIONS.NO,
      customPhoneNumbers: [],
      startDate: '09:00',
      endDate: '17:00',
      startHour: 9,
      startMinute: 0,
      endHour: 17,
      endMinute: 0
    },
    validationSchema,
    onSubmit: async e => {
      if (
        (e.useCustomPhoneNumber === USE_OWN_NUMBER_OPTIONS.YES &&
          !phoneNumbers.length) ||
        (e.useCustomPhoneNumber === USE_OWN_NUMBER_OPTIONS.YES &&
          !e.customPhoneNumbers.length)
      ) {
        notifyError('Please select valid phone numbers')
        return
      }
      mutate({
        agent: e.agent,
        campaignName: e.campaignName,
        list: e.list,
        startHour: e.startHour,
        startMinute: e.startMinute,
        endHour: e.endHour,
        endMinute: e.endMinute,
        customPhoneNumbers: e.useCustomPhoneNumber === USE_OWN_NUMBER_OPTIONS.YES
          ? e.customPhoneNumbers.map(item => item.phoneNumber)
          : []
      })
    }
  })
  const { mutate: checkEmailMutation, isLoading: emailVerificationLoader } =
    useMutation(({ values }) => agentService.verifyEmail(values), {
      onSuccess: agentData => {
        queryClient.invalidateQueries(['agents'])
        formik.setFieldValue('agent.isEmailVerified', agentData.isEmailVerified)
        notifySuccess(
          `Verification check completed. The email is ${
            agentData.isEmailVerified ? 'verified' : 'not verified'
          }`
        )
      },
      onError: () => {
        notifyError('Verification check failed. Please try again later')
      }
    })
  const navigate = useNavigate()

  const getCampaignCost = useCallback(async () => {
    const payload = {
      agent: {
        _id: formik.values.agent?._id,
        callSettingsKeys: formik.values.agent?.callSettingsKeys
      },
      list: { _id: formik.values.list?._id }
    }
    const cost = await campaignService.getCampaignCost(payload)
    setCost(cost?.campaignCost)
  }, [
    formik.values.agent?._id,
    formik.values.agent?.callSettingsKeys,
    formik.values.list?._id
  ])

  useEffect(() => {
    // Check if both agent and list are specified (have an _id property)
    if (formik.values.agent?._id && formik.values.list?._id) {
      getCampaignCost()
    }
  }, [formik.values.agent, formik.values.list, getCampaignCost])

  useEffect(() => {
    setLoading('pending')
    BillingService.getAvailableBalance().then(data => {
      setAvailableFunds(data.balance ?? 0)
      setLoading('resolved')
    })
  }, [])

  useEffect(() => {
    if (phoneNumbers?.length) {
      formik.setFieldValue(
        'customPhoneNumbers',
        phoneNumbers.map(ph => ph)
      )
    }
  }, [phoneNumbers])

  if (loading === 'pending' || agentLoading || listLoading) {
    return (
      <MDBox
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        height="30vh"
        width="30vw"
      >
        <Loader />
      </MDBox>
    )
  } else if (availableFunds === 0) {
    return (
      <Card id="basic-info" sx={{ overflow: 'visible' }}>
        <MDBox component="form" p={3}>
          <Grid item xs={12}>
            <MDTypography
              component="label"
              sx={{
                display: 'block',
                fontSize: '14px',
                textAlign: 'justify',
                marginBottom: '1rem'
              }}
            >
              You currently have $0.00 credit. To run campaigns you need to add
              funds to your account. Please add funds to your account to
              continue.
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate('/account/settings#billing-info')}
            >
              Go to Billing Info
            </MDButton>
          </Grid>
        </MDBox>
      </Card>
    )
  }

  return (
    <Card id="basic-info" sx={{ overflow: 'visible' }}>
      <MDBox component="form" p={3} onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormLabel
              sx={{
                display: 'block',
                fontWeight: '500',
                fontSize: '1rem',
                lineHeight: '1.5rem',
                marginBottom: '0.5rem'
              }}
            >
              Campaign Name
            </FormLabel>
            <FormField
              name="campaignName"
              placeholder="E.g., Summer Promo Calls"
              onChange={formik.handleChange}
              value={formik.values.campaignName}
              error={
                formik.touched.campaignName &&
                Boolean(formik.errors.campaignName)
              }
              helperText={
                formik.touched.campaignName && formik.errors.campaignName
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel
                sx={{
                  display: 'block',
                  fontWeight: '500',
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  marginBottom: '0.5rem'
                }}
              >
                Choose Your Campaign Type
              </FormLabel>
              <RadioGroup
                defaultValue="outbound"
                name="campaign-type"
                sx={{ display: 'flex', gap: '0.5rem' }}
              >
                <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    value="outbound"
                    control={<Radio />}
                    onClick={() => setShowDescription(true)}
                  />
                  <MDBox sx={{ marginLeft: '-0.5rem' }}>
                    <MDTypography
                      variant="subtitle1"
                      sx={{ fontSize: '1rem', fontWeight: 400 }}
                    >
                      Outbound Calling
                    </MDTypography>
                    <MDTypography variant="subtitle2">
                      Reach out and connect! Cald dials out during local working
                      hours, ensuring your message gets heard.
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    value="inbound"
                    control={<Radio />}
                    onClick={() => setShowDescription(false)}
                    disabled
                  />
                  <MDBox sx={{ marginLeft: '-0.5rem' }}>
                    <MDTypography
                      variant="subtitle1"
                      sx={{ fontSize: '1rem', fontWeight: 400 }}
                    >
                      Inbound Calling
                    </MDTypography>
                    <MDTypography
                      variant="subtitle2"
                      sx={{ color: 'rgba(0, 0, 0, 0.3)' }}
                    >
                      Campaigns are specific to outbound calls. For inbound,
                      head over to the{' '}
                      <Link
                        to="/phoneNumbers"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Phone Numbers
                      </Link>{' '}
                      page to set up your inbound number and assign it to an
                      agent.
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel
                sx={{
                  display: 'block',
                  fontWeight: '500',
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  marginBottom: '0.5rem'
                }}
              >
                Would you like to use your own numbers for this campaign?
              </FormLabel>
              <RadioGroup
                name="useCustomPhoneNumber"
                sx={{ display: 'flex', gap: '0.5rem' }}
                value={formik.values.useCustomPhoneNumber}
                onChange={formik.handleChange}
              >
                <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    value={USE_OWN_NUMBER_OPTIONS.YES}
                    control={<Radio />}
                  />
                  <MDBox sx={{ marginLeft: '-0.5rem' }}>
                    <MDTypography
                      variant="subtitle1"
                      sx={{ fontSize: '1rem', fontWeight: 400 }}
                    >
                      Yes
                    </MDTypography>
                  </MDBox>
                  <MDTooltip
                    title="You can select multiple phone numbers that you own to use for this campaign."
                    placement="right-end"
                    arrow={false}
                  >
                    <IconButton size="small">
                      <Info />
                    </IconButton>
                  </MDTooltip>
                </MDBox>
                <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    value={USE_OWN_NUMBER_OPTIONS.NO}
                    control={<Radio />}
                  />
                  <MDBox sx={{ marginLeft: '-0.5rem' }}>
                    <MDTypography
                      variant="subtitle1"
                      sx={{ fontSize: '1rem', fontWeight: 400 }}
                    >
                      No
                    </MDTypography>
                  </MDBox>
                  <MDTooltip
                    title="Opting out may increase the likelihood of this number being flagged as spam."
                    placement="right-end"
                    arrow={false}
                  >
                    <IconButton size="small">
                      <Info />
                    </IconButton>
                  </MDTooltip>
                </MDBox>
              </RadioGroup>
            </FormControl>
          </Grid>
          {formik.values.useCustomPhoneNumber === USE_OWN_NUMBER_OPTIONS.YES ? (
            <Grid item xs={12} sm={12}>
              {phoneNumbers.length ? (
                <Autocomplete
                  options={phoneNumbers}
                  getOptionLabel={op => op.phoneNumber}
                  name="customPhoneNumbers"
                  defaultValue={phoneNumbers?.map(i => i)}
                  multiple
                  value={formik.values.customPhoneNumbers}
                  onChange={(_, value) => {
                    formik.setFieldValue('customPhoneNumbers', value)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select Your Phone Number"
                      placeholder="Choose a phone number"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              ) : (
                <Alert variant="outlined" severity="warning">
                  You don't currently have any numbers. Please purchase them
                  from{' '}
                  <Link
                    to="/phoneNumbers"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </Link>
                  .
                </Alert>
              )}
            </Grid>
          ) : null}
          {formik.values.agent &&
            formik.values.agent.isEmailEnabled &&
            !formik.values.agent.isEmailVerified && (
              <MDBox item xs={12} sm={6} p={2}>
                <Alert severity="error">
                  <p>
                    {' '}
                    This agent will not be able to send any follow up emails
                    since, the email address to send it from has not been yet
                    verified.
                  </p>
                  Check the email's inbox to complete the verification step,
                  then{' '}
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontSize: '1rem',
                      fontWeight: 300,
                      textUnderlineOffset: 2
                    }}
                    component="span"
                    onClick={() => {
                      checkEmailMutation({
                        values: {
                          email: formik.values.agent.emailSender,
                          agentId: formik.values.agent._id
                        }
                      })
                    }}
                  >
                    click here
                  </Typography>{' '}
                  to refresh the status{' '}
                  {emailVerificationLoader ? <Loader /> : null}
                </Alert>
              </MDBox>
            )}
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={[
                defaultAgentOption,
                ...(agentData?.agents?.length ? agentData?.agents : [])
              ]}
              name="agent"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={option => (option ? option.agentName : '')}
              value={formik.values.agent}
              onChange={(_, newValue) => {
                formik.setFieldValue('agent', newValue ? newValue : '')
                formik.setFieldValue(
                  'isEmailVerified',
                  newValue ? newValue.isEmailVerified : true
                )
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Your Agent"
                  placeholder="Choose an AI agent"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={[defaultListOption, ...(listData && listData?.lists)]}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={option => (option ? option.listName : '')}
              value={formik.values.list}
              onChange={(_, newValue) => {
                formik.setFieldValue('list', newValue ? newValue : '')
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Your Contact Group"
                  placeholder="Group for this campaign"
                  InputLabelProps={{ shrink: true }}
                  disabled={!formik.values.agent}
                />
              )}
            />
          </Grid>
          <MDTooltip
            title="Campaign times are in the local time of the prospect"
            placement="bottom-end"
          >
            <Grid item container spacing={3}>
              <Grid item xs={6} sm={6}>
                <MDTooltip
                  title="Select a time between 9:00 AM and 7:00 PM (09:00 - 19:00) only.."
                  placement="right-end"
                >
                  <Grid container alignItems="center">
                    <FormLabel
                      sx={{
                        display: 'block',
                        fontWeight: '500',
                        fontSize: '1rem',
                        lineHeight: '1.5rem',
                        marginBottom: '0.5rem'
                      }}
                    >
                      Start Time
                    </FormLabel>
                    <MDDatePicker
                      value={formik.values.startDate || '09:00'}
                      onChange={(_, newValue) => {
                        formik.setFieldValue(
                          'startDate',
                          newValue ? newValue : ''
                        )
                        const [hour, minute] = (newValue || '09:00').split(':')
                        formik.setFieldValue('startHour', parseInt(hour, 10))
                        formik.setFieldValue(
                          'startMinute',
                          parseInt(minute, 10)
                        )
                      }}
                      options={{
                        noCalendar: true,
                        dateFormat: 'H:i',
                        enableTime: true,
                        time_24hr: true,
                        position: 'above',
                        minTime: '09:00',
                        maxTime: '19:00'
                      }}
                    />
                  </Grid>
                </MDTooltip>
              </Grid>
              <Grid item xs={6} sm={6}>
                <MDTooltip
                  title="Select a time between 9:00 AM and 7:00 PM (09:00 - 19:00) only.."
                  placement="right-end"
                >
                  <Grid container alignItems="center">
                    <FormLabel
                      sx={{
                        display: 'block',
                        fontWeight: '500',
                        fontSize: '1rem',
                        lineHeight: '1.5rem',
                        marginBottom: '0.5rem'
                      }}
                    >
                      End Time
                    </FormLabel>
                    <MDDatePicker
                      value={formik.values.endDate || '17:00'}
                      onChange={(_, newValue) => {
                        formik.setFieldValue(
                          'endDate',
                          newValue ? newValue : ''
                        )
                        const [hour, minute] = (newValue || '09:00').split(':')
                        formik.setFieldValue('endHour', parseInt(hour, 10))
                        formik.setFieldValue('endMinute', parseInt(minute, 10))
                      }}
                      options={{
                        noCalendar: true,
                        dateFormat: 'H:i',
                        enableTime: true,
                        time_24hr: true,
                        position: 'above',
                        minTime: '09:00',
                        maxTime: '19:00'
                      }}
                    />
                  </Grid>
                </MDTooltip>
              </Grid>
            </Grid>
          </MDTooltip>

          <Grid item xs={12}>
            <FormLabel
              sx={{
                display: 'block',
                fontWeight: '500',
                fontSize: '1rem',
                lineHeight: '1.5rem',
                color: 'rgb(17 24 39 / 1)',
                marginBottom: '0.5rem'
              }}
            >
              <MDTypography
                component="label"
                sx={{
                  display: 'block',
                  fontSize: '14px',
                  textAlign: 'justify'
                }}
              >
                Your Available Balance: ${availableFunds?.toFixed(2)}
              </MDTypography>
              {cost && (
                <MDTypography
                  component="label"
                  sx={{ display: 'block', fontSize: '14px', marginTop: '1rem' }}
                >
                  <Tooltip title="Keep in mind this is a conservative estimate of the budget this campaign would require as it's hard to predict how long calls will last.">
                    Allocating up to ${roundToTwo(cost)}. Remaining balance: $
                    {balance()}.
                  </Tooltip>
                  {balance() < 0 && (
                    <>
                      <MDAlert sx={{ m: 2 }} color="secondary">
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            Heads up! You might need more credit...or your
                            campaign will pause before completing!
                          </Grid>
                          <Grid item xs={12}>
                            <MDButton
                              color="secondary"
                              component={Link}
                              sx={{ marginTop: '2rem' }}
                              to="/account/settings#billing-info"
                            >
                              Add More Funds
                            </MDButton>
                          </Grid>
                        </Grid>
                      </MDAlert>
                    </>
                  )}
                </MDTypography>
              )}
            </FormLabel>
          </Grid>
          {showDescription && balance() > 0 && (
            <Grid item xs={12}>
              <MDTypography
                component="label"
                sx={{
                  display: 'block',
                  fontSize: '14px',
                  textAlign: 'justify'
                }}
              >
                Cald will dial until they answer or a voicemail is left. Your
                campaign will pause automatically if your balance runs low.
              </MDTypography>
            </Grid>
          )}
          <Grid item xs={12}>
            <MDButton
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                balance() <= 0 ||
                !formik.values.campaignName ||
                !formik.values.agent?._id ||
                !formik.values.list?._id ||
                (formik.values.agent.isEmailEnabled &&
                  !formik.values.agent.isEmailVerified)
              }
            >
              Launch Campaign
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  )
}

export default NewCampaignForm
