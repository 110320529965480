import HttpService from './http.service'

class StripeService {
  getConfig = async () => {
    const endpoint = `/api/stripe/config`
    return await HttpService.get(endpoint)
  }

  getStripePaymentIntent = async () => {
    const endpoint = `/api/stripe/create-payment-intent`
    return await HttpService.get(endpoint)
  }

  getPaymentIntentInfo = async (amount = '') => {
    const endpoint = '/api/stripe/payment-intent-details'
    return await HttpService.post(endpoint, { amount })
  }
}

export default new StripeService()
