import { useQuery } from 'react-query'
import voiceService from 'services/voice-service'

export function useUserVoices() {
  const { data, isLoading } = useQuery(['voices'], voiceService.getUserVoices, {
    select: data => {
      return data.voices.sort((a, b) => a.language.localeCompare(b.language))
    }
  })

  return {
    data,
    isLoading
  }
}
