import { Auth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

const Auth0WithNavigate = ({ children }) => {
  const navigate = useNavigate()

  const onRedirectCallback = appState => {
    // navigate(appState?.returnTo || window.location.hostname)
    navigate(appState?.returnTo || window.location.pathname)
  }
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: 'this is unique identifier',
        scope: 'openid profile email',
        redirect_uri: window.location.origin
      }}
      // cookieDomain=".staging-dot-my-cald.ue.r.appspot.com"
      // cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0WithNavigate
