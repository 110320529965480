import { publicFetch } from 'utils/publicFetch'
import HttpService from './http.service'

export const login = async userData => {
  const loginUserState = await publicFetch.post(`/api/auth/login`, userData)
  return loginUserState
}

export const signup = async userData => {
  const registerUserState = await publicFetch.post('/api/auth/signup', userData)
  return registerUserState
}

export const checkPromo = async payload => {
  return await publicFetch.post('/api/auth/promo', payload)
}

export const changepassword = async userData => {
  const changePasswordState = await HttpService.patch(
    '/api/auth/change-password',
    userData
  )
  return changePasswordState
}

export const resetPassword = async credentials => {
  const resetPassword = '/api/auth/password-reset'
  return await HttpService.post(resetPassword, credentials)
}

export const sendOtp = async payload => {
  return await publicFetch.post(`/api/auth/send-otp`, {
    phoneNumber: payload
  })
}

export const resendOtp = async payload => {
  return await publicFetch.post(`/api/auth/resend-otp`, {
    sessionId: payload
  })
}

export const storeReferralCode = async data => {
  const payload = {
    email: data.email,
    referralCode: data.code
  }
  return await HttpService.post(`/api/auth/referral`, payload)
}

export const getSubDomain = async id => {
  return await HttpService.get(`/api/manage/metadata?id=${id}`)
}

export const patchSubDomain = async (payload, id) => {
  const formData = new FormData()
  formData.append('logo', payload.logoImage)
  formData.append('domain', payload.domainName)
  formData.append('appName', payload.appName)
  formData.append('id', id)
  // const updatePayload = {
  //   formData,
  // }
  return await HttpService.patch(`/api/manage/metadata`, formData)
}
