import { LoadingSpinner } from 'components/LoadingSpinner'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { DataTable } from 'components/Tables/material'
import { Fragment, useContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import userService from 'services/user-service'
import Cookies from 'js-cookie'
import { notifyError } from 'components/Messages'
import { AuthContext } from 'context'
import { useNavigate } from 'react-router-dom'
import { ROLES } from 'routes'
const Users = () => {
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  if (authContext.authState.userInfo.role !== ROLES.ADMIN) {
    navigate('/', { replace: true })
  }
  const { data, isLoading } = useQuery('users', userService.getUsers)
  const columns = useMemo(
    () => [
      {
        accessorFn: row =>
          `${row.user_metadata?.first_name ?? ''} ${
            row.user_metadata?.last_name ?? ''
          } `,
        header: 'Name'
      },
      {
        accessorKey: 'email',
        enableClickToCopy: true,
        header: 'Email'
      },
      {
        accessorKey: 'email_verified',
        header: 'Email Verified',
        Cell: ({ row }) => (row.original.email_verified ? 'True' : 'False')
      },
      {
        accessorKey: 'user_id',
        header: 'User ID'
      },
      {
        accessorKey: 'logins_count',
        header: 'Login Count'
      },
      {
        header: 'Impersonate',
        Cell: ({ row }) => {
          return (
            <MDBox>
              <MDButton
                color="success"
                variant="outlined"
                onClick={() => handleImpersonation(row.original.email)}
              >
                Impersonate
              </MDButton>
            </MDBox>
          )
        }
      }
    ],
    []
  )

  const handleImpersonation = email => {
    const cookies = Cookies.get('overrideEmail')
    if (!cookies) {
      Cookies.set('overrideEmail', email, {
        expires: 1,
        path: '/',
        sameSite: 'None',
        secure: true ,
        domain: window.location.hostname
      })
      const newTab = window.open('/', '_blank')
      if (newTab) {
        newTab.focus()
      } else {
        console.error(
          'Failed to open a new tab. Please check your browser settings.'
        )
      }
    } else {
      notifyError('You are already impersonating a user')
    }
  }
  if (isLoading) {
    return <LoadingSpinner />
  }
  let tableData = data.users.users.filter(
    user => user.email !== authContext.authState.userInfo.email
  )
  return (
    <Fragment>
      <DataTable table={{ columns, rows: tableData ?? [] }} canSearch />
    </Fragment>
  )
}

export default Users
