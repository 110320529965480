import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import knowledgeService from 'services/knowledge-service'
import { getTimeDifferenceString } from 'utils/utilsFunctions'

export function useKnowledgeBase() {
  const { id } = useParams()
  if (!id)
    throw new Error('Knowledge Base Id is missing in useKnowledgeBase Hook')
  const { data, isLoading } = useQuery(
    ['knowledge-base', id],
    knowledgeService.getKnowledgeBase(id)
  )
  return {
    data,
    isLoading
  }
}

export function useKnowledgeBases() {
    const { data, isLoading: isKnowledgeBaseLoading } = useQuery(
    ['knowledge-base'],
    knowledgeService.getKnowledgeBases,
    {
      select: data =>
        data.knowledgeBases.map(knowledgebase => ({
          id: knowledgebase._id,
          name: knowledgebase.name,
          lastEdited: getTimeDifferenceString(knowledgebase.updatedAt)
        }))
    }
  )

  return {
    data,
    isKnowledgeBaseLoading
  }
}
