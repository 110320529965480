// VariablesEditor.js
import React from 'react'
import { TextField } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'

import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDModal from 'components/MDModal'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  newValue: yup
    .string('Enter Variable Name')
    .min(2, 'Minimum 3 characters')
    .required('Variable Name is required')
})

const VariablesEditor = ({ variable = null, open, onClose, onUpdate }) => {
  const formik = useFormik({
    initialValues: {
      newValue: '',
      defaultValue: variable?.default || ''
    },
    validationSchema,
    onSubmit: () => {
      console.log('onSubmit formik')
    }
  })
  const handleSave = () => {
    console.log('onSubmit handleSubmit')

    if (variable) {
      onUpdate({
        ...variable,
        default: formik.values.defaultValue
      })
    } else {
      onUpdate({
        variable: formik.values.newValue,
        default: formik.values.defaultValue
      })
    }
    handleClose()
  }
  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  const renderReadOnly = () => {
    return (
      <MDModal
        open={open}
        sx={{ px: 4, py: 2, width: { xs: '90%', md: '40%' } }}
      >
        <MDTypography variant="h5" mb={1}>
          {`Viewing ${variable.variable} variable`}
        </MDTypography>
        <MDTypography variant="body2" mb={1}>
          This is a system variable and it cannot be modified. Currently, if you
          type &#123;
          {variable.variable}&#125; anywhere in your script, email, voicemail,
          or text message templates, it will be replaced with a specific
          prospectName you provided as part of the context (via API or as part
          of the contacts upload).
        </MDTypography>

        <MDTypography variant="body2" mb={1}>
          Also, please note that there is no default value for this variable".
        </MDTypography>
        <MDButton
          variant="contained"
          color="primary"
          onClick={() => {
            onClose()
          }}
        >
          <Check />
        </MDButton>
      </MDModal>
    )
  }

  //   if (variable?.variable === 'prospectName') {
  //     return renderReadOnly()
  //   }

  return (
    <MDModal
      open={open}
      onClose={handleClose}
      sx={{ px: 4, py: 2, width: { xs: '90%', md: '40%' } }}
    >
      <MDTypography variant="h4" mb={1}>
        {variable
          ? `Add a default value for ${variable.variable} variable`
          : 'Add new variable'}
      </MDTypography>
      {!variable && (
        <TextField
          size="medium"
          name="newValue"
          fullWidth
          onChange={formik.handleChange}
          placeholder="Enter a new variable name"
          error={formik.touched.newValue && Boolean(formik.errors.newValue)}
          helperText={formik.touched.newValue && formik.errors.newValue}
        />
      )}
      <MDTypography variant="body2" mb={1} sx={{ fontStyle: 'italic' }}>
        Currently, if you type &#123;
        {variable ? variable.variable : formik.values.newValue}&#125; anywhere
        your script, it will be replaced with a runtime value that will be
        passed at the time a call is made. Therefore it is important now to
        provide the value for this variable as part of params during an API call
        or as part of the contact's information when they are upload).
      </MDTypography>
      <TextField
        size="medium"
        name="defaultValue"
        value={formik.values.defaultValue}
        fullWidth
        multiline
        minRows={1}
        onChange={formik.handleChange}
        placeholder="Default value for the variable"
      />

      <MDTypography variant="body2" mb={1}>
        This default value will be used as a fail safe option, in case the
        actual value is not available at the time of calling, so choose it
        carefully. To have the best result, choose a value that makes sense in
        the context of your script, email, voicemail, or text message templates.
      </MDTypography>

      <MDButton
        variant="contained"
        color="primary"
        onClick={() => {
          console.log('onSubmit')
          handleSave()
        }}
        sx={{ margin: '0.5rem 0.5rem' }}
      >
        <Check />
      </MDButton>
      <MDButton variant="contained" color="secondary" onClick={handleClose}>
        <Clear />
      </MDButton>
    </MDModal>
  )
}

export default VariablesEditor
