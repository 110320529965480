import React, { useMemo, useCallback } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import AddVoice from './add'
import AlertDialog from 'components/AlertDialog'
import { useMaterialUIController } from 'context'
import { useMutation, useQueryClient } from 'react-query'
import { notifyError, notifySuccess } from 'components/Messages'
import { DataTable } from 'components/Tables/material'
import { Stack } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { LoadingSpinner } from 'components/LoadingSpinner'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import voiceService from 'services/voice-service'
import { useUserVoices } from 'utils/customHooks/useUserVoices'
import MDBox from 'components/MDBox'

function VoicesPage() {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller
  const queryClient = useQueryClient()

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [deleteItem, setDeleteItem] = React.useState(null)

  const { data, isLoading, isError } = useUserVoices()

  const ActionCell = ({ params, darkMode }) => {
    const isDefaultVoice = !params.row.original.isCustom

    return (
      <Stack sx={{ flexDirection: 'row', gap: 4 }}>
        <Tooltip
          title={isDefaultVoice ? 'Default voice cannot be deleted' : ''}
        >
          <MDBox>
            <IconButton
              disabled={isDefaultVoice}
              onClick={e => handleDelete(e, params.row.original)}
            >
              <Delete color={darkMode ? 'white' : ''} />
            </IconButton>
          </MDBox>
        </Tooltip>
      </Stack>
    )
  }
  const handleDelete = useCallback((e, row) => {
    e.stopPropagation()
    setDeleteItem(row)
    setShowDeleteDialog(true)
  }, [])

  const refresh = () => {
    queryClient.invalidateQueries(['voices'])
  }
  const deleteVoiceMutation = useMutation(
    voiceId => voiceService.deleteVoice({ id: voiceId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['voices'])
        notifySuccess('Voice deleted')
      },
      onError: error => notifyError(error?.error)
    }
  )

  const columns = useMemo(
    () => [
      { accessorKey: 'voice', header: 'Voice ID' },
      { accessorKey: 'voiceName', header: 'Name' },
      { accessorKey: 'language', header: 'Language' },
      {
        accessorKey: 'action',
        header: 'Action',
        Cell: params => <ActionCell params={params} darkMode={darkMode} />
      }
    ],
    []
  )
  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return <div>Error loading your voices</div>
  }

  return (
    <>
      <AddVoice onClose={refresh} />
      <DataTable table={{ columns, rows: data ?? [] }} canSearch />
      <AlertDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        title="Just Checking In!"
        content={renderDeleteModalContent(deleteItem)}
      >
        <MDButton onClick={() => setShowDeleteDialog(false)}>Cancel</MDButton>
        <MDButton
          color="error"
          onClick={() => {
            deleteVoiceMutation.mutate(deleteItem?.voice)
            setShowDeleteDialog(false)
          }}
        >
          Delete
        </MDButton>
      </AlertDialog>
    </>
  )
}
const renderDeleteModalContent = deleteItem => (
  <div>
    About to release {deleteItem?.voiceName} ({deleteItem?.language}). Proceed?
    <br />
    <br />
    <MDTypography variant="cation" component="div">
      This action cannot be undone. However, if a campaign is using this voice,
      it will continue to be available until the campaign is stopped or
      finished.
    </MDTypography>
    {/* <strong>Active Campaigns:</strong> 0 */}
  </div>
)

export default VoicesPage
