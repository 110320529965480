import React, { useState, useMemo, useCallback, Fragment } from 'react'
import { IconButton, Stack } from '@mui/material'
import { Delete, Description } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import MDButton from 'components/MDButton'
import CreateKnowledgeBaseModal from '../CreateKnowledgeBaseModal'
import knowledgeService from 'services/knowledge-service'
import AlertDialog from 'components/AlertDialog'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useMaterialUIController } from 'context'
import { DataTable } from 'components/Tables/material'

function KnowledgeTable({ data }) {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const deleteKnowledgeBase = useMutation(
    knowledgeService.deleteKnowledgeBase,
    {
      onSuccess: () => queryClient.invalidateQueries(['knowledge-base'])
    }
  )

  const cloneKnowledgeBase = useMutation(knowledgeService.addKnowledgeBase, {
    onSuccess: () => queryClient.invalidateQueries(['knowledge-base'])
  })

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [knowledgeBaseId, setKnowledgeBaseId] = useState(null)

  const handleDelete = useCallback((e, id) => {
    e.stopPropagation()
    setKnowledgeBaseId(id)
    setShowDeleteDialog(true)
  }, [])

  const handleDuplicate = useCallback(
    (e, name) => {
      e.stopPropagation()
      cloneKnowledgeBase.mutate({
        name: `${name} - Copy`,
        flashcards: []
      })
    },
    [cloneKnowledgeBase]
  )

  const columns = useMemo(
    () => [
      { accessorKey: 'name', header: 'Name', width: 400 },
      { accessorKey: 'lastEdited', header: 'Last Edited', flex: 1 },
      {
        accessorKey: 'action',
        header: 'Action',
        flex: 1,
        Cell: params => <ActionCell params={params} darkMode={darkMode} />
      }
    ],
    [darkMode]
  )

  const ActionCell = ({ params, darkMode }) => (
    <Stack sx={{ flexDirection: 'row', gap: 4 }}>
      <IconButton
        onClick={e => {
          handleDuplicate(e, params.row.original.name)
        }}
      >
        <Description color={darkMode ? 'white' : ''} />
      </IconButton>
      <IconButton onClick={e => handleDelete(e, params.row.original.id)}>
        <Delete color={darkMode ? 'white' : ''} />
      </IconButton>
    </Stack>
  )

  const tableData = {
    columns,
    rows: data
  }

  return (
    <Fragment>
      <DataTable
        table={tableData}
        canSearch
        onRowClick={row =>
          navigate(`/knowledgebases/${row.original.id}`, { replace: true })
        }
        onNewItem={() => setShowAddDialog(true)}
        newItemLabel="New Knowledge Base"
      />
      <CreateKnowledgeBaseModal
        open={showAddDialog}
        onClose={() => setShowAddDialog(false)}
      />
      <AlertDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        title="Just Checking In!"
        content={renderDeleteModalContent()}
      >
        <MDButton onClick={() => setShowDeleteDialog(false)}>Cancel</MDButton>
        <MDButton
          color="error"
          onClick={() => {
            deleteKnowledgeBase.mutate(knowledgeBaseId)
            setShowDeleteDialog(false)
          }}
        >
          Delete
        </MDButton>
      </AlertDialog>
    </Fragment>
  )
}

const renderDeleteModalContent = () => (
  <MDBox>
    <MDTypography variant="body2">
      Hold on! Deleting this knowledge base will remove it for good. Are you
      sure you want to say goodbye to this knowledge base?
    </MDTypography>
  </MDBox>
)

export default KnowledgeTable
