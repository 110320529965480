import React, { useState, useEffect, useCallback } from 'react'

// react-router components
import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom'

// @mui material components
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

// React Toastify
import { toast, ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

// Material Dashboard 2 PRO React themes
import theme from 'assets/theme'
import themeDark from 'assets/theme-dark'
import { useMaterialUIController, setMiniSidenav } from 'context'

import routes from 'routes'
import {
  SettingsPage,
  IntegrationsPage,
  CampaignsPage,
  CampaignDetails,
  CallDetails,
  AgentsPage,
  AgentDetails,
  AgentTestRecordings,
  KnowledgeBaseList,
  KnowledgebaseDetails,
  ContactGroupsPage,
  ContactGroupDetails,
  VoicesPage,
  PhoneNumbersPage
} from 'pages'

import { setupAxiosInterceptors } from 'services/interceptor'

import UIConfigurator from 'components/UIConfigurator'
import { notifyWarning } from 'components/Messages'
import { Layout } from 'components/Layout'
import Sidenav from 'components/Sidenav'
import { useAuth0 } from '@auth0/auth0-react'
import { useUser } from 'context'
import { Box } from '@mui/material'
import { LoadingSpinner } from 'components/LoadingSpinner'
import PreAuth from 'PreAuth'
import userService from 'services/user-service'
import { useReferralCode } from 'utils/customHooks/useReferralCode'
import Users from 'pages/users'
import { useQuery } from 'react-query'
import { getSubDomain } from 'services/authentication.service'
import { useAppInfo } from 'context'

export default function App() {
  const { pathname } = useLocation()
  const [controller, dispatch] = useMaterialUIController()
  const {
    miniSidenav,
    sidenavColor,
    transparentSidenav,
    darkMode,
    whiteSidenav,
    brandDark,
    brandWhite
  } = controller
  const [onMouseEnter, setOnMouseEnter] = useState(false)
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0()
  const { setAuthState } = useUser()
  const urlParams = new URLSearchParams(window.location.search)
  const [searchParams] = useSearchParams()
  const promocode = searchParams.get('promocode')
  const viaParam = urlParams.get('via')
  const { setAppInfo, appInfo } = useAppInfo()
  console.log(appInfo, 'appInfoappInfo')
  const { data: userData, isLoading: userInfoLoading } = useQuery(
    'user',
    userService.getUser,
    {
      select: data => data.data
    }
  )
  useReferralCode()

  useEffect(() => {
    if (viaParam) {
      sessionStorage.setItem('affiliateCode', viaParam)
    }
    if (promocode) {
      sessionStorage.setItem('promoCode', promocode)
    }
  }, [promocode, viaParam])

  const onUnAuthenticate = () => {
    try {
      notifyWarning('Session expired. Logging out...')

      setTimeout(async () => {
        logout()
      }, 2000)
    } catch (e) {
      toast.error(e.message)
    }
  }

  setupAxiosInterceptors(getAccessTokenSilently, onUnAuthenticate)

  const fetchUserInfo = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently()
      setAuthState({
        userInfo: {
          email: userData?.email,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          id: userData?.id,
          affiliateCode: userData?.affiliateCode,
          role: userData?.role ?? null
        },
        token
      })
    } catch (error) {
      console.error('Error fetching user info:', error)
    }
  }, [getAccessTokenSilently, setAuthState, userData])

  useEffect(() => {
    if (user && !userInfoLoading) {
      fetchUserInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getAccessTokenSilently, userData])

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [pathname])

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false)
      setOnMouseEnter(true)
    }
  }

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true)
      setOnMouseEnter(false)
    }
  }

  // const auth = useAuth0()

  // useEffect(() => {
  //   const fetchSubDomainAndRedirect = async () => {
  //     try {
  //       if (auth.error) {
  //         console.log(auth.error)
  //         auth.logout()
  //         return
  //       }

  //       const idToken = await auth.getIdTokenClaims()

  //       if (idToken) {
  //         const res = await getSubDomain(idToken.sub)
  //         const subDomain =
  //           res.users.user_metadata?.domain ?? idToken['nickname']
  //         const appName = res.users.user_metadata?.appName ?? subDomain
  //         const logoUrl = res.users.user_metadata?.logo ?? ''
  //         const newUrl = `https://${subDomain}.staging-dot-my-cald.ue.r.appspot.com`
  //         setAppInfo(prev => ({
  //           ...prev,
  //           domain: subDomain,
  //           auth0Id: idToken.sub,
  //           appName,
  //           logoUrl
  //         }))

  //         if (!window.location.href.includes(newUrl)) {
  //           window.location.href = newUrl
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Error fetching subdomain or getting ID token:', error)
  //     }
  //   }

  //   fetchSubDomainAndRedirect()
  // }, [auth, user, setAppInfo])

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <LoadingSpinner />
      </Box>
    )

  if (!isAuthenticated) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <ToastContainer style={{ fontSize: '16px' }} />
        <CssBaseline />
        <PreAuth />
      </ThemeProvider>
    )
  } else {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <ToastContainer style={{ fontSize: '16px' }} />
        <Sidenav
          color={sidenavColor}
          brand={
            (transparentSidenav && !darkMode) || whiteSidenav
              ? brandDark
              : brandWhite
          }
          brandName={'My Cald'}
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
        <UIConfigurator />
        <Layout>
          <Routes>
            <Route path="/knowledgebase" element={<KnowledgeBaseList />} />
            <Route
              path="/knowledgebases/:id"
              element={<KnowledgebaseDetails />}
            />
            <Route path="/contactgroups" element={<ContactGroupsPage />} />
            <Route path="/groupdetails/:id" element={<ContactGroupDetails />} />
            <Route path="/campaigns" element={<CampaignsPage />} />
            <Route path="/phoneNumbers" element={<PhoneNumbersPage />} />
            <Route path="/voices" element={<VoicesPage />} />
            <Route path="/campaigns/:id" element={<CampaignDetails />} />
            <Route path="/calls/:id" element={<CallDetails />} />
            <Route path="/agents/:agentId" element={<AgentDetails />} />
            <Route path="/users" element={<Users />} />
            <Route
              path="/agents/:agentId/tests"
              element={<AgentTestRecordings />}
            />
            <Route path="/account/settings" element={<SettingsPage />} />
            <Route
              path="/account/integrations"
              element={<IntegrationsPage />}
            />
            <Route index path="*" element={<AgentsPage />} />
          </Routes>
        </Layout>
      </ThemeProvider>
    )
  }
}
