import { ArrowBackIos } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { CheckCircle, FileUpload } from '@mui/icons-material'
import React from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import { useState } from 'react'

function AddKnowledgeBaseModal({ open, onClose, onSubmit, formik }) {
  const [activeStep, setActiveStep] = useState(0)

  const handleNextStep = () => {
    if (activeStep === 0) {
      if (formik.values.type === 'flashcard') {
        setActiveStep(activeStep + 1)
      } else {
        setActiveStep(activeStep + 2)
      }
    } else {
      // console.log("formik.values:", formik.values);
      onSubmit()
      setActiveStep(0)
    }
  }

  const handleCreateNewStep = async () => {
    await formik.validateForm()
    // console.log("formik.isValid:", formik.isValid);
    if (formik.isValid) {
      handleNextStep()
    } else {
      formik.setTouched({ name: true, content: true }) // Optionally, mark all fields as touched to show validation errors
    }
  }

  const handlePrevStep = () => {
    if (formik.values.type === 'flashcard') {
      setActiveStep(activeStep - 1)
    } else {
      setActiveStep(activeStep - 2)
    }
  }

  const steps = [
    <AddKnowledge key={1} formik={formik} handleNextStep={handleNextStep} />,
    <CreateFlashCard
      key={2}
      formik={formik}
      handleNextStep={() => {
        handleCreateNewStep()
      }}
      handlePrevStep={handlePrevStep}
    />,
    <AttachedDocument
      key={3}
      formik={formik}
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
    />
  ]
  return (
    <MDModal
      disableAutoFocus
      open={open}
      onClose={onClose}
      sx={{
        padding: '1.5rem',
        borderColor: '#FAFAFA',
        width: '100%',
        maxWidth: '50rem',
        maxHeight: '85%',
        overflow: 'auto'
      }}
    >
      <MDBox component="form">{steps[activeStep]}</MDBox>
    </MDModal>
  )
}

function FileDropZone({ formik }) {
  const [fileName, setFileName] = useState(null)

  const handleFile = async file => {
    setFileName(file.name)
    const arrayBuffer = await file.arrayBuffer()
    const uint8Array = new Uint8Array(arrayBuffer)
    let binaryString = ''
    for (let i = 0; i < uint8Array.length; i++) {
      binaryString += String.fromCharCode(uint8Array[i])
    }
    const base64 = window.btoa(binaryString)
    formik.setFieldValue('content', base64)
    formik.setFieldValue('name', file.name)
    formik.setFieldValue('type', 'file')
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => {
      handleFile(acceptedFiles[0])
    },
    noKeyboard: true,
    multiple: false,
    accept: {}
  })

  return (
    <Box
      {...getRootProps()}
      name="file"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '250px',
        maxHeight: '100%',
        borderStyle: 'dashed',
        borderColor: '#c5bebe',
        backgroundColor: '#f8f3f3',
        borderWidth: '3px',
        borderRadius: 1,
        mt: 7,
        outline: 'none'
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <Typography variant="body1">Drop the file here...</Typography>
      ) : (
        <React.Fragment>
          {fileName ? (
            <Stack spacing={1} alignItems="center">
              <CheckCircle sx={{ fontSize: 48 }} />
              <Typography variant="body1">{fileName}</Typography>
              <Typography variant="bdoy2">
                File Successfully Uploaded
              </Typography>
            </Stack>
          ) : (
            <Stack alignItems="center" spacing={1}>
              <FileUpload />
              <Typography
                // onClick={open} // Commenting this line out prevents a double upload popup from appearing
                variant="body1"
                sx={{
                  textDecoration: 'underline',
                  fontWeight: 500,
                  cursor: 'pointer'
                }}
              >
                Upload a file
              </Typography>
              <Typography variant="body2">or Drag and Drop it here</Typography>
            </Stack>
          )}
        </React.Fragment>
      )}
    </Box>
  )
}

const CreateFlashCard = ({
  formik,
  handleNextStep,
  handlePrevStep,
  isLoading
}) => {
  return (
    <Box width="100%">
      <Stack
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton onClick={handlePrevStep}>
          <ArrowBackIos />
        </IconButton>
        <Box flex={1}>
          <Typography variant="h5" textAlign="center">
            Create A Flash Card
          </Typography>
        </Box>
      </Stack>
      <Box p={2}>
        <Typography variant="body2">Question/Objection</Typography>
        <TextField
          variant="outlined"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          sx={{ width: '100%', marginTop: 2 }}
          helperText={
            formik.touched.name &&
            Boolean(formik.errors.name) &&
            formik.errors.name
          }
          placeholder="eg. What's the name of your company"
        />
      </Box>
      <Box p={2}>
        <Typography variant="body2">Answer/Response</Typography>
        <TextField
          name="content"
          variant="outlined"
          type="textarea"
          value={formik.values.content}
          onChange={formik.handleChange}
          sx={{ width: '100%', marginTop: 2 }}
          placeholder="eg. Tesla's Super-Computers"
          multiline
          rows={4}
          helperText={
            formik.touched.content &&
            Boolean(formik.errors.content) &&
            formik.errors.content
          }
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} p={3} pt={1}>
        <Button
          variant="contained"
          sx={{
            color: 'whitesmoke'
          }}
          onClick={handleNextStep}
          disabled={formik.isSubmitting}
        >
          {isLoading ? 'Creating...' : 'Create'}
        </Button>
      </Box>
    </Box>
  )
}

const AttachedDocument = ({ formik, handleNextStep, handlePrevStep }) => {
  return (
    <Box width="100%">
      <Stack
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton onClick={handlePrevStep}>
          <ArrowBackIos />
        </IconButton>
        <Box flex={1}>
          <Typography variant="h3" textAlign="center" color="#3B81f6">
            Upload Document
          </Typography>
          <Typography variant="body2" textAlign="center">
            Upload documents and Cald will use them during live AI-powered calls
          </Typography>
        </Box>
      </Stack>

      <FileDropZone formik={formik} />
      {false && ( //todo: add error message
        <Typography color="red">Error</Typography>
      )}

      <Box
        sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}
        p={3}
        pt={1}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#808184',
            width: '150px',
            color: 'whitesmoke'
          }}
          onClick={handleNextStep}
        >
          Next
        </Button>
      </Box>
    </Box>
  )
}

const AddKnowledge = ({ formik, handleNextStep }) => {
  return (
    <MDBox
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <FormControl sx={{ m: 3 }} variant="standard">
        <Stack sx={{ textAlign: 'center' }}>
          <Typography variant="h5" id="demo-error-radios">
            Add To Knowledge Base
          </Typography>
          <Typography variant="body2">
            Do you want AI magically turn documents or call recordings into FAQ
            flashcards or add them manually?
          </Typography>
        </Stack>
        <Box
          sx={{
            width: '100%',
            border: '1px solid rgb(191 219 254 / .5)',
            borderRadius: 2
          }}
          mt={2}
        >
          <RadioGroup
            aria-labelledby="knowledge-type-radios"
            value={formik.values.type}
            name="type"
            onChange={formik.handleChange}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Box
              width="100%"
              borderBottom={1}
              p={2}
              sx={{
                borderColor: 'rgb(191 219 254 / .5)',
                backgroundColor:
                  formik.values.type === 'flashcard'
                    ? 'rgb(191 219 254 / .1)'
                    : ''
              }}
            >
              <FormControlLabel
                value="flashcard"
                control={<Radio />}
                label="Manually Add FAQ Flashcard"
              />
            </Box>
            <Box
              p={2}
              sx={{
                backgroundColor:
                  formik.values.type === 'file' ? 'rgb(191 219 254 / .1)' : ''
              }}
            >
              <FormControlLabel
                value="file"
                control={<Radio />}
                label="Upload Documents"
              />
            </Box>
          </RadioGroup>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Button
            variant="contained"
            sx={{
              maxWidth: '10rem',
              marginTop: 3,
              color: 'whitesmoke'
            }}
            onClick={handleNextStep}
          >
            Select
          </Button>
        </Box>
      </FormControl>
    </MDBox>
  )
}

export default AddKnowledgeBaseModal
