import React, { useState } from 'react'
import { Grid, TextField } from '@mui/material'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import voiceService from 'services/voice-service'
import MDModal from 'components/MDModal'
import MDInput from 'components/MDInput'
import { notifySuccess } from 'components/Messages'
import { notifyError } from 'components/Messages'

function AddVoice({ onClose }) {
  const [showAddNumberDialog, setShowAddNumberDialog] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [voiceName, setVoiceName] = useState('')
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [uploadedFileName, setUploadedFileName] = useState('')

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0])
    setUploadSuccess(false)
    setUploadedFileName('')
  }

  const handleVoiceNameChange = event => {
    setVoiceName(event.target.value)
  }

  const handleFileUpload = async () => {
    if (!selectedFile) {
      console.error('No file selected')
      return
    }

    setIsUploading(true)
    const formData = new FormData()
    formData.append('file', selectedFile)
    formData.append('name', voiceName)

    try {
      await voiceService.cloneVoice(formData)
      notifySuccess('Voice uploaded successfully')
      setUploadedFileName(selectedFile.name)
      setUploadSuccess(true)
      setShowAddNumberDialog(false)
    } catch (error) {
      notifyError(error.error)
      setUploadSuccess(false)
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <MDBox
      sx={{
        paddingBottom: '1rem',
        borderRadius: '8px',
        borderColor: '#FAFAFA',
        width: '100%',
        overflow: 'auto'
      }}
    >
      <MDButton color="success" onClick={() => setShowAddNumberDialog(true)}>
        Add Custom Voice
      </MDButton>
      <MDModal
        open={showAddNumberDialog}
        title="Add New Phone Number"
        onClose={() => {
          onClose()
          setShowAddNumberDialog(false)
        }}
      >
        <MDBox sx={{ minWidth: '600px', margin: '3rem' }}>
          <MDTypography
            variant="h6"
            component="div"
            sx={{ marginBottom: '2rem' }}
          >
            Clone Existing Voice
          </MDTypography>
          <MDTypography
            variant="body2"
            component="div"
            sx={{ marginBottom: '2rem' }}
          >
            You can clone an existing voice (for example your own voice) by
            uploading a new audio file and giving it a new name. The new voice
            will be available for use in your application. Note that voice
            cloning is currently only available in English.
          </MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDInput
                type="file"
                onChange={handleFileChange}
                disabled={isUploading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ marginTop: '1rem' }}
                fullWidth
                label="Voice Name"
                placeholder=""
                onChange={handleVoiceNameChange}
                value={voiceName}
              />
            </Grid>
            <Grid item xs={12}>
              <MDButton
                color="success"
                onClick={handleFileUpload}
                disabled={isUploading}
              >
                Upload
              </MDButton>
              {isUploading && <MDTypography>Loading...</MDTypography>}
              {uploadSuccess && (
                <MDTypography>Uploaded File: {uploadedFileName}</MDTypography>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </MDModal>
    </MDBox>
  )
}

export default AddVoice
