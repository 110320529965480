import { IconButton, Card, Grid } from '@mui/material'
import { Delete } from '@mui/icons-material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { notifyError, notifySuccess } from 'components/Messages'
import { DataTable } from 'components/Tables'
import { FormField } from 'pages/account/components'
import { useState } from 'react'
import { useMaterialUIController } from 'context'
import { useMutation } from 'react-query'
import userService from 'services/user-service'
import { useUser } from 'context'
import { useEffect } from 'react'
import HttpService from 'services/http.service'
import randomString from 'crypto-random-string'

const ClientAPI = () => {
  const { authState } = useUser()
  const [keys, setKeys] = useState([])
  const [controller] = useMaterialUIController()
  const { darkMode } = controller

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await HttpService.get('/api/me/settings')
      setKeys(data?.apiKeys || [])
    }
    fetchSettings()
  }, [])

  const { mutate, isLoading } = useMutation(
    data => userService.updateUser(authState?.userInfo?.id, data),
    {
      onSuccess: data => {
        if (data?.userInfo?.apiKeys) {
          setKeys(data?.userInfo?.apiKeys)
        }
        notifySuccess('Update successful')
      },
      onError: () => {
        notifyError('Update failed. Please try again later')
      }
    }
  )

  const handleGenerate = name => {
    if (!name) {
      notifyError('Please enter a name for the key')
      return
    }
    if (keys.some(key => key.name === name)) {
      notifyError('A key with this name already exists')
      return
    }
    // Generate a new key
    const newKey = {
      name: name,
      key: 'caldLive' + randomString({ length: 32 }),
      created: new Date().toLocaleDateString('en-US')
    }

    // Add the new key to the keys state
    const newKeys = [...keys, newKey]
    setKeys(newKeys)
    mutate({ apiKeys: newKeys })
  }

  const handleDelete = keyToDelete => {
    // Remove the key from the keys state
    const newKeys = keys.filter(key => key.key !== keyToDelete)
    setKeys(newKeys)
    mutate({ apiKeys: newKeys })
  }

  const tableData = {
    columns: [
      { Header: 'name', accessor: 'name', width: '20%' },
      { Header: 'key', accessor: 'key', width: '25%' },
      { Header: 'created', accessor: 'created' },
      {
        Header: 'actions',
        accessor: 'actions',
        width: '7%',
        Cell: ({ row }) => (
          <IconButton onClick={e => handleDelete(row.original.key)}>
            <Delete color={darkMode ? 'white' : ''} />
          </IconButton>
        )
      }
    ],
    rows: keys
  }
  return (
    <MDBox>
      <Card id="settings" sx={{ overflow: 'visible' }}>
        <MDBox
          component="form"
          p={3}
          onSubmit={e => {
            e.preventDefault()
            const name = e.target.elements.name.value
            handleGenerate(name)
          }}
        >
          <MDTypography variant="h5">New API Key</MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField label="Name" name="name" />
            </Grid>
            <Grid item xs={12}>
              <MDButton variant="gradient" color="primary" type="submit">
                Generate
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>

      <MDBox mt={3}>
        <Card>
          <MDTypography variant="h5" fontWeight="medium" p={3}>
            API Documentation
          </MDTypography>
          <MDTypography variant="body1" px={3} pb={3}>
            <a
              href="https://docs.cald.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to view the API documentation
            </a>
          </MDTypography>
        </Card>
      </MDBox>

      <MDBox mt={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Your API Keys
            </MDTypography>
          </MDBox>
          <DataTable table={tableData} />
        </Card>
      </MDBox>
    </MDBox>
  )
}

export default ClientAPI
