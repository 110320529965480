import {
  Grid,
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem
} from '@mui/material'
import { DataTable } from 'components/Tables/material'
import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useQuery, QueryClient } from 'react-query'
import MDBox from 'components/MDBox'
import ArticleIcon from '@mui/icons-material/Article'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import { TranscriptModal } from 'components/Modals'
import ReviewModal from './ReviewModal'
import { useNavigate } from 'react-router-dom'
import { useAudioPlayer } from 'utils/customHooks/useAudioPlayer'
import CallService from 'services/call-service'
import StatsSection from 'components/StatsSection'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import MDButton from 'components/MDButton'
import campaignService from 'services/campaign-service'
import agentService from 'services/agent-service'
import userService from 'services/user-service'
import { notifyError, notifySuccess } from 'components/Messages'

// import TablePagination from '@mui/material/TablePagination'

const TableCell = React.memo(({ onClick, children }) => (
  <MDBox
    sx={{
      cursor: 'pointer',
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      color: 'inherit'
    }}
    onClick={onClick}
  >
    {children}
  </MDBox>
))

const PlayPauseCell = React.memo(({ id, location, handlePlayPause }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying) // Toggle play state
    handlePlayPause(id, location) // Invoke the global play/pause handler
  }

  return (
    <MDBox
      sx={{
        cursor: 'pointer',
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        color: 'inherit'
      }}
      onClick={togglePlayPause}
    >
      {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      <Typography variant="body2">{isPlaying ? 'Pause' : 'Play'}</Typography>
    </MDBox>
  )
})

const queryClient = new QueryClient()

const CallsList = ({ type, id, summary = false }) => {
  let excelName
  const exportToExcel = async excelData => {
    if (type === 'campaign') {
      excelName = campaign.campaign.campaignName
    } else if (type === 'agent') {
      excelName = agent.agentName
    } else {
      excelName = user.data.firstName
    }

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    if (excelData.length !== 0) {
      excelData.forEach(input => {
        if (input.transcripts) {
          console.log(input.transcripts)
          input.transcripts = input.transcripts
            .map(transcript => {
              return `${transcript.role}: ${transcript.content}`
            })
            .join('\n')
        }
      })
    }

    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, excelName + fileExtension)
  }

  const { handlePlayPause } = useAudioPlayer()
  const [playingId, setPlayingId] = useState(null)

  const [openTranscriptModal, setOpenTranscriptModal] = useState(false)
  const [transcriptData, setTranscriptData] = useState([])
  const [openReviewModal, setOpenReviewModal] = useState(false)
  const [reviewItem, setReviewItem] = useState({})
  const [rows, setRows] = useState([])

  // Add state for current page and items per page
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  let recordings
  let campaign
  let agent
  let user

  const types = ['agent', 'campaign', 'user']
  if (!types.includes(type)) {
    throw new Error('Invalid type')
  }
  if (!id) {
    throw new Error('id is required')
  }
  ;({ data: recordings } = useQuery(['recordings', id, page, pageSize], () => {
    return CallService.getCallsByType({ id, type, page })
  }))
  ;({ data: campaign } = useQuery(['campaign', id], () => {
    return campaignService.getCampaignDetails(id)
  }))
  ;({ data: agent } = useQuery(['agent', id], () => {
    return agentService.getAgent(id)
  }))
  ;({ data: user } = useQuery(['user', id], () => {
    return userService.getUser(id)
  }))

  useEffect(() => {
    setRows(recordings?.data ?? [])
  }, [recordings])

  // Add a handler for page change
  const handlePageChange = (event, value) => {
    setPage(value)
  }
  const handlePageSizeChange = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(1) // Reset page to 1 when changing the page size
  }

  const [rowSelection, setRowSelection] = useState({})
  const navigate = useNavigate()

  const handleCellClick = useCallback((e, callback) => {
    // e.stopPropagation()
    callback()
  }, [])

  const handlePlayPauseClick = useCallback(
    (e, id, location) => {
      e.stopPropagation()
      // Adjusted logic for playingId and handlePlayPause
      if (playingId === id) {
        setPlayingId(null)
      } else {
        setPlayingId(id)
        handlePlayPause(id, location) // Consider adjusting how this function works to be more efficient
      }
    },
    [playingId, handlePlayPause]
  )

  const updateCallResult = useCallback(async (row, value) => {
    const data = row.original
    const id = data._id
    try {
      console.log('Update call response:', id, value) //TODO: finish here using Client APIs...
    } catch (error) {
      console.error('Failed to update call:', error)
    }
  })

  const handleClassNoteChange = async (id, reviewNote, manualReview) => {
    try {
      const result = await CallService.updateCall(id, {
        notes: reviewNote,
        manualReview: manualReview
      })

      if (result) {
        queryClient.invalidateQueries('calls')
        var row = rows.find(x => x.callId === id)
        row.manualReview = manualReview
        row.reviewNote = reviewNote
        notifySuccess('Change saved successfully')
      } else {
        notifyError(result?.error || 'Failed to save changes')
      }
    } catch (error) {
      console.error(error)
      notifyError('An error occurred while saving changes')
    } finally {
      setOpenReviewModal(false)
    }
  }

  const updateCallResultClick = useCallback(
    (e, row, value) => {
      handleCellClick(e, () => updateCallResult(row, value))
    },
    [handleCellClick, updateCallResult]
  )

  const handleTranscriptClick = useCallback(
    (e, row, transcripts) => {
      handleCellClick(e, () => {
        setRowSelection(prev => {
          if (row.id in prev) {
            return { ...prev }
          } else {
            return { [row.id]: !prev[row.id] }
          }
        })
        setOpenTranscriptModal(true)
        setTranscriptData(transcripts)
      })
    },
    [
      handleCellClick,
      setRowSelection,
      setOpenTranscriptModal,
      setTranscriptData
    ]
  )
  const handleReviewClick = useCallback(
    (e, row, item) => {
      handleCellClick(e, () => {
        setRowSelection(prev => {
          if (row.id in prev) {
            return { ...prev }
          } else {
            return { [row.id]: !prev[row.id] }
          }
        })
        setOpenReviewModal(true)
                setReviewItem(item)
      })
    },
    [
      handleCellClick,
      setRowSelection,
      setOpenTranscriptModal,
      setTranscriptData
    ]
  )

  const recordingsColumns = useMemo(() => {
    let fields = [
      {
        header: 'Details',
        Cell: info => {
          const id = info.cell.row.original.callId

          const handleDetailsClick = e => {
            window.open(`/calls/${id}`, '_blank')
          }

          return (
            <TableCell onClick={handleDetailsClick}>
              <ZoomInIcon />
              View
            </TableCell>
          )
        }
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At',
        Cell: info => {
          const data = info.cell.row.original
          const date = new Date(data.createdAt)
          return date.toLocaleString()
        }
      },
      {
        accessorKey: 'to',
        header: 'Phone'
      },
      {
        accessorKey: 'callLength',
        header: 'Duration',
        Cell: info => {
          const data = info.cell.row.original
          const callDuration = data?.callLength

          if (!callDuration) return '00:00:00'

          // convert seconds to hh:mm:ss
          const date = new Date(0)
          date.setSeconds(callDuration)
          const timeString = date.toISOString().substr(11, 8)

          return timeString
        }
      },
      {
        header: 'Reviewed',
        Cell: info => {
          const row = info.cell.row
          const data = info.cell.row.original
          let reviewed = !!data?.manualReview ? data?.manualReview : 'No'

          return (
            <TableCell onClick={e => handleReviewClick(e, row, data)}>
              <ArticleIcon />
              {reviewed}
            </TableCell>
          )
        }
      },
      {
        header: 'Recording',
        Cell: info => {
          const id = info.cell.row.original.callId
          const location = info.cell.row.original.recordingLocation
          return (
            <PlayPauseCell
              id={id}
              location={location}
              handlePlayPause={handlePlayPause}
            />
          )
        }
      },
      {
        header: 'Transcript',
        Cell: info => {
          const row = info.cell.row
          const data = info.cell.row.original
          const transcripts = data?.transcripts

          if (!transcripts || transcripts.length === 0) return 'No transcript'

          return (
            <TableCell
              onClick={e => handleTranscriptClick(e, row, transcripts)}
            >
              <ArticleIcon />
              Read
            </TableCell>
          )
        }
      },
      {
        header: 'Score',
        accessorKey: 'score'
      }
    ]
    const campaignFields = [
      {
        accessorKey: 'contactName',
        header: 'Name'
      },
      {
        accessorKey: 'status',
        header: 'Status'
      },
      {
        accessorKey: 'result',
        header: 'Call Result'
      },
      {
        accessorKey: 'numOfTries',
        header: 'Attempt #'
      },
      {
        accessorKey: 'scheduledAt',
        header: 'Scheduled At',
        Cell: info => {
          const data = info.cell.row.original
          const date = new Date(data.scheduledAt)
          return date.toLocaleString()
        }
      },
      {
        header: 'Update Status',
        Cell: info => {
          const row = info.cell.row
          const data = info.cell.row.original

          const handleChange = e => {
            updateCallResultClick(e, row, e.target.value)
          }

          const options = [
            'pending',
            'voicemail',
            'ivr',
            'picked_up',
            'failed',
            'unanswered',
            'donotcall',
            'disconnected'
          ]

          return (
            <Select value={data.result} onChange={handleChange}>
              {options.map(option => (
                <MenuItem key={option} value={option}>
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </MenuItem>
              ))}
            </Select>
          )
        }
      },
      {
        accessorKey: 'price',
        header: 'Price'
      }
    ]
    fields =
      type === 'campaign' ? (fields = fields.concat(campaignFields)) : fields
    return fields
  }, [
    type,
    handlePlayPause,
    handleTranscriptClick,
    handleReviewClick,
    updateCallResultClick,
    navigate
  ])

  const tableData = {
    columns: recordingsColumns,
    rows,
    initialState: {
      density: 'compact',
      pagination: {
        page: 0,
        pageSize: 1000
      }
    }
  }

  // const onRowClick = row => {
  //   // console.log(row.original)
  //   navigate(`/calls/${row.original._id}`)
  // }
  return (
    <>
      <TranscriptModal
        isOpen={openTranscriptModal}
        onClose={() => {
          setOpenTranscriptModal(false)
        }}
        transcriptData={transcriptData}
      />{' '}
      <ReviewModal
        isOpen={openReviewModal}
        onClose={() => {
          setOpenReviewModal(false)
        }}
        onSave={handleClassNoteChange}
        item={reviewItem}
      />{' '}
      {summary && (
        <Grid container spacing={2} sx={{ flexWrap: 'nowrap' }}>
          <Grid item xs={6}>
            <StatsSection
              title="Call Summary"
              items={recordings?.data}
              fieldName="status"
              chart
            />
          </Grid>
          <Grid item xs={6}>
            <StatsSection
              title="Call Results"
              items={recordings?.data}
              fieldName="result"
              chart
            />
          </Grid>
        </Grid>
      )}
      <MDBox>
        <MDButton
          variant="contained"
          color="dark"
          onClick={() => exportToExcel(rows)}
        >
          Excel Export
        </MDButton>
      </MDBox>
      <Card sx={{ marginTop: '20px' }}>
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            style={{ marginTop: '10px', marginBottom: '30px' }}
          >
            Call Log
          </Typography>

          <DataTable
            table={tableData}
            canSearch
            totalCount={recordings?.totalCount}
            selectedRow={rowSelection}
            setRowSelection={setRowSelection}
          />
          {/* <TablePagination
          component="div"
          count={recordings?.totalCount}
          page={page - 1} // TablePagination is 0-indexed
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handlePageSizeChange}
        /> */}
        </CardContent>
      </Card>
    </>
  )
}

export default CallsList
