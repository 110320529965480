/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { useUser } from 'context'

function AffiliateInfo() {
  const { authState, setAuthInfo } = useUser()

  return (
    <Card id="affiliateInfo" sx={{ overflow: 'visible' }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <MDTypography variant="h5">Affiliate Info</MDTypography>
        {/* <MDBadge variant="contained" color="success" badgeContent="enabled" container /> */}
      </MDBox>
      <MDBox p={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <MDTypography variant="body2" color="text">
            Your Affliate Link
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {`https://my.cald.ai/signup?via=${authState?.userInfo?.affiliateCode}`}
              </MDTypography>
            </MDBox>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() =>
                navigator.clipboard.writeText(
                  `https://my.cald.ai/signup?via=${authState?.userInfo?.affiliateCode}`
                )
              }
            >
              copy
            </MDButton>
          </MDBox>
        </MDBox>
        <Divider />
        {/* <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDTypography variant="body2" color="text">
            Signups
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <MDTypography variant="button" color="text" fontWeight="regular">
                33
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDTypography variant="body2" color="text">
            Conversions
          </MDTypography>
          <MDBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
              <MDTypography variant="button" color="text" fontWeight="regular">
                20
              </MDTypography>
            </MDBox>
            <MDButton variant="outlined" color="dark" size="small">
              pay out
            </MDButton>
          </MDBox>
        </MDBox> */}
      </MDBox>
    </Card>
  )
}

export default AffiliateInfo
