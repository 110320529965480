import CircularProgress from '@mui/material/CircularProgress'

function Loader({ color = 'success', size = 20 }) {
  return (
    <div>
      <CircularProgress color={color} size={size} />
    </div>
  )
}

export default Loader
