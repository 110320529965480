import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'

import CrudService from 'services/cruds-service'
import AuthService from 'services/auth-service'

// The Material Dashboard 2 PRO React main context
const MaterialUI = createContext()

// the authentication context
export const AuthContext = createContext({
  isAuthenticated: false,
  login: () => {},
  register: () => {},
  logout: () => {},
  getCurrentUser: () => {},
  getRole: () => {}
})

const AuthContextProvider = ({ children }) => {
  const token = localStorage.getItem('token')
  let userInfo = localStorage.getItem('userInfo')

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [authState, setAuthState] = useState({
    token,
    userInfo: userInfo && userInfo !== 'undefined' ? JSON.parse(userInfo) : {}
  })
  const [appInfo, setAppInfo] = useState({
    appName: '',
    domain: '',
    auth0Id: ''
  })
  const [paymentInfo, setPaymentInfo] = useState({})
  const setAuthInfo = ({ token, userInfo }) => {
    localStorage.setItem('token', token)
    localStorage.setItem('userInfo', JSON.stringify(userInfo))

    setAuthState({
      token,
      userInfo
    })
  }

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!token) return

    setIsAuthenticated(true)
    navigate(location.pathname)
  }, [])

  const login = newToken => {
    localStorage.setItem('token', newToken)
    setIsAuthenticated(true)
  }

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('expiresAt')
    setAuthState({})
  }

  const getCurrentUser = async () => {
    try {
      const res = await AuthService.getProfile()
      return res.data.id
    } catch (err) {
      console.error(err)
      return null
    }
  }

  const getRole = async () => {
    // first get the current user id
    const id = await getCurrentUser()
    try {
      // second I get the user with role
      const res = await CrudService.getUser(id)
      const roleId = res.data.relationships.roles.data[0].id
      // third check the role id and return the role type
      if (roleId === '1') {
        return 'admin'
      }
      if (roleId === '2') {
        return 'creator'
      }
      if (roleId === 3) {
        return 'member'
      }
      return res.included[0].attributes.name
    } catch (err) {
      console.error(err)
      return null
    }
  }

  const appDetails = {
    appInfo,
    setAppInfo
  }

  return (
    <AuthContext.Provider
      value={{
        appDetails,
        paymentInfo,
        authState,
        setPaymentInfo,
        isAuthenticated,
        setAuthState: authInfo => setAuthInfo(authInfo),
        login,
        logout,
        getRole,
        getCurrentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAppInfo = () => {
  const context = useContext(AuthContext)

  if (!context) throw new Error('useAppInfo must be used inside AuthProvider')
  return context.appDetails
}

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = 'MaterialUIContext'

// Material Dashboard 2 PRO React reducer
function reducer(state, action) {
  switch (action.type) {
    case 'MINI_SIDENAV': {
      return { ...state, miniSidenav: action.value }
    }
    case 'TRANSPARENT_SIDENAV': {
      return { ...state, transparentSidenav: action.value }
    }
    case 'WHITE_SIDENAV': {
      return { ...state, whiteSidenav: action.value }
    }
    case 'SIDENAV_COLOR': {
      return { ...state, sidenavColor: action.value }
    }
    case 'TRANSPARENT_NAVBAR': {
      return { ...state, transparentNavbar: action.value }
    }
    case 'FIXED_NAVBAR': {
      return { ...state, fixedNavbar: action.value }
    }
    case 'OPEN_CONFIGURATOR': {
      return { ...state, openConfigurator: action.value }
    }
    case 'DIRECTION': {
      return { ...state, direction: action.value }
    }
    case 'LAYOUT': {
      return { ...state, layout: action.value }
    }
    case 'DARKMODE': {
      return { ...state, darkMode: action.value }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: 'info',
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: 'ltr',
    layout: 'dashboard',
    darkMode: false
  }

  const [controller, dispatch] = useReducer(reducer, initialState)

  const value = useMemo(() => [controller, dispatch], [controller, dispatch])

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>
}

function useUser() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useUser should be used inside the AuthContextProvider')
  }

  return context
}

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI)

  if (!context) {
    throw new Error(
      'useMaterialUIController should be used inside the MaterialUIControllerProvider.'
    )
  }

  return context
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired
}

// Context module functions
const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: 'MINI_SIDENAV', value })
const setTransparentSidenav = (dispatch, value) =>
  dispatch({ type: 'TRANSPARENT_SIDENAV', value })
const setWhiteSidenav = (dispatch, value) =>
  dispatch({ type: 'WHITE_SIDENAV', value })
const setSidenavColor = (dispatch, value) =>
  dispatch({ type: 'SIDENAV_COLOR', value })
const setTransparentNavbar = (dispatch, value) =>
  dispatch({ type: 'TRANSPARENT_NAVBAR', value })
const setFixedNavbar = (dispatch, value) =>
  dispatch({ type: 'FIXED_NAVBAR', value })
const setOpenConfigurator = (dispatch, value) =>
  dispatch({ type: 'OPEN_CONFIGURATOR', value })
const setDirection = (dispatch, value) => dispatch({ type: 'DIRECTION', value })
const setLayout = (dispatch, value) => dispatch({ type: 'LAYOUT', value })
const setDarkMode = (dispatch, value) => dispatch({ type: 'DARKMODE', value })

export {
  AuthContextProvider,
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  useUser
}
