/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import CallsList from 'pageComponents/calls/List'


import { useState, useEffect, Fragment } from 'react'

import BillingService from 'services/billing-service'
import { CreditCardModal } from 'components/Modals'
import { useStripe } from 'utils/customHooks/useStripe'
import stripeService from 'services/stripe-service'
import { Chip, Tooltip } from '@mui/material'
import Loader from 'components/Loader'
import { notifyError } from 'components/Messages'
import { roundToTwo } from 'utils/utilsFunctions'
import { useUser } from 'context'

const MONEY = [5, 10, 20, 50, 100, 500, 750, 1000, 1500, 3000]
function BillingInfo() {
  const [isPaymentSetup, setIsPaymentSetup] = useState(false)
  const [isOpenCardInfo, setIsOpenCardInfo] = useState(false)
  const { stripePromise } = useStripe(setIsPaymentSetup)
  const [clientSecret, setClientSecret] = useState('')
  const [availableCredit, setAvailableCredit] = useState()
  const [payments, setPayments] = useState([])
  const [isPaymentsLoading, setIsPaymentsLoading] = useState(true)
  const [spentAmount, setSpentAmount] = useState()
  const [paidAmount, setPaidAmount] = useState()
  const [callPricingDetails, setCallPricingDetails] = useState()
  const [isCallPricingDetailsLoading, setIsCallPricingDetailsLoading] =
    useState(true)
  const [totalAmount, setTotalAmount] = useState(0)
  const [paymentIntentLoader, setPaymentIntentLoader] = useState('idle')
  const [pendingIntentLoader, setPendingIntentLoader] = useState('idle')
  const { authState, setAuthInfo } = useUser()
  

  const handleCreatePaymentIntent = async amount => {
    setPaymentIntentLoader('pending')
    const payload = amount * 100 // Stripe accept the payment in cents
    try {
      const { data } = await stripeService.getPaymentIntentInfo(payload)
      setClientSecret(data?.client_secret)
    } catch (err) {
      console.log(err)
      notifyError('Something Went Wrong')
      setPaymentIntentLoader('resolved')
    } finally {
      setPaymentIntentLoader('resolved')
    }
  }

  const handleAddPayment = amount => {
    setTotalAmount(prev => prev + amount)
  }

  const handleUpdateCredit = payload => {
    const amount = payload / 100
    setAvailableCredit(prev => Number(amount) + Number(prev))
    setTotalAmount(0)
    setClientSecret('')
  }

  useEffect(() => {
    const fetchPendingPaymentIntent = async () => {
      setPendingIntentLoader('pending')
      try {
        const response = await stripeService.getPaymentIntentInfo()
        if (response.data && response.data.status !== 'succeeded') {
          setTotalAmount(response.data.amount / 100)
          setClientSecret(response.data.client_secret)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setPendingIntentLoader('resolved')
      }
    }

    fetchPendingPaymentIntent()
  }, [])

  // Refactor multiple api's into one single endpoint
  useEffect(() => {
    BillingService.getAvailableBalance().then(data => {
      setAvailableCredit(roundToTwo(data.balance))
    })

    BillingService.getPayments().then(data => {
      setPayments(data.payments)
      setIsPaymentsLoading(false)
    })

    BillingService.getTotalSpent().then(data => {
      setSpentAmount(data.amount)
    })

    BillingService.getTotalPaid().then(data => {
      setPaidAmount(data.amount)
    })

    BillingService.getCallPricingDetails().then(data => {
      setCallPricingDetails(data)
      setIsCallPricingDetailsLoading(false)
    })
  }, [isPaymentSetup, availableCredit])

  return (
    <Card id="billing-info">
      <CreditCardModal
        clientSecret={clientSecret}
        stripePromise={stripePromise}
        isOpen={isOpenCardInfo}
        updateCredit={handleUpdateCredit}
        onClose={() => {
          setIsOpenCardInfo(false)
          // Call a function that updates spentAmount here
          // BillingService.getTotalSpent().then((data) => {
          //   setSpentAmount(data.amount);
          // });
        }}
        title={`Pay $${totalAmount}`}
      />
      <MDBox p={3}>
        <MDTypography variant="h5">Billing Info</MDTypography>
      </MDBox>
      <MDBox p={3}>
        <MDTypography variant="h6">Available Credit</MDTypography>
        <MDTypography variant="body2">
          You currently have ${availableCredit ?? 0} credit.
        </MDTypography>
        {pendingIntentLoader === 'pending' ? (
          <Loader />
        ) : (
          !clientSecret && (
            <Fragment>
              <MDTypography mt={1} variant="h6">
                Add More Credits
              </MDTypography>
              <MDBox
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                p={1}
              >
                {MONEY.map(item => (
                  <Chip
                    key={item}
                    label={`$${item}`}
                    onClick={() => handleAddPayment(item)}
                    disabled={paymentIntentLoader === 'pending'}
                  />
                ))}
              </MDBox>
            </Fragment>
          )
        )}
        <MDBox p={1} sx={{ display: 'flex', gap: 2, alignItems: ' center' }}>
          {!clientSecret && (
            <MDTypography variant="body2">
              Total:{' '}
              <MDTypography component="span" variant="h6">
                ${totalAmount}
              </MDTypography>
            </MDTypography>
          )}
          {totalAmount > 0 && !clientSecret && (
            <MDBox sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Tooltip title="Cancel" arrow={false}>
                <Chip
                  onClick={() => setTotalAmount(0)}
                  color="error"
                  label="Cancel"
                  disabled={paymentIntentLoader === 'pending'}
                />
              </Tooltip>
              <Tooltip title="Confirm" arrow={false}>
                <Chip
                  onClick={() => handleCreatePaymentIntent(totalAmount)}
                  color="success"
                  label="Confirm"
                  disabled={paymentIntentLoader === 'pending'}
                />
              </Tooltip>
            </MDBox>
          )}
          {paymentIntentLoader === 'pending' && <Loader />}
        </MDBox>
        {clientSecret && (
          <MDButton
            variant="contained"
            color="primary"
            onClick={() => setIsOpenCardInfo(true)}
            style={{ marginTop: '16px' }}
            disabled={!clientSecret}
          >
            {`Add $${totalAmount} Credit`}
          </MDButton>
        )}
      </MDBox>
      <MDBox p={3}>
      <MDTypography
          variant="h6"
          style={{
            fontWeight: 600,
            borderBottom: '1px solid #ccc',
            paddingBottom: '8px',
            marginBottom: '16px'
          }}
        >
          Subscriptions
        </MDTypography>
        <MDTypography
          variant="body2"
          color="secondary"
          style={{ marginBottom: '16px' }}
        >Manage your subscriptions and view a complete payments history 
            <a href="https://billing.stripe.com/p/login/14keXife37qt9Qk6oo"> here </a>
        </MDTypography>
        <MDTypography
          variant="h6"
          style={{
            fontWeight: 600,
            borderBottom: '1px solid #ccc',
            paddingBottom: '8px',
            marginBottom: '16px'
          }}
        >
          Detailed Call Pricing
        </MDTypography>
        <MDBox>
          <CallsList summary id={authState?.userInfo?.id} type='user' />
        </MDBox>
      </MDBox>
    </Card>
  )
}

export default BillingInfo
