import Modal from '@mui/material/Modal'
import colors from 'assets/theme-dark/base/colors'
import MDBox from 'components/MDBox'
import { useMaterialUIController } from 'context'

function MDModal({ open, onClose, children, sx }) {
  const [controller] = useMaterialUIController()
  const { darkMode } = controller

  const modalStyle = {
    backgroundColor: darkMode ? '#0F141F' : '#FFF',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    boxShadow: 24,
    position: 'absolute',
    overflowY: 'auto',
    borderRadius: '10px',
    maxHeight: '90%'
  }

  return (
    <Modal
      disableAutoFocus={true}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox sx={{ ...modalStyle, ...sx }}>{children}</MDBox>
    </Modal>
  )
}

export default MDModal
