import { TermsOfUse } from 'components/Legal'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'

const TermsOfUseModal = ({ isOpen = false, onClose, onOkCallback }) => {
  return (
    <MDModal
      onClose={onClose}
      open={isOpen}
      sx={{
        height: '700px',
        borderRadius: '10px',
        width: { xs: '90%', md: '40%' },
        px: 4,
        py: 1.5
      }}
    >
      <MDBox sx={{ textAlign: 'center', m: 2 }}>
        <MDTypography variant="h3">Let's go over the terms</MDTypography>
      </MDBox>
      <MDBox sx={{ m: 5 }}>
        <TermsOfUse />
        <MDButton
          fullWidth
          sx={{ mt: 5 }}
          variant="contained"
          color="primary"
          onClick={onOkCallback}
        >
          Agree and Set Up Campaign
        </MDButton>
      </MDBox>
    </MDModal>
  )
}

export default TermsOfUseModal
